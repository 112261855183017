define("plutof/components/common/record-list-view", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ol class="record-list-view-ng" ...attributes>
      {{#each this.entries as |entry|}}
          <li class="record-list-view-ng__entry">
              <span class="record-list-view-entry-content {{annotation/added-removed-class entry.record}}">
                  {{#if (has-block)}}
                      {{yield entry.record entry}}
                  {{else}}
                      {{! This is common enough that I grudgingly let it be default }}
                      {{entry.record.representation}}
                  {{/if}}
              </span>
  
              <span>
                  {{#if entry.last}}
                      {{@terminator}}
                  {{else}}
                      {{this.separator}}
                  {{/if}}
              </span>
          </li>
      {{/each}}
  </ol>
  
  */
  {
    "id": "71zZ3wrt",
    "block": "{\"symbols\":[\"entry\",\"@terminator\",\"&default\",\"@namedBlocksInfo\",\"&attrs\"],\"statements\":[[11,\"ol\"],[24,0,\"record-list-view-ng\"],[17,5],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"entries\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[14,0,\"record-list-view-ng__entry\"],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[31,[\"record-list-view-entry-content \",[30,[36,0],[[32,1,[\"record\"]]],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,4],\"default\",[27,[32,3]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[18,3,[[32,1,[\"record\"]],[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[1,[32,1,[\"record\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n\\n            \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"last\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[1,[32,0,[\"separator\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"annotation/added-removed-class\",\"-has-block\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/common/record-list-view.hbs"
    }
  });
  let RecordListView = (_dec = Ember.computed('args.records.[]'), _class = class RecordListView extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "separator", ',');
      if (this.args.separator !== undefined) {
        this.separator = this.args.separator;
      }
    }
    get entries() {
      const records = this.args.records || [];
      const lastIndex = records.length - 1;
      return records.map((record, index) => ({
        record,
        last: index === lastIndex
      }));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "entries", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "entries"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RecordListView);
});
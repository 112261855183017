define("plutof/components/plutof-select/value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ValueSelect = (_dec = Ember._action, _class = class ValueSelect extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "optionValuePath", 'value');
      _defineProperty(this, "optionLabelPath", 'display_name');
      _defineProperty(this, "prompt", '---');
      _defineProperty(this, "selectClass", 'form-control');
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "oneWay", false);
    }
    valueChanged(value) {
      const changeAction = this.selectionChanged;
      if (!Ember.isNone(changeAction)) {
        const valuePath = this.optionValuePath;
        const contentValue = this.content.findBy(valuePath, value);
        changeAction(contentValue, value);
      }
      if (!this.oneWay) {
        this.set('value', value);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "valueChanged", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "valueChanged"), _class.prototype), _class);
  var _default = _exports.default = ValueSelect;
});
define("plutof/components/analysis/matching-source", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/controllers/analysis/utils", "plutof/mixins/component-validations", "plutof/utils/has-many", "plutof/utils/validations"], function (_exports, _component, _emberCpValidations, _utils, _componentValidations, _hasMany, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createMatchingSourceData = createMatchingSourceData;
  _exports.default = void 0;
  _exports.loadMatchingSourceData = loadMatchingSourceData;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class2, _class3, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let MatchingSourceData = (_dec = Ember.inject.service(), _dec2 = Ember.computed.reads('pagination.objects'), _dec3 = Ember.computed('count', 'clipboardPagination.objectCount', 'fastaFiles.[]'), _class = class MatchingSourceData extends Ember.Object.extend(_hasMany.HasManyDataMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "items", _descriptor2, this);
    }
    init() {
      super.init(...arguments);
      this.set('nextOrdering', 1);
      this.set('clipboardOrdering', null);
      this.set('clipboardPagination', null);
      this.set('clipboardUploaded', false);
      this.set('fastaFiles', []);
    }
    get totalCount() {
      return this.count + (this.get('clipboardPagination.objectCount') || 0) + this.fastaFiles.length;
    }
    async save() {
      await super.save(...arguments);
      await Ember.RSVP.all(this.items.map(item => item.save()));
      if (!Ember.isNone(this.clipboardPagination) && !this.clipboardUploaded) {
        let clipboardSequenceIDs = [];
        for (let pageNum = 1; pageNum <= this.clipboardPagination.pageCount; pageNum++) {
          await this.clipboardPagination.switchPage(pageNum);
          for (let sequence of this.clipboardPagination.objects) {
            clipboardSequenceIDs.push(sequence.id);
          }
        }
        await (0, _utils.batchAddMatchingItems)(this.ajax, this.run, clipboardSequenceIDs, this.clipboardOrdering);
        this.set('clipboardUploaded', true);
      }
      for (let fastaFile of this.fastaFiles.filter(fasta => !fasta.uploaded)) {
        // FASTA files are imported this way, because frontend doesn't know how many sequences they
        // contain. Backend parses the files
        const {
          next_ordering
        } = await (0, _utils.addMatchingItemsFromFASTA)(this.ajax, this.run, fastaFile.file, this.nextOrdering);
        this.set('nextOrdering', next_ordering);
        fastaFile.uploaded = true;
      }
    }
    _createEmpty() {
      return this.store.createRecord('analysis/sequence-matching-run-item', {
        run: this.run,
        ordering: this.nextOrdering
      });
    }
    addExisting(item) {
      super.addExisting(item);

      // TODO: Make sure that editing works with this
      this.set('nextOrdering', Math.max(item.ordering + 1, this.nextOrdering));
    }
    setClipboardSequences(pagination) {
      this.set('clipboardPagination', pagination);
      this.set('clipboardUploaded', false);
      this.set('clipboardOrdering', this.nextOrdering);
      this.set('nextOrdering', this.nextOrdering + pagination.objectCount);
    }
    addFASTA(file) {
      this.fastaFiles.pushObject({
        file,
        uploaded: false
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "totalCount"), _class.prototype), _class);
  const {
    create,
    load
  } = (0, _hasMany.hasManyDataConstructors)('analysis/sequence-matching-run-item', MatchingSourceData, {
    parentField: 'run',
    extraFilters: {
      ordering: 'ordering'
    }
  });
  function createMatchingSourceData(store, run) {
    return create(store, run).then(data => {
      data.add();
      return data;
    });
  }
  function loadMatchingSourceData(store, run) {
    return load(store, run).then(data => {
      // Need to know maximum ordering among the existing items (including one not yet loaded),
      // so that new items are properly ordered
      // TODO: Do we really need frontend-provided ordering?
      return data.pagination.loaded.then(() => {
        const lastItemPromise = data.pagination.onLastPage ? Ember.RSVP.Promise.resolve(data.pagination.objects.lastObject) : data.pagination.loadPage(data.pagination.pageCount).then(lastPage => lastPage.lastObject);
        return lastItemPromise.then(lastItem => {
          if (!Ember.isNone(lastItem)) {
            data.set('nextOrdering', lastItem.ordering + 1);
          }
          return data;
        });
      });
    });
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    haveEnoughItems: (0, _emberCpValidations.validator)('is-truthy')
  });
  let MatchingSource = (_dec4 = (0, _component.classNames)('analysis-matching-source'), _dec5 = (0, _validations.splitContext)('validationContext', 'data.items'), _dec6 = Ember.computed.gt('data.totalCount', 0), _dec7 = Ember.computed('data.{items.[],fastaFiles.[]}'), _dec8 = Ember.computed('data.{count,clipboardPagination.objectCount,fastaFiles.[]}'), _dec9 = Ember._action, _dec10 = Ember._action, _dec4(_class2 = (_class3 = class MatchingSource extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "validationEntries", _descriptor3, this);
      _defineProperty(this, "validationChildren", [{
        name: 'items',
        label: 'Analysis.sourceData'
      }]);
      _initializerDefineProperty(this, "haveEnoughItems", _descriptor4, this);
    }
    get haveNonClipboardEntries() {
      return this.data.items.length > 0 || this.data.fastaFiles.length > 0;
    }
    get canRemove() {
      // Can remove if we have more than one entry. Don't use data.totalCount here, because
      // clipboard block counts as one entry
      const clipboardEntries = (this.data.clipboardPagination.objectCount ?? 0) > 0 ? 1 : 0;
      return this.data.count + clipboardEntries + this.data.fastaFiles.length > 1;
    }
    add() {
      this.data.add();
    }
    remove(item) {
      this.data.remove(item);
    }
  }, _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "validationEntries", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "haveEnoughItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "haveNonClipboardEntries", [_dec7], Object.getOwnPropertyDescriptor(_class3.prototype, "haveNonClipboardEntries"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "canRemove", [_dec8], Object.getOwnPropertyDescriptor(_class3.prototype, "canRemove"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "add", [_dec9], Object.getOwnPropertyDescriptor(_class3.prototype, "add"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "remove", [_dec10], Object.getOwnPropertyDescriptor(_class3.prototype, "remove"), _class3.prototype), _class3)) || _class2);
  var _default = _exports.default = MatchingSource;
});
define("plutof/components/map/map-search", ["exports", "@ember-decorators/component", "plutof/utils/notifications"], function (_exports, _component, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const COORDS_REGEXP = new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)(,)?(\s)+[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/);
  let MapSearch = (_dec = (0, _component.classNames)('map-search'), _dec2 = Ember.inject.service, _dec3 = Ember.computed('query'), _dec4 = Ember._action, _dec(_class = (_class2 = class MapSearch extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "geocoding", _descriptor, this);
      _defineProperty(this, "query", '');
    }
    get queryIsInvalid() {
      return this.query.trim() === '';
    }
    search() {
      // Even with disabled button, user can reach this with [Enter]
      if (this.queryIsInvalid) {
        return;
      }
      if (COORDS_REGEXP.test(this.query)) {
        // TODO: Just use groups
        const coords = this.query.split(/(\s|,)+/).map(s => s.trim()).reject(Ember.isEmpty);
        const lat = parseFloat(coords[0]);
        const lon = parseFloat(coords[1]);
        this.searchCoordinates(lon, lat);
      } else {
        // TODO: Problem with this is that extra request is sent on success. We already can have
        // country etc from the geocoding search, but because of separation between search, map and
        // area edit, there is unfortunately no normal way to pass that info on. If sampling is reorganized
        // sometime, should keep this cross-concern in mind.
        this.geocoding.search(this.query).then(({
          lon,
          lat
        }) => {
          this.searchCoordinates(lon, lat);
        }).catch(_notifications.reportError);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "geocoding", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "queryIsInvalid", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "queryIsInvalid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "search", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "search"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = MapSearch;
});
define("plutof/components/conservation/red-list/edit", ["exports", "@glimmer/component", "plutof/components/trait/list/edit", "plutof/utils/access", "plutof/components/linked-items/edit"], function (_exports, _component, _edit, _access, _edit2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createRedListModel = createRedListModel;
  _exports.default = void 0;
  _exports.loadRedListModel = loadRedListModel;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Conservation::RedList::-Edit::GeneralData
      @redlist={{@model.redlist}}
      @validationContext={{@validationContext.generalData}} />
  
  <Trait::List::Edit @model={{@model.traits}} />
  
  <Conservation::RedList::-Edit::Area
      @redlist={{@model.redlist}}
      @validationContext={{@validationContext.area}} />
  
  <LinkedItems::Edit @data={{@model.linkedItemsData}} />
  
  */
  {
    "id": "u7MKyzVu",
    "block": "{\"symbols\":[\"@model\",\"@validationContext\"],\"statements\":[[8,\"conservation/red-list/-edit/general-data\",[],[[\"@redlist\",\"@validationContext\"],[[32,1,[\"redlist\"]],[32,2,[\"generalData\"]]]],null],[2,\"\\n\\n\"],[8,\"trait/list/edit\",[],[[\"@model\"],[[32,1,[\"traits\"]]]],null],[2,\"\\n\\n\"],[8,\"conservation/red-list/-edit/area\",[],[[\"@redlist\",\"@validationContext\"],[[32,1,[\"redlist\"]],[32,2,[\"area\"]]]],null],[2,\"\\n\\n\"],[8,\"linked-items/edit\",[],[[\"@data\"],[[32,1,[\"linkedItemsData\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/conservation/red-list/edit.hbs"
    }
  });
  class RedListModel {
    constructor(store, redlist, traits, linkedItemsData) {
      this.store = store;
      this.redlist = redlist;
      this.traits = traits;
      this.linkedItemsData = linkedItemsData;
    }
    async save() {
      await this.traits.save(this.redlist.name);
      this.redlist.set('form', this.traits.form);
      await this.redlist.save();

      // Set form rights properly. It's a bit circular
      const accessRights = await (0, _access.create_access_rights)(this.store, this.traits.form);
      accessRights.setProperties({
        visible: 'PUBLIC',
        editable: 'WORKGROUPS',
        group_edit: [await this.redlist.workgroup]
      });
      await accessRights.saveWithTarget(this.traits.form);
      this.linkedItemsData.save(this.redlist, accessRights);
    }
  }
  async function createRedListModel(store) {
    const redlist = store.createRecord('redbook/red-list');
    const traits = (0, _edit.createTraitListModel)(store, 'red_list');
    const linkedItemsData = await (0, _edit2.createLinkedItemsData)(store, redlist, {
      disabledTabs: ['glossaries', 'links', 'references', 'permits']
    });
    return new RedListModel(store, redlist, traits, linkedItemsData);
  }
  async function loadRedListModel(store, redlist) {
    const form = await redlist.form;
    const traits = form ? await (0, _edit.loadTraitListModel)(store, form) : (0, _edit.createTraitListModel)(store, 'red_list');
    const linkedItemsData = await (0, _edit2.loadLinkedItemsData)(store, redlist, {
      useEventFiles: false,
      disabledTabs: ['glossaries', 'links', 'references', 'permits']
    });
    return new RedListModel(store, redlist, traits, linkedItemsData);
  }
  let RedListEdit = _exports.default = (_dec = Ember.inject.service, _class = class RedListEdit extends _component.default {
    constructor(...args) {
      super(...args);

      // Context is split on such a small form simply because it's the only
      // way to show user that save is disabled because the area is not set
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      this.args.validationContext.addChild('generalData', this.i18n.translate('General.generalData'));
      this.args.validationContext.addChild('area', this.i18n.translate('conservation.redList.fields.area'));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RedListEdit);
});
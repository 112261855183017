define("plutof/components/taxonomy-lab/taxon-description/view", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="TaxonomyLab::TaxonDescription::View"
  >
      <GenericViews::Record @record={{@taxonDescription}} as |view|>
          <Layout::RegularGrid @columns={{2}}>
              {{view.link "taxon_node" route="taxonomy.view"}}
  
              {{#view.attr "language"}}
                  {{@taxonDescription.language.representation}}
              {{/view.attr}}
  
              {{#each fields as |field|}}
                  {{view.attr field}}
              {{/each}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "TCK/ccPf",
    "block": "{\"symbols\":[\"view\",\"field\",\"@taxonDescription\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"TaxonomyLab::TaxonDescription::View\"]],[[\"@title\"],[[30,[36,2],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"link\"]],\"expected `view.link` to be a contextual component but found a string. Did you mean `(component view.link)`? ('plutof/components/taxonomy-lab/taxon-description/view.hbs' @ L7:C14) \"],null],\"taxon_node\"],[[\"route\"],[\"taxonomy.view\"]]]],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"attr\"]],\"expected `view.attr` to be a contextual component but found a string. Did you mean `(component view.attr)`? ('plutof/components/taxonomy-lab/taxon-description/view.hbs' @ L9:C15) \"],null],\"language\"],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,3,[\"language\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"attr\"]],\"expected `view.attr` to be a contextual component but found a string. Did you mean `(component view.attr)`? ('plutof/components/taxonomy-lab/taxon-description/view.hbs' @ L14:C18) \"],null],[32,2]],null]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"i18n-t\",\"fields\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/taxonomy-lab/taxon-description/view.hbs"
    }
  });
  class TaxonDescriptionView extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "fields", ['description', 'ecology', 'phylogeny', 'distribution', 'diagnostic_description', 'morphology', 'size', 'growth', 'look_alikes', 'habitat', 'trophic_strategy', 'behaviour', 'life_cycle', 'reproduction', 'dispersal', 'associations', 'migration', 'population_biology', 'trends', 'threats', 'risk_statement', 'conservation_status', 'legislation', 'use_field', 'key_field', 'remarks']);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TaxonDescriptionView);
});
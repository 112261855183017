define("plutof/routes/observation/edit", ["exports", "plutof/components/interaction/edit-multiple", "plutof/components/linked-items/edit", "plutof/components/measurements/measurement-form", "plutof/components/substrate/edit", "plutof/misc/content_types", "plutof/utils/access", "plutof/utils/model", "plutof/utils/store", "plutof/components/edit-determinations", "plutof/components/layer/occurrence-areas", "plutof/utils/routes"], function (_exports, _editMultiple, _edit, _measurementForm, _edit2, _content_types, _access, _model, _store, _editDeterminations, _occurrenceAreas, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ObservationEditRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _class = class ObservationEditRoute extends _routes.EditRoute {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "emberDataStore", _descriptor2, this);
      _defineProperty(this, "confirmExit", true);
    }
    model(params) {
      const store = this.store;
      const observationPromise = store.findRecord('taxonoccurrence/observation/observation', params.id);
      return observationPromise.then(observationRecord => {
        const observationAccessPromise = (0, _access.get_permissions)(store, this.ajax, observationRecord);
        const samplingDataPromise = (0, _model.getOccurrenceSamplingData)(store, observationRecord);
        return Ember.RSVP.hash({
          samplingAreaEventFormData: samplingDataPromise.then(samplingData => {
            samplingData.formData.project = observationRecord.get('project');
            samplingData.formData.set('canEditEvent', true);
            return samplingData.formData;
          }),
          layers: _occurrenceAreas.LinkedLayersModel.load(this.ajax, observationRecord),
          accessRights: observationAccessPromise,
          itemData: (0, _edit.loadLinkedItemsData)(store, observationRecord, {
            useEventFiles: true
          }),
          contentType: (0, _content_types.get_ctype_by_name)(store, 'observation/observation'),
          observationData: _measurementForm.MeasurementsData.create({
            store: store
          }),
          observation: observationRecord,
          substrateData: (0, _edit2.createSubstrateData)(store, observationRecord),
          interactions: _editMultiple.InteractionsData.load(store, observationRecord),
          determinations: (0, _editDeterminations.loadDeterminationData)(store, observationRecord),
          typifications: (0, _store.query)(store, 'determination/typification', {
            is_culture: 'False'
          }),
          additionalIdentifiers: (0, _store.query)(this.store, 'taxonoccurrence/observation/additional-identifier', {
            observation: observationRecord.id
          })
        });
      });
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        objectsToDelete: [],
        relatedObjectsToDelete: []
      });
    }
    getTitle(model) {
      return model.observation.taxon_name;
    }
    transitionToDefaultRoute() {
      return this.transitionTo('observation.view', this.currentModel.observation.id);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "emberDataStore", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = ObservationEditRoute;
});
define("plutof/components/clipboard/list-view-clipboard-button", ["exports", "@ember-decorators/component", "ember-concurrency", "plutof/misc/clipboard", "plutof/utils/notifications"], function (_exports, _component, _emberConcurrency, _clipboard, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ListViewClipboardButton = (_dec = (0, _component.tagName)('span'), _dec2 = Ember.inject.service, _dec3 = Ember.computed('entry.on_clipboard', 'toggleInclusion.isRunning'), _dec4 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec(_class = (_class2 = class ListViewClipboardButton extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "toggleInclusion", _descriptor2, this);
    }
    get iconClass() {
      if (this.toggleInclusion.isRunning) {
        return 'loading-icon';
      }
      return this.entry.on_clipboard ? 'icon-clipboard-remove clickable' : 'icon-clipboard-add clickable';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "iconClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "iconClass"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "toggleInclusion", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        try {
          // XXX: What's going on here?
          const contentType = yield Ember.RSVP.Promise.resolve(this.contentType);
          const status = (0, _clipboard.get_clipboard_status)(this.ajax, this.store, contentType, this.entry.id);
          yield status.check(this.entry);
          if (status.checked) {
            yield status.change(!status.included);
            Ember.set(this.entry, 'on_clipboard', status.included);
          }
        } catch (error) {
          (0, _notifications.reportError)(error);
        }
      };
    }
  }), _class2)) || _class);
  var _default = _exports.default = ListViewClipboardButton;
});
define("plutof/components/map/locate-me", ["exports", "@ember-decorators/component", "ol/Feature"], function (_exports, _component, _Feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let LocateMe = (_dec = (0, _component.classNames)('ol-control ol-unselectable locate-me-control'), _dec2 = Ember._action, _dec(_class = (_class2 = class LocateMe extends Ember.Component {
    init() {
      super.init();
      this.set('disabled', !navigator || !navigator.geolocation);
      this.set('inProgress', false);
    }
    locateMe() {
      this.set('inProgress', true);
      navigator.geolocation.getCurrentPosition(data => {
        var lat = data.coords.latitude;
        var lon = data.coords.longitude;
        if (Ember.isNone(lat) || Ember.isNone(lon)) {
          return;
        }
        var map = this.map;
        var geometry = map.coordsToGeometry(lon, lat);
        if (this.modify) {
          map.addFeature(new _Feature.default(geometry));
        }
        map.focusOn(geometry, false);
        this.set('inProgress', false);
      }, () => {
        this.set('inProgress', false);
      });
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "locateMe", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "locateMe"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = LocateMe;
});
define("plutof/mixins/route", ["exports", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/routing", "plutof/mixins/reset", "plutof/mixins/route-title", "plutof/utils/errors", "plutof/utils/notifications"], function (_exports, _abstract, _config, _routing, _reset, _routeTitle, _errors, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CONFIRM_TRANSITIONS = _config.default.Common.ENABLE_TRANSITION_CONFIRMATION;

  // XXX: Those should be in router service if they are to be anywhere.
  // At least its not a part of application controller anymore
  // TODO: Also, without invokeRoute can just use URLs
  let routeStack = [];
  function pushRoute(route, param) {
    routeStack.pushObject({
      route,
      param
    });
  }
  function popRoute() {
    return routeStack.popObject(); // undefined when the stack is empty is ok
  }

  // TODO: Rename this
  // TODO: Common error handler (see route.error)
  var _default = _exports.default = Ember.Mixin.create(_reset.default, _routeTitle.default, {
    router: Ember.inject.service(),
    defaultReturnRoute: '',
    confirmExit: false,
    confirmationDialog: Ember.inject.service(),
    ajax: Ember.inject.service(),
    init: function () {
      this._super(...arguments);
      this.set('going_back', false);
      this.set('current_path', null);
      this.set('param', null);
      // if confirmExit is true and exitConfirmed is false, exit transition is prevented
      this.set('exitConfirmed', false);
      // If true, don't spawn additional exit confirmations
      this.set('confirmationScheduled', false);
    },
    _transition: function (route, param, confirmExit, queryParams = {}) {
      if (confirmExit) {
        this.set('exitConfirmed', true);
      }
      if (Ember.isNone(param)) {
        return this.transitionTo(route, {
          queryParams
        });
      } else {
        return this.transitionTo(route, param, {
          queryParams
        });
      }
    },
    afterModel: function (model, transition, queryParams) {
      this._super(model, transition, queryParams);

      // TODO: Replace with router service
      this.set('current_path', transition.targetName);
      // XXX: This whole file needs to be scrapped
      this.set('param', Object.values(transition.to.params)[0]);
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      this.set('exitConfirmed', false);
      (0, _routing.getRoutingStatus)().set('routeLoading', false);
    },
    // TODO: Move all CSS shenanigans to app route
    activate: function () {
      this._super(...arguments);
      var cssClass = this.getCssClass();
      var authenticated = this.get('session.isAuthenticated');
      if (authenticated) {
        document.querySelector('body').classList.add(cssClass);

        /**
         * Because the application route activates just once, and never
         * deactivates, we remove its class every time we activate a route
         * other than application.
         */
        if (cssClass !== 'application') {
          document.querySelector('body').classList.remove('application');
        }
      } else {
        // force white body background when not authenticated
        document.querySelector('body').classList.add('white-background');
      }

      /**
       * Add route name as class to outlet container to enable variable page
       * content width per route.
       */
      Ember.run.schedule('afterRender', () => {
        const container = document.querySelector('#outlet-container');
        if (Ember.isNone(container)) {
          return;
        }
        container.classList.add(cssClass);
      });
    },
    deactivate: function () {
      this._super(...arguments);
      const body = document.querySelector('body');
      const outlet = document.querySelector('#outlet-container');
      const className = this.getCssClass();
      body.classList.remove(className);
      body.classList.remove('white-background');
      if (!Ember.isNone(outlet)) {
        outlet.classList.remove(className);
      }

      /*
      Assuming we are going to the index page, since no other route's class has been added.
      Modal is a special case here. We want to add the application class even when navigation form modal to index.
      */
      const classList = document.body.classList;
      if (classList.contains('ember-application') || classList.contains('ember-application modal-open')) {
        classList.add('application');
      }
    },
    getCssClass: function () {
      return Ember.String.dasherize(this.routeName.replace(/\./g, '-'));
    },
    goBack: function (parameter, confirmExit, enableCurrentPath) {
      var previous_route = popRoute();
      if (Ember.isNone(confirmExit)) {
        confirmExit = true;
      }

      // In case user goes A -> B/1 -> B/2 -> C, goBack sequence should be C -> B/2 -> A, not
      // C -> B/2 -> B/1 -> A
      if (enableCurrentPath) {
        previous_route = popRoute();
      } else {
        while (!Ember.isNone(previous_route) && previous_route.route === this.current_path) {
          previous_route = popRoute();
        }
      }
      if (Ember.isNone(previous_route)) {
        if (confirmExit) {
          this.set('exitConfirmed', true);
        }
        this.transitionToDefaultRoute();
      } else {
        this.set('going_back', true);
        let transition = this._transition(previous_route.route, previous_route.param, confirmExit);
        transition.then(() => {
          if (transition.isAborted) {
            this.goBack(parameter, confirmExit);
          }
        });
      }
    },
    transitionToDefaultRoute() {
      this.transitionTo(this.defaultReturnRoute);
    },
    _routeMixinHandleWillTransition(transition) {
      if (this.going_back) {
        this.set('going_back', false);
      }
      if (CONFIRM_TRANSITIONS && !Ember.isNone(transition) && this.confirmExit && !this.exitConfirmed && transition.to.queryParams.no_confirm !== 'true') {
        transition.abort();
        if (this.confirmationScheduled) {
          return false;
        }
        this.set('confirmationScheduled', true);
        this.confirmationDialog.confirm({
          title: this.i18n.t('General.routeExitDlgTitle'),
          content: this.i18n.t('General.routeExitDlgContent')
        }).then(confirmed => {
          this.set('confirmationScheduled', false);
          if (!confirmed) {
            return;
          }
          this.set('exitConfirmed', true);
          let cleanupFinished = _abstract.EMPTY_PROMISE;
          if (_config.default.Common.ENABLE_UNLOAD_ON_CONFIRMED_LEAVE) {
            const controller = this.controller;
            if (!Ember.isNone(controller) && !Ember.isNone(controller.unloadRecords)) {
              cleanupFinished = controller.unloadRecords();
            }
          }
          cleanupFinished.then(() => transition.retry());
        });
      }
      return true;
    },
    actions: {
      loading: function () {
        this._super();
        (0, _routing.getRoutingStatus)().set('routeLoading', true);
      },
      // XXX TODO: location history is still modified
      willTransition: function (transition) {
        // XXX XXX
        if (this._routeMixinHandleWillTransition(transition)) {
          this._super(transition);
        }
      },
      didTransition: function () {
        this._super();

        // TODO: Remove currentRoute from app_ctr, replacing it with router
        // service route metadata (requires ember 3.10)
        var app_ctr = this.controllerFor('application');
        app_ctr.set('currentRoute', this);

        // Ugly, ugly hacks
        if (this.current_path !== 'index') {
          pushRoute(this.current_path, this.param);
        }
      },
      go: function (route, param, queryParams = {}) {
        this._transition(route, param, true, queryParams);
      },
      goBack: function (parameter, confirmExit, enableCurrentPath) {
        if (Ember.isNone(confirmExit)) {
          confirmExit = true;
        }
        this.goBack(parameter, confirmExit, enableCurrentPath);
      },
      error: function (error, transition) {
        (0, _notifications.reportError)(error);
        transition.abort();

        // XXX XXX XXX XXX XXX XXX
        this.transitionTo('/not-found');
        (0, _errors.processError)(error);
      }
    }
  });
});
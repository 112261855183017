define("plutof/components/project/record-project", ["exports", "@glimmer/component", "plutof/utils/notifications"], function (_exports, _component, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createRecordProjectModel = createRecordProjectModel;
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _dec3, _dec4, _dec5, _class2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.Project"}}
      data-test="Record::Project"
  >
      {{#if @data.projectIsPrivate}}
          <Common::PrivateRelation />
      {{else}}
          <Layout::RegularGrid
              @columns={{2}}
              @cellGap={{true}}
          >
              <div>
                  <div class="column-heading flex-row">
                      <Common::LocalLabel @label="Projects.project" />
  
                      {{#unless @hideHelper}}
                          <Information::DialogTrigger
                              @title={{i18n-t "Sample.area.areaSelectionHelper"}}
                              @content="information/content/general/area-project-filter" />
                      {{/unless}}
                  </div>
  
                  {{#async/bind-relation @data.project as |value update|}}
                      <Project::AutoComplete
                          @value={{value}}
                          @update={{fn this.update update}}
                          @disabled={{@disabled}}
                          data-test="project" />
                  {{/async/bind-relation}}
              </div>
          </Layout::RegularGrid>
  
          <Project::View::RecordProjectFields @project={{@data.project}} />
      {{/if}}
  </PlutofPanel::Simple>
  
  */
  {
    "id": "Y8OswF51",
    "block": "{\"symbols\":[\"value\",\"update\",\"@disabled\",\"@hideHelper\",\"@data\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Record::Project\"]],[[\"@title\"],[[30,[36,1],[\"General.Project\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[32,5,[\"projectIsPrivate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"common/private-relation\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"layout/regular-grid\",[],[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"column-heading flex-row\"],[12],[2,\"\\n                    \"],[8,\"common/local-label\",[],[[\"@label\"],[\"Projects.project\"]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"                        \"],[8,\"information/dialog-trigger\",[],[[\"@title\",\"@content\"],[[30,[36,1],[\"Sample.area.areaSelectionHelper\"],null],\"information/content/general/area-project-filter\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,5,[\"project\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,\"project/auto-complete\",[[24,\"data-test\",\"project\"]],[[\"@value\",\"@update\",\"@disabled\"],[[32,1],[30,[36,0],[[32,0,[\"update\"]],[32,2]],null],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,\"project/view/record-project-fields\",[],[[\"@project\"],[[32,5,[\"project\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"i18n-t\",\"unless\",\"async/bind-relation\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/project/record-project.hbs"
    }
  });
  // XXX TODO: Atm most of the modules store project in sample model, where it was
  // relevant originally. So that is what gets passed to Project::RecordProject.
  //
  // However, it should be replaces with this, because sample and occurrence project
  // can now be different (for example, when sequence is linked to the specimen and
  // reuses its sample). Because there is no time atm for a proper refactor, this
  // class is duck-compatible with sample model for the purposes of RecordProject
  let RecordProjectModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, _class = class RecordProjectModel {
    constructor({
      project,
      projectIsPrivate
    }) {
      _initializerDefineProperty(this, "_project", _descriptor, this);
      _initializerDefineProperty(this, "projectIsPrivate", _descriptor2, this);
      this._project = project;
      this.projectIsPrivate = projectIsPrivate;
    }
    get project() {
      return this._project;
    }
    set project(project) {
      this._project = project;
      this.projectIsPrivate = false;
      return project;
    }
    apply(occurrence) {
      if (!this.projectIsPrivate) {
        occurrence.set('project', this.project);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_project", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "projectIsPrivate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  async function createRecordProjectModel(occurrence) {
    try {
      const project = await occurrence.project;
      return new RecordProjectModel({
        project,
        projectIsPrivate: false
      });
    } catch (err) {
      return new RecordProjectModel({
        project: null,
        projectIsPrivate: true
      });
    }
  }
  let RecordProject = (_dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, _class2 = class RecordProject extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor3, this);
    }
    async checkRecordPermissionsChanges(project) {
      const record = this.args.permissionCheckRecord;
      const oldProject = await record.project;
      const isPublic = oldProject ? oldProject.is_public : record.is_public;
      const willBePublic = project ? project.is_public : record.is_public;
      (0, _notifications.clearNotifications)();
      if (isPublic !== willBePublic) {
        const message = `Access.derivedVisibilityChange.${willBePublic ? 'toPublic' : 'toPrivate'}`;
        (0, _notifications.displayNotification)('warning', this.i18n.translate(message), null, 0, false);
      }
    }
    update(sync, project) {
      // Hacky but that's how 2-way binding work. If there are more such
      // places, might want to add a way to pass post-update to bind-relation
      sync(project);
      if (this.args.permissionCheckRecord && !this.args.permissionCheckRecord.isNew) {
        this.checkRecordPermissionsChanges(project);
      }
    }
  }, _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "i18n", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "checkRecordPermissionsChanges", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "checkRecordPermissionsChanges"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "update", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype), _class2);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RecordProject);
});
define("plutof/models/photobank/customlistitem", ["exports", "@ember-data/model", "plutof/mixins/display-name-from-value", "plutof/models/plutof-model"], function (_exports, _model, _displayNameFromValue, _plutofModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CustomListItem = (_dec = (0, _model.attr)('number'), _dec2 = (0, _displayNameFromValue.displayName)('custom_classification'), _dec3 = (0, _model.belongsTo)('photobank/customlistitem', {
    inverse: null
  }), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = Ember.computed('original_id', 'name'), _class = class CustomListItem extends _plutofModel.default.extend(_displayNameFromValue.DisplayNameMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "custom_classification", _descriptor, this);
      _defineProperty(this, "optionMixinModelName", 'photobank.customlistitem');
      _initializerDefineProperty(this, "customClassificationyDisplayName", _descriptor2, this);
      _initializerDefineProperty(this, "parent", _descriptor3, this);
      _initializerDefineProperty(this, "original_id", _descriptor4, this);
      _initializerDefineProperty(this, "name", _descriptor5, this);
      _initializerDefineProperty(this, "description", _descriptor6, this);
    }
    get representation() {
      return Ember.isEmpty(this.original_id) ? this.name : `${this.original_id} - ${this.name}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "custom_classification", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "customClassificationyDisplayName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "parent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "original_id", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _class);
  var _default = _exports.default = CustomListItem;
});
define("plutof/components/observation/moderation-table", ["exports", "@ember-decorators/object", "plutof/misc/profile_settings", "plutof/mixins/profile-predefined-columns", "plutof/mixins/scrollbar-top", "plutof/utils/notifications"], function (_exports, _object, _profile_settings, _profilePredefinedColumns, _scrollbarTop, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const ORDER_EXCLUDE_LIST = ['related_objects'];
  const ASCEDING_DEFAULT = true;
  let ModerationTable = (_dec = Ember.inject.service, _dec2 = Ember.computed('filters.ordering'), _dec3 = Ember.computed('pagination.objects.[]'), _dec4 = Ember.computed('pagination.pageSize', 'pagination.pageNumber'), _dec5 = Ember.computed.filterBy('entries', 'selected'), _dec6 = Ember.computed('columns'), _dec7 = (0, _object.observes)('allRowsSelected'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._tracked, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _class = class ModerationTable extends Ember.Component.extend(_profilePredefinedColumns.default, _scrollbarTop.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "profileEndPoint", 'moderate_observation');
      // Save columns to profile
      _defineProperty(this, "showColumnMenu", false);
      _defineProperty(this, "tableBodyId", 'search-results-table-body');
      _defineProperty(this, "tableHeaderRowId", 'search-results-table-header-row');
      _initializerDefineProperty(this, "activeEntries", _descriptor2, this);
      _initializerDefineProperty(this, "openForwardingDialog", _descriptor3, this);
      _defineProperty(this, "observationsBeingForwarded", []);
    }
    get absoluteOrdering() {
      const ordering = this.filters.ordering;
      if (Ember.isNone(ordering)) {
        return null;
      }
      return ordering.startsWith('-') ? ordering.slice(1) : ordering;
    }
    set absoluteOrdering(value) {
      return value;
    }
    init() {
      super.init(...arguments);

      // Used by ProfilePrefedinedColumns mixin.
      (0, _profile_settings.get_personal_settings)(this.store).then(ps => {
        this.set('personalSettings', ps);
      });
    }
    get entries() {
      return this.pagination.objects.map(observation => ({
        selected: false,
        observation
      }));
    }
    get startingIndex() {
      return this.pagination.pageSize * (this.pagination.pageNumber - 1) + 1;
    }
    get headers() {
      const columns = this.columns;
      return Object.keys(columns).map(field => {
        const config = {
          label: this.i18n.t(`moderation.advancedTableFields.${field}`),
          absoluteOrdering: field,
          ordering: !ORDER_EXCLUDE_LIST.includes(field)
        };
        return Ember.Object.create({
          name: field,
          config: config,
          ascendingOrder: !this.filters.ordering.startsWith('-'),
          isVisible: columns[field]
        });
      });
    }
    allRowsSelectedChanged() {
      this.entries.forEach(entry => Ember.set(entry, 'selected', this.allRowsSelected));
    }
    hideOrShowProfileHeaders(headers, savedItems) {
      if (Ember.isEmpty(savedItems)) {
        return;
      }
      super.hideOrShowProfileHeaders(...arguments);
      this.hideOrShowColumns(savedItems);
    }
    hideOrShowColumns(savedItems) {
      Object.keys(this.columns).forEach(field => {
        this.columns.set(field, savedItems.includes(field));
      });
    }
    saveBulkAction(comment, status) {
      const entriesIds = this.activeEntries.mapBy('observation.id');
      const endpoint = '/taxonoccurrence/observation/observation-moderate/comments/bulk-moderate/';
      return this.ajax.put(endpoint, {
        data: {
          comment,
          moderation_status: status,
          observation_ids: entriesIds
        }
      });
    }
    removeUpdatedEntries() {
      this.entries.removeObjects(this.activeEntries);
      this.pagination.reload();
    }

    // Show or hide all columns
    setColumnsVisibility(state) {
      this.headers.forEach(header => {
        header.set('isVisible', state);
      });
      Object.keys(this.columns).forEach(field => {
        this.columns.set(field, state);
      });
      this.updateProfileToggleAll();
    }
    toggleColumn(header) {
      header.toggleProperty('isVisible');
      this.columns.toggleProperty(header.name);
      this.updateProfileToggle(header);
    }
    columnMenuButton(state) {
      const show = Ember.isNone(state) ? !this.showColumnMenu : state;
      this.set('showColumnMenu', show);
    }
    showRejectPanel() {
      this.set('bulkRejectPopup', true);
    }
    sortBy(header) {
      if (header.name === this.absoluteOrdering) {
        header.toggleProperty('ascendingOrder');
      } else {
        header.set('ascendingOrder', ASCEDING_DEFAULT);
      }
      this.set('absoluteOrdering', header.name);
      const ordering = header.ascendingOrder ? header.name : '-' + header.name;
      this.updateOrdering(ordering);
    }

    // Aliasing @accept/@reject to throw away the event that {{on}} passes through. It
    // usually isn't a problem, but @accept has an optional parameter that gets bound to it
    acceptRow(id) {
      return this.accept(id);
    }
    bulkAccept() {
      return this.saveBulkAction('Accepted', 1).then(() => {
        this.removeUpdatedEntries();
        (0, _notifications.displayNotification)('success', this.i18n.t('moderation.successfullyAccepted'));
      }, _notifications.reportError);
    }
    bulkReject(observation, comment) {
      return this.saveBulkAction(comment, 0).then(() => {
        this.set('bulkRejectPopup', false);
        this.removeUpdatedEntries();
        (0, _notifications.displayNotification)('success', this.i18n.t('moderation.reasonOfDenial'));
      }, _notifications.reportError);
    }
    prepareForwarding(observations) {
      this.observationsBeingForwarded = observations;
      this.openForwardingDialog = true;
    }
    cancelForwarding() {
      this.openForwardingDialog = false;
    }
    async forward(moderators) {
      try {
        await this.ajax.post('/taxonoccurrence/observation/observations/forward/', {
          data: {
            observations: this.observationsBeingForwarded.mapBy('id'),
            moderators: moderators.map(user => parseInt(user.id))
          }
        });
        for (const observation of this.observationsBeingForwarded) {
          const newModerators = moderators.map(moderator => ({
            name: moderator.fullName
          }));

          // XXX
          Ember.set(observation, 'forwarded', (observation.forwarded || []).concat(newModerators));
        }
        const forwardedTo = {
          moderators: moderators.mapBy('fullName').join(', ')
        };
        (0, _notifications.displayNotification)('success', this.i18n.t('moderation.forwarding.forwardedTo', {
          hash: forwardedTo
        }));
      } catch (err) {
        (0, _notifications.reportError)(err);
      } finally {
        this.openForwardingDialog = false;
      }
    }
    bulkForward() {
      this.prepareForwarding(this.activeEntries.mapBy('observation'));
    }
    cancelReject() {
      this.set('bulkRejectPopup', false);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "absoluteOrdering", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "absoluteOrdering"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "entries", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "entries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startingIndex", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "startingIndex"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeEntries", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allRowsSelectedChanged", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "allRowsSelectedChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setColumnsVisibility", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setColumnsVisibility"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleColumn", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleColumn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "columnMenuButton", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "columnMenuButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showRejectPanel", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showRejectPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortBy", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "sortBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "acceptRow", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "acceptRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bulkAccept", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "bulkAccept"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bulkReject", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "bulkReject"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "openForwardingDialog", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "prepareForwarding", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "prepareForwarding"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelForwarding", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "cancelForwarding"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "forward", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "forward"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bulkForward", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "bulkForward"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelReject", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "cancelReject"), _class.prototype), _class);
  var _default = _exports.default = ModerationTable;
});
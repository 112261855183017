define("plutof/components/unite-table", ["exports", "@ember-decorators/object", "ember-concurrency", "plutof/helpers/icon", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/profile_settings", "plutof/models/users/usergroup", "plutof/utils/access", "plutof/utils/i18n", "plutof/utils/notifications", "plutof/utils/pagination", "plutof/utils/structures"], function (_exports, _object, _emberConcurrency, _icon, _abstract, _config, _profile_settings, _usergroup, _access, _i18n, _notifications, _pagination, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const i18n = (0, _i18n.getI18n)();
  const PreprocessSHSequences = Ember.Mixin.create({
    preprocessPage(clusterSequences) {
      return clusterSequences.map(cseq => {
        const seq = cseq.sequence;
        const ids = seq.identifiers;
        seq.compound_id = Ember.isEmpty(ids) ? seq.id : ids.mapBy('id_in_database').join(' | ');
        if (cseq.reference_sequence) {
          seq.is_reference = true;
        }

        // TODO: Pass SH id via a normal property
        const shID = parseInt(this.filters.dshcluster);

        // Not async.
        return Ember.Object.create({
          id: cseq.id,
          reference_sequence: cseq.reference_sequence,
          sequence: seq,
          area: seq.area.name === 'Unspecified' ? '' : seq.area.name,
          order: cseq.cheat_combined_order,
          collapse: cseq.collapse,
          cseqs: (0, _structures.reversed)(cseq.colours.sortBy('ordering')).map(linkedSH => {
            const inActiveCluster = linkedSH.dsh_id === shID;
            const color = Ember.isEmpty(linkedSH.dshcolour) ? '#fff' : `#${linkedSH.dshcolour}`;
            const style = Ember.String.htmlSafe(inActiveCluster ? `background: repeating-linear-gradient(-45deg, ${color}, ${color} 5px, white 5px, white 10px)` : `background-color: ${color}`);

            // TODO: Set isInActiveDSHCluster and style here
            return {
              style,
              inActiveCluster,
              clusterID: linkedSH.dsh_id,
              color
            };
          }),
          aligned_sequence: cseq.aligned_sequence
        });
      }).sortBy('order');
    }
  });
  const STATUS_ICONS = [{
    field: 'locked',
    icon: 'fas fa-lock',
    description: i18n.t('DoiSHref.locked')
  }, {
    field: 'lowQuality',
    icon: 'icon-unite-sequence-status icon-unite-sequence-status--low-quality',
    description: i18n.t('DoiSHref.lowq')
  }, {
    field: 'chimeric',
    icon: 'icon-unite-sequence-status icon-unite-sequence-status--chimeric',
    description: i18n.t('DoiSHref.chimeric')
  }, {
    field: 'excluded',
    icon: 'icon-unite-sequence-status icon-unite-sequence-status--excluded',
    description: i18n.t('DoiSHref.EX')
  }];
  let SequenceEntry = (_dec = (0, _object.observes)('sequence.id'), _class = class SequenceEntry extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "sequence", null);
    }
    init() {
      super.init(...arguments);
      this.initExcludedSequence();
    }
    initExcludedSequence() {
      var id = this.get('sequence.id');
      if (!Ember.isNone(id)) {
        this.set('excludedLocally', this.get('sequence.is_excluded'));
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "initExcludedSequence", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "initExcludedSequence"), _class.prototype), _class);
  let UniteTable = (_dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec5 = Ember.computed.reads('loadSequences.lastSuccessful.value'), _dec6 = Ember.computed('pagination.objects.[]'), _dec7 = Ember._tracked, _dec8 = Ember.computed('collapseLevels'), _dec9 = Ember._action, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = (0, _object.observes)('dshcluster'), _dec15 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember.computed('entries.[]'), _dec21 = Ember.computed('canModifyRefseqs', 'canModifyExclusions', 'selectedColumns.[]'), _class2 = class UniteTable extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _defineProperty(this, "cleanups", []);
      _defineProperty(this, "loadedSH", null);
      _initializerDefineProperty(this, "loadSequences", _descriptor3, this);
      _initializerDefineProperty(this, "pagination", _descriptor4, this);
      _initializerDefineProperty(this, "currentCollapseLevel", _descriptor5, this);
      _initializerDefineProperty(this, "repSeq", _descriptor6, this);
      _initializerDefineProperty(this, "repSeqStats", _descriptor7, this);
      _initializerDefineProperty(this, "repSeqStatsVisible", _descriptor8, this);
      // TODO: This should probably just run once, barring the dshcluster.is_active
      _initializerDefineProperty(this, "_updateRights", _descriptor9, this);
      _defineProperty(this, "selectedEntries", []);
      _defineProperty(this, "thresholds", ['0.5', '1.0', '1.5', '2.0', '2.5', '3.0']);
      _defineProperty(this, "allColumns", [{
        id: 'uniteTaxon',
        name: i18n.t('DoiSHref.UniteTaxName')
      }, {
        id: 'insdTaxon',
        name: i18n.t('DoiSHref.INSDtaxName')
      }, {
        id: 'source',
        name: i18n.t('clusters.source')
      }, {
        id: 'interaction',
        name: i18n.t('clusters.interaction')
      }, {
        id: 'area',
        name: i18n.t('clusters.area')
      }, {
        id: 'refseq',
        name: i18n.t('UniteSH.refseq')
      }, {
        id: 'actions',
        name: i18n.t('UniteSH.actions')
      }]);
      _defineProperty(this, "selectedColumns", this.allColumns.map(col => col.id));
    }
    init() {
      super.init(...arguments);
      this.updateRights();
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      if (this.dshcluster !== this.loadedSH) {
        this.switchCollapseLevel(0);
        this.loadedSH = this.dshcluster;
      }
    }
    get entries() {
      if (!this.pagination) {
        return [];
      }
      return this.pagination.objects.map(seq => SequenceEntry.create(seq));
    }
    get haveDifferentCollapseLevels() {
      return this.collapseLevels.length > 1;
    }
    switchCollapseLevel(level) {
      this.currentCollapseLevel = level;
      this.loadSequences.perform();
    }
    async showRepresentativeSequenceStats(entry) {
      const stats = await this.ajax.request(`globalkey/dshclustersequencestables/${entry.id}/collapsed-descendants/?level=${this.currentCollapseLevel}`);
      this.repSeq = entry;
      this.repSeqStats = stats;
      this.repSeqStatsVisible = true;
    }
    closeRepresentativeSequenceStats() {
      this.repSeqStatsVisible = false;
    }
    updateRights() {
      if (Ember.isNone(this.dshcluster) || Ember.isPresent(this.canModifyRefseqs)) {
        return;
      }
      this._updateRights.perform();
    }
    async loadUpdateRights() {
      const ALLOW_EVERYTHING = {
        refseq: true,
        exclusion: true
      };

      // Not sure what this is
      if (!this.dshcluster.is_active) {
        return {
          refseq: false,
          exclusion: true
        };
      }
      const profile = await (0, _profile_settings.get_personal_settings)(this.store);
      const user = await profile.get('user');
      if (user.is_superuser) {
        return ALLOW_EVERYTHING;
      }
      if (this.currentRefSeq) {
        const refseqPermission = await (0, _access.get_permissions)(this.store, this.ajax, this.currentRefSeq);
        if (refseqPermission.canModify) {
          return ALLOW_EVERYTHING;
        }
      }
      const inUNITEGroup = await (0, _usergroup.isGroupMember)(this.store, user.id, _config.default.Globalkey.UNITE_GROUP_ID);
      return {
        refseq: inUNITEGroup && !this.currentRefSeq,
        exclusion: inUNITEGroup
      };
    }
    // Sets new and removes old Reference Sequence.
    updateReferenceSequence() {
      const self = this;
      const store = self.get('store');
      var entries = self.get('entries');
      const currentReferenceEntry = entries.findBy('sequence.is_reference');
      const currentID = Ember.isNone(currentReferenceEntry) ? null : currentReferenceEntry.get('sequence.id');
      const initialRefS = self.get('currentRefSeq');
      let initialIDPromise = _abstract.EMPTY_PROMISE;
      if (!Ember.isNone(initialRefS)) {
        initialIDPromise = initialRefS.get('sequence').then(seq => seq.get('id'));
      }
      return initialIDPromise.then(initialID => {
        function setRefseq(id) {
          return store.findRecord('taxonoccurrence/sequence/sequence', id).then(seq => {
            return store.createRecord('globalkey/referencesequence', {
              dshcluster: self.get('dshcluster'),
              sequence: seq
            }).save().then(newRefS => {
              let refEntry = entries.findBy('sequence.id', id);
              refEntry.set('reference_sequence', newRefS);
              self.set('currentRefSeq', newRefS);
            });
          });
        }
        if (Ember.isNone(initialID)) {
          if (Ember.isNone(currentID)) {
            return _abstract.EMPTY_PROMISE;
          }
          return setRefseq(currentID);
        }

        // No sequence is activated locally
        if (Ember.isNone(currentID)) {
          // If the active refseq was not loaded, we don't have an entry for it,
          // so currentReferenceEntry is none. But this doesn't mean that refseq
          // was deactivated
          if (Ember.isNone(entries.findBy('sequence.id', parseInt(initialID)))) {
            return _abstract.EMPTY_PROMISE;
          }
          return initialRefS.destroyRecord().then(() => {
            self.set('currentRefSeq', null);
          });
        }

        // Local and saved refseqs are the same
        if (initialID.toString() === currentID.toString()) {
          return _abstract.EMPTY_PROMISE;
        }

        // Refseq was changed locally
        return initialRefS.destroyRecord().then(() => {
          return setRefseq(currentID);
        });
      });
    }
    updateExcludedSeqs() {
      var store = this.store;
      var entries = this.entries;
      return Ember.RSVP.all(entries.map(entry => {
        var seq = entry.get('sequence');
        var isExcluded = Ember.get(seq, 'is_excluded');
        var excludedLocally = entry.get('excludedLocally');

        // Meaning database and local exclude state are different.
        if (isExcluded !== excludedLocally) {
          if (excludedLocally) {
            return store.findRecord('taxonoccurrence/sequence/sequence', Ember.get(seq, 'id')).then(s => {
              return store.createRecord('globalkey/excludedsequence', {
                sequence: s,
                reason: ''
              }).save().then(() => {
                Ember.set(seq, 'is_excluded', true);
              });
            });
          } else {
            return store.queryRecord('globalkey/excludedsequence', {
              sequence: Ember.get(seq, 'id')
            }).then(exclSeq => {
              return exclSeq.destroyRecord().then(() => {
                Ember.set(seq, 'is_excluded', false);
              });
            });
          }
        } else {
          return _abstract.EMPTY_PROMISE;
        }
      }));
    }
    setReference(entry, state) {
      this.entries.mapBy('sequence').forEach(s => Ember.set(s, 'is_reference', false));
      entry.set('sequence.is_reference', state);
      this.set('modified', true);
    }
    toggleExclusion(entry) {
      entry.toggleProperty('excludedLocally');
      this.set('modified', true);
    }
    save() {
      return this.updateReferenceSequence().then(() => {
        return this.updateExcludedSeqs().then(() => {
          this.set('modified', false);
          (0, _notifications.displayNotification)('success', i18n.t('General.done'));
        });
      }, _notifications.reportError);
    }
    scrollbarScrollMirror() {
      Ember.run.debounce(this, this._scrollbarScrollMirror, 33);
    }
    get alignmentSequences() {
      return this.entries.map(entry => ({
        id: entry.sequence.id,
        compoundID: entry.sequence.compound_id,
        sequence: entry.sequence.is_locked ? '' : entry.aligned_sequence,
        collapsedChildren: entry.collapse.descendant_count,
        uniteTaxon: entry.sequence.taxon_node,
        insdTaxon: entry.sequence.insd_taxon_node,
        source: entry.sequence.source && entry.sequence.source.source_name !== 'sample' ? {
          source: entry.sequence.source.source_name,
          typification: entry.sequence.source.typification
        } : entry.sequence.seq_source && {
          source: entry.sequence.seq_source,
          typification: null
        },
        interactingTaxa: entry.sequence.it_taxon_nodes,
        country: entry.sequence.country.name,
        isReference: entry.sequence.is_reference,
        status: {
          locked: entry.sequence.is_locked,
          lowQuality: entry.sequence.quality_status,
          chimeric: entry.sequence.is_chimeric,
          excluded: entry.sequence.is_excluded
        },
        clusterLinks: entry.cseqs.slice(entry.cseqs.length - this.thresholds.length).map(cseq => ({
          id: cseq.clusterID.toString(),
          name: cseq.clusterID.toString(),
          color: cseq.color,
          url: this.router.urlFor('unite.view', cseq.clusterID)
        })),
        entry // For event handlers
      }));
    }
    performCleanups() {
      this.cleanups.forEach(([target, event, handler]) => {
        target.removeEventListener(event, handler);
      });
      this.cleanups = [];
    }
    willDestroyElement() {
      super.willDestroyElement();
      this.performCleanups();
    }
    get buildAlignmentConfig() {
      return fields => {
        let currentRefseqIcon;
        const createButton = (icon, handler) => {
          const button = document.createElement('button');
          button.className = 'btn btn-default btn-link btn-xs unite-table__alignment-button';
          const content = document.createElement('span');
          content.className = icon;
          function _handler(event) {
            event.preventDefault();
            handler();
          }
          button.addEventListener('click', _handler);
          button.appendChild(content);
          this.cleanups.push([button, 'click', _handler]);
          return [button, content];
        };
        const router = this.router;
        function taxonLink(label, field) {
          return fields.link(label, seq => {
            const taxon = seq[field];
            if (taxon) {
              return {
                name: taxon.name,
                url: router.urlFor('taxonomy.view', taxon.id)
              };
            } else {
              return {
                name: null,
                url: null
              };
            }
          }, {
            openInNewTab: true
          });
        }
        const enabledColumns = new Set(this.selectedColumns);
        return {
          thresholds: this.thresholds,
          disabledThresholds: this.collapseLevels.length > 1 && this.currentCollapseLevel === 0 ? [this.thresholds[0]] : [],
          activeCluster: this.dshcluster.id.toString(),
          stickyColumns: 2,
          fields: [fields.element('', seq => {
            const container = document.createElement('div');
            for (const {
              field,
              icon,
              description
            } of STATUS_ICONS) {
              if (seq.status[field]) {
                const iconElement = document.createElement('span');
                iconElement.className = `unite-table__sequence-status ${icon}`;
                iconElement.title = description;
                container.appendChild(iconElement);
              }
            }
            return container;
          }), fields.link(i18n.t('UniteSH.sequenceID'), seq => ({
            name: seq.compoundID,
            url: this.router.urlFor('sequence.view', seq.id)
          }), {
            openInNewTab: true,
            shortenTo: 20
          }), fields.element('', seq => {
            const button = document.createElement('button');
            button.classList.add('btn-fake-link');
            if (seq.collapsedChildren > 0) {
              const content = `+${seq.collapsedChildren}`;
              button.innerHTML = content;
              const handler = async event => {
                event.stopPropagation();
                button.innerHTML = '<span class="loading-icon"></span>';
                button.disabled = true;
                try {
                  await this.showRepresentativeSequenceStats(seq.entry);
                } finally {
                  button.innerHTML = content;
                  button.disabled = false;
                }
              };
              button.addEventListener('click', handler);
              this.cleanups.push([button, 'click', handler]);
            }
            return button;
          }), enabledColumns.has('uniteTaxon') && fields.element(i18n.t('DoiSHref.UniteTaxName'), seq => {
            const container = document.createElement('span');
            if (seq.uniteTaxon && seq.uniteTaxon) {
              const link = document.createElement('a');
              link.href = router.urlFor('taxonomy.view', seq.uniteTaxon.id);
              link.target = '_blank';
              link.innerText = seq.uniteTaxon.name;
              container.appendChild(link);
              if (seq.uniteTaxon.typification) {
                const typification = document.createElement('i');
                typification.className = 'plutof-yellow-dark';
                typification.innerText = ` ${seq.uniteTaxon.typification}`;
                container.appendChild(typification);
              }
            }
            return container;
          }), enabledColumns.has('insdTaxon') && taxonLink(i18n.t('DoiSHref.INSDtaxName'), 'insdTaxon'), enabledColumns.has('source') && fields.element(i18n.t('clusters.source'), seq => {
            const container = document.createElement('span');
            if (seq.source) {
              const source = document.createElement('span');
              // Yes, it's the space between two parts, I regret nothing
              source.innerText = seq.source.source + ' ';
              container.appendChild(source);
              if (seq.source.typification) {
                const typification = document.createElement('i');
                typification.className = 'plutof-yellow-dark';
                typification.innerText = seq.source.typification;
                container.appendChild(typification);
              }
            }
            return container;
          }),
          // enabledColumns.has('source') && fields.text(i18n.t('clusters.source'), seq => seq.source || ''),
          enabledColumns.has('interaction') && fields.text(i18n.t('clusters.interaction'), seq => seq.interactingTaxa.map(t => t.name).join(', ')), enabledColumns.has('area') && fields.text(i18n.t('clusters.area'), seq => seq.country), enabledColumns.has('refseq') && fields.element(i18n.t('UniteSH.refseq'), seq => {
            if (this.canModifyRefseqs) {
              let button, content;
              [button, content] = createButton(refseqIcon(seq.entry.sequence.is_reference), () => {
                this.setReference(seq.entry, !seq.entry.sequence.is_reference);
                if (seq.entry.sequence.is_reference) {
                  content.className = refseqIcon(true);
                  if (currentRefseqIcon) {
                    currentRefseqIcon.className = refseqIcon(false);
                  }
                  currentRefseqIcon = content;
                } else {
                  content.className = refseqIcon(false);
                  currentRefseqIcon = null;
                }
              });
              function refseqIcon(isReference) {
                return isReference ? (0, _icon.icon)('ok') : 'fas fa-minus';
              }
              if (seq.entry.sequence.is_reference) {
                currentRefseqIcon = content;
              }
              return button;
            } else {
              const content = document.createElement('span');
              if (seq.entry.sequence.is_reference) {
                content.className = (0, _icon.icon)('ok');
              }
              return content;
            }
          }), enabledColumns.has('actions') && fields.element(i18n.t('UniteSH.actions'), seq => {
            const container = document.createElement('div');
            if (this.canModifyExclusions) {
              function excludeIcon(excludedLocally) {
                return excludedLocally ? 'fas fa-plus' : 'icon-remove';
              }
              const [button, content] = createButton(excludeIcon(seq.entry.excludedLocally), () => {
                this.toggleExclusion(seq.entry);
                content.className = excludeIcon(seq.entry.excludedLocally);
              });
              container.appendChild(button);
            }
            return container;
          })].filter(Boolean),
          nucleotides: {
            width: 12,
            palette: {
              'A': ['white', '#fb0018'],
              'G': ['black', '#fffd33'],
              'C': ['black', '#38fd2a'],
              'T': ['white', '#0022fb'],
              'default': ['black', '#dddddd']
            }
          },
          guideline: {
            step: 10
          },
          selectionChanged: selection => {
            this.set('selectedEntries', selection.map(s => s.entry));
          }
        };
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "loadSequences", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        // Loading the entire thing because user fetching pages one-by-one
        // is more costly than building everything at once
        //
        // This is still a pagination just in case, but it doesn't have to be
        const pagination = yield (0, _pagination.paginateAjaxEndpoint)(this.ajax, 'globalkey/dshclustersequencestables/sequence_ordering', {
          pageSize: 500,
          mixins: [_pagination.AccumulatingPaginationMixin, PreprocessSHSequences],
          countEndpoint: 'globalkey/dshclustersequencestables/sequence_ordering_count/',
          filters: {
            dshcluster: this.dshcluster.id,
            collapse_level: this.currentCollapseLevel
          }
        });
        yield pagination.loaded;
        while (!pagination.onLastPage) {
          yield pagination.nextPage();
        }
        return pagination;
      };
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "pagination", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "entries", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "entries"), _class2.prototype), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "currentCollapseLevel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "haveDifferentCollapseLevels", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "haveDifferentCollapseLevels"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "switchCollapseLevel", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "switchCollapseLevel"), _class2.prototype), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "repSeq", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "repSeqStats", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "repSeqStatsVisible", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "closeRepresentativeSequenceStats", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "closeRepresentativeSequenceStats"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateRights", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "updateRights"), _class2.prototype), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "_updateRights", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const {
          refseq,
          exclusion
        } = yield this.loadUpdateRights();
        this.setProperties({
          canModifyRefseqs: refseq,
          canModifyExclusions: exclusion
        });
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "setReference", [_dec16], Object.getOwnPropertyDescriptor(_class2.prototype, "setReference"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleExclusion", [_dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleExclusion"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "scrollbarScrollMirror", [_dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "scrollbarScrollMirror"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "alignmentSequences", [_dec20], Object.getOwnPropertyDescriptor(_class2.prototype, "alignmentSequences"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "buildAlignmentConfig", [_dec21], Object.getOwnPropertyDescriptor(_class2.prototype, "buildAlignmentConfig"), _class2.prototype), _class2);
  var _default = _exports.default = UniteTable;
});
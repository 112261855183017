define("plutof/components/search/filters/taxon", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="label-control">
      <div class="plutof-label label-control__label">
          {{i18n-t "Search.filters.taxon.label"}}
      </div>
  
      <Search::Filters::-LabelControlToggle
          @filter={{@filters.include_taxon_synonyms}}
          @label={{i18n-t "Search.filters.includeTaxonSynonyms.label"}}
          @tooltip={{i18n-t "Search.filters.includeTaxonSynonyms.tooltip"}} />
  
      <Search::Filters::-LabelControlToggle
          @filter={{@filters.parent_taxon}}
          @label={{i18n-t "Search.filters.includeSubtaxa.label"}}
          @tooltip={{i18n-t "Search.filters.includeSubtaxa.tooltip"}} />
  </div>
  
  <Taxonomy::Taxon::AutoComplete
      @update={{this.add}}
      @includeCommonNames={{@filters.taxon_node.queryArguments.include_commonnames}}
      class="filter-input-ac" />
  
  <Common::RecordList
      @records={{@filters.taxon_node.value}}
      @remove={{this.remove}} />
  
  */
  {
    "id": "R8T5FaPQ",
    "block": "{\"symbols\":[\"@filters\"],\"statements\":[[10,\"div\"],[14,0,\"label-control\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-label label-control__label\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Search.filters.taxon.label\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"search/filters/-label-control-toggle\",[],[[\"@filter\",\"@label\",\"@tooltip\"],[[32,1,[\"include_taxon_synonyms\"]],[30,[36,0],[\"Search.filters.includeTaxonSynonyms.label\"],null],[30,[36,0],[\"Search.filters.includeTaxonSynonyms.tooltip\"],null]]],null],[2,\"\\n\\n    \"],[8,\"search/filters/-label-control-toggle\",[],[[\"@filter\",\"@label\",\"@tooltip\"],[[32,1,[\"parent_taxon\"]],[30,[36,0],[\"Search.filters.includeSubtaxa.label\"],null],[30,[36,0],[\"Search.filters.includeSubtaxa.tooltip\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"taxonomy/taxon/auto-complete\",[[24,0,\"filter-input-ac\"]],[[\"@update\",\"@includeCommonNames\"],[[32,0,[\"add\"]],[32,1,[\"taxon_node\",\"queryArguments\",\"include_commonnames\"]]]],null],[2,\"\\n\\n\"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,1,[\"taxon_node\",\"value\"]],[32,0,[\"remove\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filters/taxon.hbs"
    }
  });
  let SearchTaxonFilter = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, _class = class SearchTaxonFilter extends _component.default {
    add(taxon) {
      if (!taxon) {
        return;
      }
      this.args.filters.taxon_node.value.pushObject(taxon);
    }
    remove(taxon) {
      this.args.filters.taxon_node.value.removeObject(taxon);
      if (this.args.removed) {
        this.args.removed(taxon);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "add", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchTaxonFilter);
});
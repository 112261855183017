define("plutof/components/gallery/image", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
      id="zoom-container"
      class="zoom-container {{if this.isZoomed 'zoom-container--zoomed'}}"
  >
      <img
          {{on "click" this.toggleZoom}}
          class="zoom-container__image {{if this.isZoomed 'zoom-container__image--zoomed'}}"
          ...attributes />
  </div>
  
  */
  {
    "id": "03JauJx7",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[10,\"div\"],[14,1,\"zoom-container\"],[15,0,[31,[\"zoom-container \",[30,[36,0],[[32,0,[\"isZoomed\"]],\"zoom-container--zoomed\"],null]]]],[12],[2,\"\\n    \"],[11,\"img\"],[16,0,[31,[\"zoom-container__image \",[30,[36,0],[[32,0,[\"isZoomed\"]],\"zoom-container__image--zoomed\"],null]]]],[17,1],[4,[38,1],[\"click\",[32,0,[\"toggleZoom\"]]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/gallery/image.hbs"
    }
  });
  function scrollTo(container, image, x, y) {
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    const relativeX = x / containerWidth;
    const relativeY = y / containerHeight;
    const imageX = relativeX * image.naturalWidth;
    const imageY = relativeY * image.naturalHeight;
    const scrollX = imageX - containerWidth / 2;
    const scrollY = imageY - containerHeight / 2;
    Ember.run.next(() => container.scrollTo(Math.round(scrollX), Math.round(scrollY)));
  }
  let GalleryImage = (_dec = Ember._tracked, _dec2 = Ember._action, _class = class GalleryImage extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isZoomed", _descriptor, this);
    }
    toggleZoom(event) {
      this.isZoomed = !this.isZoomed;
      if (this.isZoomed) {
        const container = document.getElementById('zoom-container');
        const image = event.target;
        scrollTo(container, image, event.layerX, event.layerY);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isZoomed", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleZoom", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleZoom"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GalleryImage);
});
define("plutof/utils/pagination/editable", ["exports", "plutof/utils/changeset", "plutof/utils/pagination"], function (_exports, _changeset, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EditablePaginatedTableModel = void 0;
  _exports.default = editablePagination;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class3, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _dec24, _dec25, _dec26, _class4, _descriptor17;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DirtyItemsPagination = (_dec = Ember._tracked, _dec2 = Ember.computed('primary.dirtyItems.[]', 'pageNumber'), _dec3 = Ember.computed('primary.dirtyItems.[]'), _dec4 = Ember.computed.reads('primary.pageSize'), _class = class DirtyItemsPagination extends _pagination.BasePagination {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "pageNumber", _descriptor, this);
      _initializerDefineProperty(this, "pageSize", _descriptor2, this);
    }
    get objects() {
      const start = (this.pageNumber - 1) * this.pageSize;
      return this.primary.dirtyItems.slice(start, start + this.pageSize);
    }
    get objectCount() {
      return this.primary.dirtyItems.length;
    }
    switchPage(number) {
      this.pageNumber = number;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "pageNumber", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "objects", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "objects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "objectCount", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "objectCount"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pageSize", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class); // XXX TODO: DRY with EditablePagination. When the dust settles, I think stuff
  // can be juggled around for a clearer mental/interface model
  let FilteredViewPagination = (_dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('pagination.objectCount', 'removedMatches.[]', 'addedMatches.[]'), _dec9 = Ember.computed.reads('pagination.pageSize'), _dec10 = Ember.computed('primary.removed.[]'), _dec11 = Ember.computed('primary.added.[]'), _class2 = class FilteredViewPagination extends _pagination.BasePagination {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "objects", _descriptor3, this);
      _initializerDefineProperty(this, "pageNumber", _descriptor4, this);
      _initializerDefineProperty(this, "isLoading", _descriptor5, this);
      _initializerDefineProperty(this, "pageSize", _descriptor6, this);
    }
    get objectCount() {
      return this.pagination.objectCount - this.removedMatches.length + this.addedMatches.length;
    }
    async switchPage(number) {
      this.isLoading = true;
      this.objects = await this._slice((number - 1) * this.pageSize, number * this.pageSize);
      this.pageNumber = number;
      this.isLoading = false;
    }
    get removedMatches() {
      return this.primary.removed.filter(item => this.filter(item.value));
    }
    get addedMatches() {
      return this.primary.added.filter(item => this.filter(item.value));
    }
    async _slice(from, to) {
      const addedRecordsOffset = this.pagination.objectCount - this.removedMatches.length;
      let records = [];
      if (from < addedRecordsOffset) {
        const dbRecords = await this._fetchRecords(from, to - from);
        records = records.concat(dbRecords);
      }
      if (to > addedRecordsOffset) {
        const start = Math.max(from - addedRecordsOffset, 0);
        const added = this.addedMatches.slice(start, to - addedRecordsOffset);
        records = records.concat(added);
      }
      return records;
    }
    async _fetchRecords(offset, count) {
      const {
        pageSize
      } = this.pagination;
      const startPage = Math.floor(offset / pageSize) + 1;
      let content = [];
      for (let pageNumber = startPage; pageNumber <= this.pagination.pageCount && content.length < count + offset; pageNumber++) {
        const page = await this._fetchPage(pageNumber);
        content = content.concat(page.filter(record => !this.primary.removedIDs.has(record.value.id)));
      }
      return content.slice(offset % pageSize, offset % pageSize + count);
    }

    // Not caching pages here
    async _fetchPage(number) {
      const page = await this.pagination.loadPage(number);
      return page.map(record => this.primary._getItem(record));
    }
  }, _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "objects", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "pageNumber", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "isLoading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "objectCount", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "objectCount"), _class2.prototype), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "pageSize", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "removedMatches", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "removedMatches"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addedMatches", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "addedMatches"), _class2.prototype), _class2);
  let EditablePagination = (_dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember.computed('pagination.objectCount', 'added.[]', 'removed.[]'), _dec18 = Ember.computed.reads('pagination.pageSize'), _dec19 = Ember._tracked, _dec20 = Ember.computed('isLoading', 'filteredPagination'), _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _class3 = class EditablePagination extends _pagination.BasePagination {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "added", []);
      _initializerDefineProperty(this, "removed", _descriptor7, this);
      _defineProperty(this, "removedIDs", new Set());
      _defineProperty(this, "items", []);
      _defineProperty(this, "itemsByID", new Map());
      _defineProperty(this, "dbPages", new Map());
      _defineProperty(this, "nextKey", 1);
      _initializerDefineProperty(this, "pageNumber", _descriptor8, this);
      _initializerDefineProperty(this, "objects", _descriptor9, this);
      _initializerDefineProperty(this, "isLoading", _descriptor10, this);
      _initializerDefineProperty(this, "filteredPagination", _descriptor11, this);
      _initializerDefineProperty(this, "pageSize", _descriptor12, this);
      _initializerDefineProperty(this, "dirtyItems", _descriptor13, this);
      // computed(changesets.@each.isValid) doesn't work, of course
      _initializerDefineProperty(this, "isValid", _descriptor14, this);
      _initializerDefineProperty(this, "showingDirtyItems", _descriptor15, this);
      _initializerDefineProperty(this, "showingFilteredItems", _descriptor16, this);
    }
    init(...args) {
      super.init(...args);
      this._makeChangeset = (0, _changeset.validatedChangeset)(this.validations, this.compoundValidations);
      if (!this.saveChangeset) {
        this.saveChangeset = cs => cs.save();
      }
    }
    get view() {
      return this.filteredPagination ? this.filteredPagination : this;
    }
    get objectCount() {
      return this.pagination.objectCount + this.added.length - this.removed.length;
    }
    async switchPage(number, params) {
      this.isLoading = true;
      this.objects = await this._slice((number - 1) * this.pageSize, number * this.pageSize);
      this.pageNumber = number;
      this.isLoading = false;
    }
    get canAdd() {
      return !this.isLoading && !this.filteredPagination;
    }
    async add(record) {
      const item = this._preprocess(record);
      this.added.pushObject(item);
      await this.switchPage(this.pageCount);
      this._revalidate();
    }
    remove(item) {
      this.added.removeObject(item);
      this.items.removeObject(item);
      if (item.value.id) {
        this.removedIDs.add(item.value.id);
        this.itemsByID.delete(item.value.id);
        this.removed.pushObject(item);
      }
      this.switchPage(Math.min(this.pageNumber, this.pageCount));
      this._revalidate();
      if (this.filteredPagination) {
        this.filteredPagination.switchPage(this.filteredPagination.pageNumber);
      }
    }
    async save() {
      await Ember.RSVP.all(this.removed.map(item => item.value.destroyRecord().catch(error => {
        item.value.rollbackAttributes();
        throw error;
      })));
      this.removed = [];
      await Ember.RSVP.all(this.items.filter(item => item.value.isDirty).map(item => this.saveChangeset(item.value)));
    }
    clearView() {
      this.filteredPagination = null;
      this.showingDirtyItems = false;
      this.showingFilteredItems = false;
    }
    switchToFilteredItems(pagination, filter) {
      const view = FilteredViewPagination.create({
        primary: this,
        pagination,
        filter
      });
      view.switchPage(1);
      this.filteredPagination = view;
      this.showingDirtyItems = false;
      this.showingFilteredItems = true;
    }
    switchToDirtyItems() {
      this.filteredPagination = DirtyItemsPagination.create({
        primary: this
      });
      this.showingDirtyItems = true;
      this.showingFilteredItems = false;
    }

    // Completely instantiate every item in the underlying pagination. On your own risk
    //
    // Feels weird, might be possible restructure stuff so that it's not necessary
    async loadEverything() {
      await this._fetchRecords(0, this.pagination.objectCount);
      this._revalidate();
    }
    async _fetchRecords(offset, count) {
      const {
        pageSize
      } = this.pagination;
      const startPage = Math.floor(offset / pageSize) + 1;
      let content = [];
      for (let pageNumber = startPage; pageNumber <= this.pagination.pageCount && content.length < count + offset; pageNumber++) {
        const page = await this._fetchPage(pageNumber);
        content = content.concat(page.filter(record => !this.removedIDs.has(record.value.id)));
      }
      return content.slice(offset % pageSize, offset % pageSize + count);
    }
    async _slice(from, to) {
      const addedRecordsOffset = this.pagination.objectCount - this.removedIDs.size;
      let records = [];
      if (from < addedRecordsOffset) {
        const dbRecords = await this._fetchRecords(from, to - from);
        records = records.concat(dbRecords);
      }
      if (to > addedRecordsOffset) {
        const start = Math.max(from - addedRecordsOffset, 0);
        const added = this.added.slice(start, to - addedRecordsOffset);
        records = records.concat(added);
      }
      return records;
    }
    async _fetchPage(number) {
      if (!this.dbPages.has(number)) {
        // loadPage is not exactly part of the top-level pagination protocol,
        // but it's better than juggling the adaptet paginations' state
        const page = await this.pagination.loadPage(number);
        this.dbPages.set(number, page.map(record => this._getItem(record)));
      }
      return this.dbPages.get(number);
    }
    _revalidate() {
      this.isValid = this.items.every(item => item.value.workingIsValid);
      this.dirtyItems = this.items.filter(item => item.value.isDirty || item.value.isNew);
    }
    _getItem(record) {
      if (record.isNew) {
        return this._preprocess(record);
      }
      if (!this.itemsByID.has(record.id)) {
        const item = this._preprocess(record);
        this.itemsByID.set(record.id, item);
      }
      return this.itemsByID.get(record.id);
    }
    _preprocess(record) {
      const value = this._makeChangeset(record, {
        validate: record.isNew
      });

      // TODO: Clean those up when model is destroyed
      value.on('afterValidation', () => this._revalidate());
      const item = {
        key: this.nextKey++,
        value
      };
      this.items.push(item);
      return item;
    }
  }, _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "removed", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "pageNumber", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "objects", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "isLoading", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "filteredPagination", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "objectCount", [_dec17], Object.getOwnPropertyDescriptor(_class3.prototype, "objectCount"), _class3.prototype), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "pageSize", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class3.prototype, "dirtyItems", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "canAdd", [_dec20], Object.getOwnPropertyDescriptor(_class3.prototype, "canAdd"), _class3.prototype), _descriptor14 = _applyDecoratedDescriptor(_class3.prototype, "isValid", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class3.prototype, "showingDirtyItems", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class3.prototype, "showingFilteredItems", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class3);
  function editablePagination({
    pagination,
    saveChangeset,
    validations,
    compoundValidations = []
  }) {
    return EditablePagination.create({
      pagination,
      saveChangeset,
      validations,
      compoundValidations
    });
  }
  let EditablePaginatedTableModel = _exports.EditablePaginatedTableModel = (_dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember.computed.reads('pagination.isValid'), _class4 = class EditablePaginatedTableModel {
    constructor({
      pagination
    }) {
      _initializerDefineProperty(this, "isValid", _descriptor17, this);
      this.pagination = pagination;
    }
    add() {
      const record = this._create();
      this.pagination.add(record);
    }
    remove(item) {
      this.pagination.remove(item);
    }
    async save() {
      await this.pagination.save();
    }
  }, _applyDecoratedDescriptor(_class4.prototype, "add", [_dec24], Object.getOwnPropertyDescriptor(_class4.prototype, "add"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "remove", [_dec25], Object.getOwnPropertyDescriptor(_class4.prototype, "remove"), _class4.prototype), _descriptor17 = _applyDecoratedDescriptor(_class4.prototype, "isValid", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class4);
});
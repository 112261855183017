define("plutof/controllers/login", ["exports", "ember-concurrency", "ember-cp-validations", "plutof/config/environment", "plutof/utils/identity-providers"], function (_exports, _emberConcurrency, _emberCpValidations, _environment, _identityProviders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'username': (0, _emberCpValidations.validator)('presence', true),
    'password': (0, _emberCpValidations.validator)('presence', true)
  });
  let LoginController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _class = class LoginController extends Ember.Controller.extend(Validations) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "maintenanceNotice", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "settings", _descriptor3, this);
      _defineProperty(this, "queryParams", ['afterRegistration']);
      _defineProperty(this, "afterRegistration", false);
      _defineProperty(this, "loginFailed", false);
      _defineProperty(this, "enableSocialAuth", _environment.default.ENABLE_SOCIAL_AUTH);
      _defineProperty(this, "enabledIdentityProviders", _identityProviders.ENABLED_PROVIDERS);
      _initializerDefineProperty(this, "authenticate", _descriptor4, this);
    }
    login(submitEvent) {
      submitEvent.preventDefault();
      this.authenticate.perform();
    }
    socialLogin(provider) {
      return (0, _identityProviders.login)(provider);
    }
    register() {
      this.transitionToRoute('step-one-register');
    }
    resetPassword() {
      this.transitionToRoute('recover-password');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "maintenanceNotice", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "login", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "authenticate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const authenticator = 'authenticator:plutof';
        const session = this.session;
        const username = this.username.trim();
        const password = this.password.trim();
        yield session.authenticate(authenticator, username, password).catch(error => {
          if (error instanceof TypeError || error.status !== 400) {
            this.maintenanceNotice.show(this.i18n.t('errorPage.maintenanceInProgress'));
          }
          this.set('loginFailed', true);
          Ember.run.later(function () {
            this.set('loginFailed', false);
          }.bind(this), 4000);
        });
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "socialLogin", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "socialLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "register", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetPassword", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "resetPassword"), _class.prototype), _class);
  var _default = _exports.default = LoginController;
});
define("plutof/components/async/bind-relation", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _BindRelation;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#resolve-promise @value as |value|}}
      {{yield value this.update}}
  {{/resolve-promise}}
  
  */
  {
    "id": "6dhDKpnY",
    "block": "{\"symbols\":[\"value\",\"&default\",\"@value\"],\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1],[32,0,[\"update\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"resolve-promise\"]}",
    "meta": {
      "moduleName": "plutof/components/async/bind-relation.hbs"
    }
  });
  // Decouples components like AutoComplete::Model from having to
  // deal with async model relations
  //
  // NB: This must be @ember/component because of how it binds
  // the @value arg
  let BindRelation = (_dec = (0, _component.tagName)(''), _dec2 = Ember._action, _dec(_class = (_class2 = (_BindRelation = class BindRelation extends Ember.Component {
    update(value) {
      this.set('value', value);

      // TODO: Consider if we should also support oneWay for this. It's a
      // bit weird, but makes sense in a way, allowing optional-bind ac's
      if (this.changed) {
        this.changed(value);
      }
    }
  }, _defineProperty(_BindRelation, "positionalParams", ['value']), _BindRelation), _applyDecoratedDescriptor(_class2.prototype, "update", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "update"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BindRelation);
});
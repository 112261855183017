define("plutof/components/clipboard/input-wrapper", ["exports", "@ember-decorators/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const REMOVE_PREFIX = 'remove_';
  const REMOVE_ALL_PREFIX = 'remove_all_';
  let InputWrapper = (_dec = Ember.computed('isAddMode', 'isRemoveMode', 'isLoading'), _dec2 = Ember.computed('config.type'), _dec3 = (0, _object.observes)('disabled', 'isDeleteMode'), _dec4 = (0, _object.observes)('value', 'disabled', 'isDeleteMode'), _dec5 = (0, _object.observes)('isDeleteMode'), _dec6 = (0, _object.observes)('config.doClearValue'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _class = class InputWrapper extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "value", null);
      _defineProperty(this, "isAddMode", false);
      _defineProperty(this, "isRemoveMode", false);
      _defineProperty(this, "isDeleteMode", false);
      _defineProperty(this, "label", null);
    }
    // String argument.

    // Clear data when the input is removed.
    willDestroyElement(...args) {
      super.willDestroyElement(...args);
      const config = this.config;
      const key = this.get('config.key');
      if (Ember.isNone(config)) {
        return;
      } else if (this.isSimpleEdit) {
        Ember.set(config.data, key, null);
        Ember.set(config, 'isActive', false);
      } else {
        Ember.set(config.data, key, null);
        Ember.set(config.data, REMOVE_PREFIX + key, null);
        Ember.set(config, 'isActive', false);
      }
    }
    get disabled() {
      return this.isLoading || !this.isAddMode && !this.isRemoveMode;
    }
    get isSimpleEdit() {
      return this.get('config.type') !== 'hasMany';
    }
    switchActiveStatus() {
      const config = this.config;
      if (!Ember.isNone(config)) {
        Ember.set(config, 'isActive', !this.disabled || this.isDeleteMode);
      }
    }

    // Data property value, not this.value.
    valueSetter() {
      const data = this.get('config.data');
      const key = this.get('config.key');
      const removePropertyName = REMOVE_PREFIX + key; // this.get('config.removeKey');
      const activePropertyName = this.isRemoveMode ? removePropertyName : key;
      const value = Ember.isNone(this.value) ? this.get('config.defaultValue') : this.value;

      // Set unused property value to null. Must we?
      if (this.isRemoveMode) {
        Ember.set(data, key, null);
      } else if (!this.isSimpleEdit) {
        Ember.set(data, removePropertyName, null);
      }

      // Update active property' value.
      if (this.disabled) {
        Ember.set(data, activePropertyName, this.get('config.nullValue'));
      } else {
        Ember.set(data, activePropertyName, value);
      }
    } // Switching delete mode also triggers value computation.

    // List and unlist properties depending on isDeleteMode.
    updateFieldsToRemove() {
      const key = this.get('config.key');
      const isDeleteMode = this.isDeleteMode;
      const activePropertyName = this.isRemoveMode ? REMOVE_PREFIX + key : key;
      const fieldsToRemove = this.get('config.fieldsToRemove');
      const isSimpleEdit = this.isSimpleEdit;

      // We don't list 'hasMany' type of fields in remove list. They are removed by another property.
      if (isDeleteMode && isSimpleEdit && !fieldsToRemove.includes(activePropertyName)) {
        fieldsToRemove.pushObject(activePropertyName);
      } else if (!isDeleteMode && fieldsToRemove.includes(activePropertyName)) {
        fieldsToRemove.removeObject(activePropertyName);
      }
      if (isDeleteMode && !isSimpleEdit) {
        this.set('config.data.' + REMOVE_ALL_PREFIX + key, true);
      } else {
        // Properties with null values won't be sent to the back-end.
        this.set('config.data.' + REMOVE_ALL_PREFIX + key, null);
      }
    }

    /**
     * XXX XXX XXX This will have to do till I come up wiht a better solution
     * for triggering inputs value reset from outside the component.
     */
    doClearValue() {
      if (this.get('config.doClearValue')) {
        this.set('value', this.getEmptyValue());
        this.set('config.doClearValue', false); // XXX Clench your buttocks!
      }
    }
    getEmptyValue() {
      return this.isSimpleEdit ? this.get('config.nullValue') : [];
    }
    resetInput() {
      this.setProperties({
        isAddMode: false,
        isRemoveMode: false,
        isDeleteMode: false,
        value: this.getEmptyValue()
      });
    }
    switchInputMode(modeProperty) {
      const state = this.get(modeProperty);
      this.resetInput();
      this.set(modeProperty, !state);
    }
    switchToAddMode() {
      this.switchInputMode('isAddMode');
    }
    switchToRemoveMode() {
      this.switchInputMode('isRemoveMode');
    }
    switchToDeleteMode() {
      this.switchInputMode('isDeleteMode');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSimpleEdit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isSimpleEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchActiveStatus", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "switchActiveStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "valueSetter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "valueSetter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFieldsToRemove", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateFieldsToRemove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doClearValue", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "doClearValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchToAddMode", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "switchToAddMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchToRemoveMode", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "switchToRemoveMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchToDeleteMode", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "switchToDeleteMode"), _class.prototype), _class);
  var _default = _exports.default = InputWrapper;
});
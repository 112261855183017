define("plutof/controllers/import/index", ["exports", "plutof/controllers/import/process", "plutof/misc/config", "plutof/utils/reflection"], function (_exports, _process, _config, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ImportListController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('model.pagination.objects.@each.{async,status}'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _class = class ImportListController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _defineProperty(this, "polling", false);
    }
    get needsAutoupdate() {
      const processes = this.model.pagination.objects;

      // XXX: For when the first page is not loaded yet
      if (!processes) {
        return true;
      }
      return processes.find(process => {
        return process.async && ['waiting', 'processing'].includes(process.status);
      });
    }
    autoupdate() {
      if (this.polling) {
        if (this.needsAutoupdate) {
          this.model.pagination.reload();
          Ember.run.later(this, 'autoupdate', _config.default.Import.POLL_INTERVAL);
        } else {
          this.stopAutoupdate();
        }
      }
    }
    startAutoupdate() {
      if (!this.polling) {
        this.set('polling', true);
        this.autoupdate();
      }
    }
    stopAutoupdate() {
      this.set('polling', false);
    }
    stopProcess(process) {
      const processURL = (0, _reflection.get_record_url)(process);
      return this.ajax.post(`${processURL}stop/`);
    }
    stop(process) {
      return this.stopProcess(process).then(() => this.model.pagination.reload());
    }
    async remove(process) {
      this.stopAutoupdate();
      await this.stopProcess(process);
      const source = await process.source;
      try {
        await process.destroyCompletely();
        await source.destroyCompletely();
      } finally {
        this.model.pagination.reload();
        this.startAutoupdate();
      }
    }
    stopAndDownload(process) {
      this.stopAutoupdate();
      return (0, _process.stopAndDownloadImport)(this.ajax, process).then(() => this.startAutoupdate());
    }
    add() {
      this.router.transitionTo('import.add');
    }
    generateTemplate() {
      this.router.transitionTo('import.generate-template');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "needsAutoupdate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "needsAutoupdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stop", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "stop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopAndDownload", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "stopAndDownload"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateTemplate", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "generateTemplate"), _class.prototype), _class);
  var _default = _exports.default = ImportListController;
});
define("plutof/components/observation/taxon-sheet/dialog", ["exports", "@ember-decorators/component", "plutof/utils/notifications"], function (_exports, _component, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TaxonSheetDialog = (_dec = (0, _component.classNames)('taxon-sheet-dialog'), _dec2 = Ember.computed.gt('sheetset.sheets.length', 1), _dec3 = Ember.computed('selectedSheetItems', 'selectedSheet.items.[]'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = class TaxonSheetDialog extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "selectedSheet", null);
      _defineProperty(this, "selectedSheetItems", []);
      _initializerDefineProperty(this, "hasMultipleSheets", _descriptor, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set('selectedSheet', this.sheetset.sheets[0]);
    }
    get entries() {
      let isSelected = {};
      this.selectedSheetItems.forEach(item => isSelected[item.id] = true);
      return this.selectedSheet.items.map(item => ({
        item,
        selected: isSelected[item.id]
      }));
    }
    add() {
      const data = this.data;
      return Ember.RSVP.all(this.selectedSheetItems.map(item => {
        return this.store.findRecord('taxonomy/taxonnode', item.id).then(taxon => {
          const observation = this.data.createOccurrence();
          const entry = this.data.createEntry(observation, this.data._createInitialMeasurements(observation));

          // Workaround for double re-render related to taxonValue. Don't know how this
          // works, but it does (probably avoids triggering rerender via autocomplete)
          entry.set('taxonValue', taxon.representation);
          entry.set('taxon', taxon);
          entry.set('sheetItem', item);
          return entry;
        }, () => {
          (0, _notifications.reportError)('Couldn\'t resolve taxon', item.representation);
        });
      })).then(entries => {
        this.set('selectedSheetItems', []);
        data.removeEmpty();
        entries.compact().forEach(entry => data.addEntry(entry));
        this.close();
      });
    }
    close() {
      this.set('show', false);
    }
    toggle(entry) {
      Ember.set(entry, 'selected', !entry.selected);
      if (entry.selected) {
        this.selectedSheetItems.pushObject(entry.item);
      } else {
        this.selectedSheetItems.removeObject(entry.item);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "hasMultipleSheets", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "entries", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "entries"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "add", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "add"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "close", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggle", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "toggle"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = TaxonSheetDialog;
});
define("plutof/components/taxonomy/tree-root", ["exports", "ember-data", "@ember-decorators/object", "ember-concurrency", "plutof/misc/abstract", "plutof/misc/config", "plutof/utils/push-to-store"], function (_exports, _emberData, _object, _emberConcurrency, _abstract, _config, _pushToStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
      {{#each this.children as |child|}}
          {{#let
              (component "taxonomy/tree-node"
                  expandedNodes=this.expandedNodes
                  selectedTaxon=@selectedTaxon
                  selectedTaxonPath=this.selectedTaxonPath
                  editable=@editable
                  selectTaxon=@selectTaxon)
              as |TreeNode|
          }}
              {{! No letrec to pass @treeNode, unfortunately }}
              <TreeNode
                  @rootNode={{child}}
                  @treeNode={{TreeNode}} />
          {{/let}}
      {{/each}}
  </div>
  
  <div>
      {{#if this._loadPage.isRunning}}
          <span class="loading-icon"></span>
          {{i18n-t "General.loading"}}...
      {{else if this.moreChildrenAvailable}}
          <button
              {{on "click" this.loadMoreChildren}}
              class="btn-fake-link"
          >
              <span class="fas fa-chevron-down"></span>
              {{i18n-t "General.showMore"}}
          </button>
      {{/if}}
  </div>
  
  {{#if @editable}}
      {{#link-to "taxonomy.add" @tree.id}}
          <p class="button-marker__taxon-node-add-button taxon-root-add">
              {{i18n-t "General.Add"}}
          </p>
      {{/link-to}}
  {{/if}}
  
  */
  {
    "id": "Qa3fgCGz",
    "block": "{\"symbols\":[\"child\",\"TreeNode\",\"@tree\",\"@selectTaxon\",\"@editable\",\"@selectedTaxon\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,0,[\"children\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[\"taxonomy/tree-node\"],[[\"expandedNodes\",\"selectedTaxon\",\"selectedTaxonPath\",\"editable\",\"selectTaxon\"],[[32,0,[\"expandedNodes\"]],[32,6],[32,0,[\"selectedTaxonPath\"]],[32,5],[32,4]]]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[],[[\"@rootNode\",\"@treeNode\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"_loadPage\",\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"loading-icon\"],[12],[13],[2,\"\\n        \"],[1,[30,[36,0],[\"General.loading\"],null]],[2,\"...\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[32,0,[\"moreChildrenAvailable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,2],[\"click\",[32,0,[\"loadMoreChildren\"]]],null],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"fas fa-chevron-down\"],[12],[13],[2,\"\\n            \"],[1,[30,[36,0],[\"General.showMore\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,5]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"route\",\"model\"],[\"taxonomy.add\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"button-marker__taxon-node-add-button taxon-root-add\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"General.Add\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\",\"on\",\"if\",\"component\",\"let\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/taxonomy/tree-root.hbs"
    }
  });
  let TreeRoot = (_dec = Ember.inject.service, _dec2 = Ember.computed('selectedTaxonPath.[]'), _dec3 = Ember.computed('selectedTaxon.valid_name', 'selectedTaxon.lineage'), _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec5 = (0, _object.observes)('expandedNodes.[]'), _dec6 = Ember._action, _class = class TreeRoot extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "lastTree", null);
      _initializerDefineProperty(this, "_loadPage", _descriptor2, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.lastTree === this.tree) {
        this.searchForPathNodes();
      } else {
        this.setProperties({
          lastTree: this.tree,
          children: [],
          pageNumber: 1,
          moreChildrenAvailable: false
        });
        this.loadPage(1);
      }
    }
    get expandedNodes() {
      return this.selectedTaxonPath.mapBy('id');
    }
    get selectedTaxonPath() {
      if (!this.selectedTaxon) {
        return [];
      }
      const pathPromise = this.selectedTaxon.get('valid_name').then(valid_name => {
        if (Ember.isNone(valid_name)) {
          return this.selectedTaxon.get('lineage');
        } else {
          return valid_name.get('lineage').then(lineage => lineage.concat(this.selectedTaxon));
        }
      });
      return _emberData.default.PromiseArray.create({
        promise: pathPromise
      });
    }
    loadPage(number) {
      return this._loadPage.perform(number);
    }
    loadNextPage() {
      this.incrementProperty('pageNumber');
      return this.loadPage(this.pageNumber);
    }
    searchForPathNodes() {
      if (this._loadPage.isRunning) {
        return;
      }
      const path = this.expandedNodes;
      if (path.get('length') === 0) {
        return;
      }
      const load = () => {
        if (this.moreChildrenAvailable && this.children.every(child => !path.includes(child.get('id')))) {
          this.loadNextPage().then(load);
        }
      };
      load();
    }
    loadMoreChildren() {
      this.loadNextPage();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "expandedNodes", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "expandedNodes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedTaxonPath", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectedTaxonPath"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_loadPage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (pageNumber) {
        const treeID = this.get('tree.id');
        if (!treeID) {
          return;
        }
        const url = (0, _abstract.construct_request)(`/taxonomy/trees/${treeID}/root_children/`, {
          page_size: _config.default.Taxonomy.TOPLEVEL_PAGE_SIZE,
          page: pageNumber
        });
        try {
          const childrenData = yield this.ajax.request(url);
          const page = (0, _pushToStore.default)(this.store, 'taxonomy/taxonnode', childrenData);
          this.children.pushObjects(page);
          // /root_children/ endpoint doesn't have /count method, so this will do
          this.set('moreChildrenAvailable', page.length === _config.default.Taxonomy.TOPLEVEL_PAGE_SIZE);
          this.searchForPathNodes();
        } catch (err) {
          this.set('moreChildrenAvailable', false);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchForPathNodes", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "searchForPathNodes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMoreChildren", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadMoreChildren"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TreeRoot);
});
define("plutof/components/map/multi-popup", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.current}}
  
  {{on-change this.reset @items}}
  
  {{#if this.showPagination}}
      <Layout::SplitRow>
          <:left>
              {{#if this.hasPrevious}}
                  <button
                      {{on "click" this.previous}}
                      class="btn-fake-link"
                  >
                      <span class="fas fa-chevron-left"></span>
                  </button>
              {{/if}}
          </:left>
  
          <:center>
              {{this.pageNumber}} / {{@items.length}}
          </:center>
  
          <:right>
              {{#if this.hasNext}}
                  <button
                      {{on "click" this.next}}
                      class="btn-fake-link"
                  >
                      <span class="fas fa-chevron-right"></span>
                  </button>
              {{/if}}
          </:right>
      </Layout::SplitRow>
  {{/if}}
  
  */
  {
    "id": "7w+K7UMz",
    "block": "{\"symbols\":[\"__arg0\",\"@items\",\"&default\"],\"statements\":[[18,3,[[32,0,[\"current\"]]]],[2,\"\\n\\n\"],[1,[30,[36,4],[[32,0,[\"reset\"]],[32,2]],null]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"showPagination\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"layout/split-row\",[],[[\"@namedBlocksInfo\"],[[30,[36,3],null,[[\"left\",\"center\",\"right\"],[0,0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,2],[[32,1],\"left\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"hasPrevious\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,0],[\"click\",[32,0,[\"previous\"]]],null],[12],[2,\"\\n                    \"],[10,\"span\"],[14,0,\"fas fa-chevron-left\"],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,2],[[32,1],\"center\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,0,[\"pageNumber\"]]],[2,\" / \"],[1,[32,2,[\"length\"]]],[2,\"\\n        \"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,2],[[32,1],\"right\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"hasNext\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,0],[\"click\",[32,0,[\"next\"]]],null],[12],[2,\"\\n                    \"],[10,\"span\"],[14,0,\"fas fa-chevron-right\"],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"if\",\"-is-named-block-invocation\",\"hash\",\"on-change\"]}",
    "meta": {
      "moduleName": "plutof/components/map/multi-popup.hbs"
    }
  });
  let MapMultiPopup = _exports.default = (_dec = Ember._tracked, _dec2 = Ember.computed('effectiveIndex', 'args.items.[]'), _dec3 = Ember.computed('currentIndex', 'args.items.[]'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _class = class MapMultiPopup extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentIndex", _descriptor, this);
    }
    get current() {
      if (!this.args.items) {
        return null;
      }
      return this.args.items[this.effectiveIndex];
    }

    // This is ridiculous, but ember doesn't let me do this properly. If we
    // have items = [a, b], set currentIndex to 1 to show b, then switch to
    // a point with items = [c], currentIndex needs to be reset to 0.
    //
    // However, reacting to component argument changes is unheard of in
    // the "framework for perfectionists with deadlines". Their solution is
    // something like {{on-change}}. Which is a moronic hack. Nevertheless,
    // even if used here, it doesn't work! Something in the rendering order
    // means that @items[this.currentIndex] can become null, even if currentIndex
    // is reset to zero.
    get effectiveIndex() {
      return Math.max(0, Math.min(this.currentIndex, this.args.items.length - 1));
    }
    get showPagination() {
      return this.args.items.length > 1;
    }
    get pageNumber() {
      return this.currentIndex + 1;
    }
    get hasPrevious() {
      return this.currentIndex > 0;
    }
    get hasNext() {
      return this.currentIndex < this.args.items.length - 1;
    }
    previous() {
      this.currentIndex = Math.max(0, this.effectiveIndex - 1);
    }
    next() {
      this.currentIndex = Math.min(this.args.items.length - 1, this.effectiveIndex + 1);
    }
    reset() {
      this.currentIndex = 0;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentIndex", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "current", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "current"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "effectiveIndex", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "effectiveIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previous", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "previous"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "next", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MapMultiPopup);
});
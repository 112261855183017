define("plutof/components/observation/moderation-reject-popup", ["exports", "plutof/components/observation/moderation-comments"], function (_exports, _moderationComments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ModerationRejectPopup = (_dec = Ember._action, _dec2 = Ember._action, _class = class ModerationRejectPopup extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "quickReplyOptions", _moderationComments.QUICK_REPLY_OPTIONS);
    }
    cancelReject() {
      this.cancelObservation();
      this.set('reasonOfDenial', '');
    }
    reject() {
      return this.rejectObservation(this.get('observation.id'), this.reasonOfDenial).then(() => {
        this.set('reasonOfDenial', '');
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "cancelReject", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "cancelReject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reject", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "reject"), _class.prototype), _class);
  var _default = _exports.default = ModerationRejectPopup;
});
define("plutof/components/compound-table", ["exports", "plutof/misc/abstract", "plutof/utils/notifications"], function (_exports, _abstract, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CompoundTable = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('clusterSequences.[]', 'totalCount'), _dec4 = Ember.computed.map('clusterSequences', function (seq) {
    const publicIdentifiers = seq.identifiers.rejectBy('database', 'Private');
    Ember.set(seq, 'compoundName', publicIdentifiers.mapBy('id_in_database').join(' | '));
    const colors = this.thresholdOrdering.map(threshold => {
      // NB: dshcolourX is "color of threshold with *ID* X", because API design is easy
      const color = seq[`dshcolour${threshold.id}`];
      const referenceSequence = seq.refseqs.findBy('threshold', threshold.threshold);
      const style = Ember.String.htmlSafe(referenceSequence ? `background: ${this.referenceGradient(color)}` : `background-color: #${color}`);
      return {
        style,
        referenceSequence,
        dshCluster: seq.dshclusters.findBy('threshold', threshold.threshold),
        color
      };
    });
    Ember.set(seq, 'colors', colors);
    return seq;
  }), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember.computed('clusterSequencesWrapper.[]'), _dec8 = Ember._action, _dec9 = Ember._action, _class = class CompoundTable extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _defineProperty(this, "thresholds", ['<.5', '0.5', '1.0', '1.5', '2.0', '2.5', '3.0']);
      // XXX: This is not a map
      _initializerDefineProperty(this, "clusterSequencesWrapper", _descriptor3, this);
    }
    init() {
      super.init(...arguments);
      this.set('page_nr', null);
      this.set('loading', false);
    }
    referenceGradient(color) {
      return `repeating-linear-gradient(45deg, #${color}, 4px, white 5px);`;
    }
    get haveLoadMore() {
      const totalCount = this.get('totalCount.objects_count');
      const clusterCount = this.get('clusterSequences.length');
      return totalCount !== clusterCount;
    }
    fetchAnotherPage() {
      const self = this;
      const clusterId = self.get('clusterID');
      var page = self.get('page_nr');
      if (Ember.isNone(page)) {
        page = 2;
      } else {
        page++;
      }
      const clusterURL = (0, _abstract.construct_request)('/globalkey/clustersequencestabels/', {
        cluster: clusterId,
        page: page,
        page_size: 100,
        ordering: 'cheat_combined_order'
      });
      self.set('page_nr', page);
      self.set('loading', true);
      return self.get('ajax').request(clusterURL).then(function (clusters) {
        self.set('loading', false);
        self.get('clusterSequences').pushObjects(clusters);
      }, _notifications.reportError);
    }
    loadMore() {
      this.fetchAnotherPage();
    }
    loadAll() {
      // initial page already loaded
      const pages = this.get('totalCount.objects_count') / 100 - 1;
      const self = this;
      function loadPageByPage(counter) {
        if (counter > 0 && self.get('haveLoadMore')) {
          self.fetchAnotherPage().then(function () {
            return loadPageByPage(counter - 1);
          });
        }
      }
      loadPageByPage(pages);
    }
    get alignmentSequences() {
      return this.clusterSequencesWrapper.map(sequence => {
        return {
          id: sequence.sequence_id,
          compoundID: sequence.compoundName,
          uniteTaxon: sequence.taxon_node,
          insdTaxon: sequence.insd_taxon_node,
          source: sequence.seq_source,
          interactingTaxa: sequence.it_taxon_nodes.map(it => it.name).join(', '),
          area: sequence.country,
          sequence: sequence.aligned_seq_full,
          clusterLinks: sequence.colors.map(color => ({
            id: 'none',
            color: color.color ? `#${color.color}` : '#fff',
            url: color.dshCluster && color.dshCluster.dshcluster_id ? this.router.urlFor('unite.view', color.dshCluster.dshcluster_id) : null
          })).slice(1)
        };
      });
    }

    // TODO: DRY with UniteTable
    buildAlignmentConfig(fields) {
      return {
        thresholds: ['0.5', '1.0', '1.5', '2.0', '2.5', '3.0'],
        stickyColumns: 1,
        fields: [fields.link(this.i18n.t('clusters.accession'), seq => ({
          name: seq.compoundID,
          url: this.router.urlFor('sequence.view', seq.id)
        }), {
          openInNewTab: true,
          shortenTo: 20
        }), fields.link(this.i18n.t('clusters.uniteTaxon'), seq => {
          return seq.uniteTaxon && seq.uniteTaxon.id ? {
            name: seq.uniteTaxon.name,
            url: this.router.urlFor('taxonomy.view', seq.uniteTaxon.id)
          } : {};
        }, {
          openInNewTab: true
        }), fields.text(this.i18n.t('clusters.insdTaxon'), seq => seq.insdTaxon || ''), fields.text(this.i18n.t('clusters.source'), seq => seq.source || ''), fields.text(this.i18n.t('clusters.interaction'), seq => seq.interactingTaxa), fields.text(this.i18n.t('clusters.area'), seq => seq.area)],
        nucleotides: {
          width: 12,
          palette: {
            'A': ['white', '#fb0018'],
            'G': ['black', '#fffd33'],
            'C': ['black', '#38fd2a'],
            'T': ['white', '#0022fb'],
            'default': ['black', '#dddddd']
          }
        },
        guideline: {
          step: 10
        }
      };
    }
    toggleOldTable() {
      this.toggleProperty('showOldTable');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "haveLoadMore", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "haveLoadMore"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clusterSequencesWrapper", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAll", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "alignmentSequences", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "alignmentSequences"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildAlignmentConfig", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "buildAlignmentConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleOldTable", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOldTable"), _class.prototype), _class);
  var _default = _exports.default = CompoundTable;
});
define("plutof/components/parent-object-path", ["exports", "ember-data", "@ember-decorators/component"], function (_exports, _emberData, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ParentObjectPath = (_dec = (0, _component.classNames)('breadcrumbs'), _dec2 = Ember.computed.equal('contentType.model', 'specimen'), _dec3 = Ember.computed.equal('contentType.model', 'strain'), _dec4 = Ember.computed.equal('contentType.model', 'materialsample'), _dec5 = Ember.computed.equal('contentType.model', 'study'), _dec6 = Ember.computed.alias('contentType.model_name'), _dec7 = Ember.computed('parentModelName', 'object_id'), _dec(_class = (_class2 = class ParentObjectPath extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isSpecimen", _descriptor, this);
      _initializerDefineProperty(this, "isStrain", _descriptor2, this);
      _initializerDefineProperty(this, "isMaterialSample", _descriptor3, this);
      _initializerDefineProperty(this, "isStudy", _descriptor4, this);
      _initializerDefineProperty(this, "parentModelName", _descriptor5, this);
    }
    get parentObject() {
      const model = this.parentModelName;
      const oid = this.object_id;
      if (Ember.isEmpty(model) || Ember.isEmpty(oid)) {
        return Ember.Object.create();
      }
      return _emberData.default.PromiseObject.create({
        promise: this.store.findRecord(model, oid)
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "isSpecimen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isStrain", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isMaterialSample", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isStudy", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "parentModelName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "parentObject", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "parentObject"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = ParentObjectPath;
});
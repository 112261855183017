define("plutof/components/tables/-list-view/related-objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@Field @label={{i18n-t "PlutofListViewColumn.relatedObjects"}}>
      <Sample::ListRelatedObjects
          @objects={{@record.related_objects}}
          @parentObject={{@record}}
          @openFile={{@open}} />
  </@Field>
  
  */
  {
    "id": "mP+DH7PP",
    "block": "{\"symbols\":[\"@Field\",\"@record\",\"@open\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"PlutofListViewColumn.relatedObjects\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"sample/list-related-objects\",[],[[\"@objects\",\"@parentObject\",\"@openFile\"],[[32,2,[\"related_objects\"]],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/tables/-list-view/related-objects.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});
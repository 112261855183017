define("plutof/components/layer/view/occurrence-layers", ["exports", "@glimmer/component", "ember-concurrency", "plutof/utils/reflection"], function (_exports, _component, _emberConcurrency, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createLayersModel = createLayersModel;
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "Layer.layers"}}>
      <div class="table-responsive">
          <table class="plutof-table table-hover">
              <thead>
                  <tr>
                      <th>{{i18n-t "Layer.layer"}}</th>
                      <th>{{i18n-t "Layer.layerArea"}}</th>
                  </tr>
              </thead>
  
              <tbody>
                  {{#each @layersData.layers as |layer|}}
                      <tr>
                          <td>
                              {{#link-to "layer.view" layer.layer_id}}
                                  {{layer.layer_name}}
                              {{/link-to}}
                          </td>
  
                          <td>
                              {{layer.area_name}}
                          </td>
                      </tr>
                  {{/each}}
              </tbody>
          </table>
      </div>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "9j9VdfWR",
    "block": "{\"symbols\":[\"layer\",\"@layersData\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,1],[\"Layer.layers\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"table-responsive\"],[12],[2,\"\\n        \"],[10,\"table\"],[14,0,\"plutof-table table-hover\"],[12],[2,\"\\n            \"],[10,\"thead\"],[12],[2,\"\\n                \"],[10,\"tr\"],[12],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"Layer.layer\"],null]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"Layer.layerArea\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2,[\"layers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"tr\"],[12],[2,\"\\n                        \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\",\"model\"],[\"layer.view\",[32,1,[\"layer_id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                                \"],[1,[32,1,[\"layer_name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                        \"],[13],[2,\"\\n\\n                        \"],[10,\"td\"],[12],[2,\"\\n                            \"],[1,[32,1,[\"area_name\"]]],[2,\"\\n                        \"],[13],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"i18n-t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/layer/view/occurrence-layers.hbs"
    }
  });
  let OccurrenceLayersModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class OccurrenceLayersModel {
    constructor(occurrence) {
      _initializerDefineProperty(this, "layers", _descriptor, this);
      _initializerDefineProperty(this, "geoms", _descriptor2, this);
      this.occurrence = occurrence;
    }
    *load() {
      const layers = yield fetch(`${this.occurrence.url}layers/`).then(response => response.json());
      this.layers = layers.sortBy('layer_name').map(layer => {
        return Object.assign({
          layer_id: (0, _reflection.recordURLToID)(layer.layer)
        }, layer);
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "layers", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "geoms", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "load", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype), _class);
  function createLayersModel(occurrence) {
    const model = new OccurrenceLayersModel(occurrence);
    model.load.perform();
    return model;
  }
  class ViewOccurrencelayers extends _component.default {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ViewOccurrencelayers);
});
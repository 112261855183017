define("plutof/components/edit-preparations", ["exports", "ember-data", "@ember-decorators/object", "ember-cp-validations", "ember-inflector", "plutof/misc/abstract", "plutof/misc/options-getter", "plutof/mixins/component-validations", "plutof/models/common/preparation", "plutof/utils/promises"], function (_exports, _emberData, _object, _emberCpValidations, _emberInflector, _abstract, _optionsGetter, _componentValidations, _preparation, _promises) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PreparationsData = void 0;
  _exports.createPreparationData = createPreparationData;
  _exports.default = void 0;
  _exports.loadPreparationData = loadPreparationData;
  var _dec, _class, _descriptor, _dec2, _dec3, _class2, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class3, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const EntryValidations = (0, _emberCpValidations.buildValidations)({
    'preparation.type': (0, _emberCpValidations.validator)('presence', true),
    'preparation.name': (0, _emberCpValidations.validator)('length', {
      max: 254
    }),
    'preparation.remarks': (0, _emberCpValidations.validator)('length', {
      max: 254
    }),
    'preparation.prepared_location': (0, _emberCpValidations.validator)('length', {
      max: 254
    }),
    'preparation.method_desc': (0, _emberCpValidations.validator)('length', {
      max: 254
    }),
    'preparation.count': (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }),
    'preparation.storage_location': (0, _emberCpValidations.validator)('length', {
      max: 254
    }),
    // XXX Not ideal.
    'dateValidity': (0, _emberCpValidations.validator)('is-truthy')
  });
  let PreparationEntry = (_dec = Ember.computed.alias('validations.isValid'), _class = class PreparationEntry extends Ember.Object.extend(EntryValidations) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isValid", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  let PreparationsData = _exports.PreparationsData = (_dec2 = (0, _object.observes)('entries.@each.isValid'), _dec3 = Ember.computed('preparations.[]'), _class2 = class PreparationsData extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "targetRecord", null);
    }
    init() {
      // Default values.
      this.set('recordsToDelete', []);
      this.set('agentsToDelete', []);
      super.init(...arguments);
      this.dataValidityUpdater();
    }
    dataValidityUpdater() {
      this.set('validity', this.entries.isEvery('isValid'));
    }
    get entries() {
      const ownerInjection = Ember.getOwner(this).ownerInjection();
      return this.preparations.map(preparation => PreparationEntry.create(ownerInjection, {
        preparation: preparation,
        dateValidity: true,
        fadeId: preparation.get('fadeId') || preparation.get('id')
      }));
    }
    save() {
      const preparations = this.preparations;
      const preparationsToSave = preparations.filterBy('hasDirtyAttributes');
      return Ember.RSVP.all(preparationsToSave.invoke('save')).then(() => {
        return Ember.RSVP.all(this.agentsToDelete.invoke('destroyRecord')).then(() => {
          this.agentsToDelete.clear();
          return Ember.RSVP.all(this.recordsToDelete.invoke('destroyRecord')).then(() => {
            this.recordsToDelete.clear();
            return Ember.RSVP.all(preparations.map(preparation => {
              const agentsToSavePromise = _promises.default.filter(preparation.get('agents'), agent => {
                if (!agent.get('hasDirtyAttributes')) {
                  return (0, _abstract.wrap_as_promise)(false);
                }
                return agent.get('person').then(person => !Ember.isNone(person));
              });
              return agentsToSavePromise.then(agents => _promises.default.saveSequentially(agents)).then(() => preparation);
            }));
          });
        });
      });
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "dataValidityUpdater", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "dataValidityUpdater"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "entries", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "entries"), _class2.prototype), _class2);
  function createPreparationData(store, targetRecord, requireOne = false) {
    var preparations = [];
    if (requireOne) {
      var model = targetRecord.modelType || targetRecord.constructor.modelName;
      var modelSet = (0, _preparation.getPreparationModelset)(model);
      var _params = {
        fadeId: Math.random()
      };
      _params[modelSet.objectField] = targetRecord;
      var prep = store.createRecord(modelSet.preparationModel, _params);
      prep.set('agents', []);
      preparations.pushObject(prep);
    }
    const ownerInjection = Ember.getOwner(store).ownerInjection();
    return PreparationsData.create(ownerInjection, {
      targetRecord: targetRecord,
      preparations: preparations
    });
  }
  function loadPreparationData(store, targetRecord) {
    // TODO: Extract to a function? Those are private fields, can change in future
    const model = targetRecord.constructor.modelName;
    const modelSet = (0, _preparation.getPreparationModelset)(model);
    let params = {};
    params[modelSet.objectField] = targetRecord.get('id');
    return store.query(modelSet.preparationModel, params).then(preparations => {
      return Ember.RSVP.all(preparations.map(preparation => {
        return store.query(modelSet.agentModel, {
          preparation: preparation.get('id')
        }).then(agents => {
          preparation.set('agents', agents.toArray());
        });
      })).then(() => {
        const ownerInjection = Ember.getOwner(store).ownerInjection();
        return PreparationsData.create(ownerInjection, {
          targetRecord: targetRecord,
          preparations: preparations.toArray()
        });
      });
    });
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'data.validity': (0, _emberCpValidations.validator)('is-truthy')
  });
  let PreparationsComponent = (_dec4 = Ember.inject.service, _dec5 = Ember.computed('model'), _dec6 = (0, _object.observes)('data.validity'), _dec7 = Ember.computed('modelSet'), _dec8 = (0, _object.observes)('targetRecord', 'modelSet'), _dec9 = Ember.computed('requireOne', 'data.preparations.[]'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _class3 = class PreparationsComponent extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _defineProperty(this, "data", null);
      _defineProperty(this, "validity", true);
      _defineProperty(this, "model", 'taxonoccurrence/specimen/specimen');
      // Requiring at least one preparation
      _defineProperty(this, "requireOne", false);
    }
    get modelSet() {
      return (0, _preparation.getPreparationModelset)(this.model);
    }
    init() {
      super.init(...arguments);
      this.validityUpdater();
      this.targetRecordObserver();
    }
    validityUpdater() {
      this.set('validity', this.get('data.validity'));
    }
    get preparationTypes() {
      var typesURL = '/' + (0, _emberInflector.pluralize)(this.modelSet.preparationModel) + '/';
      return _emberData.default.PromiseArray.create({
        promise: (0, _optionsGetter.getChoices)(this.ajax, typesURL, 'type', {
          preserveEmpty: true
        })
      });
    }
    targetRecordObserver() {
      var targetRecord = this.targetRecord;
      var store = this.store;
      if (Ember.isNone(targetRecord) || !targetRecord.get('isLoaded') || !Ember.isNone(this.data)) {
        return;
      }
      if (targetRecord.get('isNew')) {
        this.set('data', createPreparationData(store, targetRecord, this.requireOne));
      } else {
        loadPreparationData(store, targetRecord).then(function (data) {
          this.set('data', data);
        }.bind(this));
      }
    }
    get disableRemove() {
      return this.requireOne && this.get('data.preparations.length') === 1;
    }
    add() {
      var params = {
        fadeId: Math.random()
      };
      params[this.get('modelSet.objectField')] = this.targetRecord;
      params.agents = [];
      var newPreparation = this.store.createRecord(this.get('modelSet.preparationModel'), params);
      this.get('data.preparations').pushObject(newPreparation);
    }
    remove(preparation) {
      const removedAgents = preparation.get('agents').rejectBy('isNew');
      this.get('data.agentsToDelete').removeObjects(removedAgents);
      this.get('data.preparations').removeObject(preparation);
      if (!preparation.get('isNew')) {
        this.get('data.recordsToDelete').pushObject(preparation);
      }
    }
    addAgent(preparation, person) {
      if (Ember.isNone(person)) {
        return null;
      }
      const agent = this.store.createRecord(this.get('modelSet.agentModel'), {
        preparation: preparation,
        person: person
      });
      return agent;
    }
    removeAgent(agent) {
      if (!agent.get('isNew')) {
        this.get('data.agentsToDelete').pushObject(agent);
      }
    }
    _addAgent(preparation, person) {
      if (!person) {
        return null;
      }
      const agent = this.store.createRecord(this.get('modelSet.agentModel'), {
        preparation,
        person: person
      });
      preparation.agents.pushObject(agent);
    }
    _removeAgent(preparation, agent) {
      preparation.agents.removeObject(agent);
      this.get('data.agentsToDelete').pushObject(agent);
    }
  }, _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "modelSet", [_dec5], Object.getOwnPropertyDescriptor(_class3.prototype, "modelSet"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "validityUpdater", [_dec6], Object.getOwnPropertyDescriptor(_class3.prototype, "validityUpdater"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "preparationTypes", [_dec7], Object.getOwnPropertyDescriptor(_class3.prototype, "preparationTypes"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "targetRecordObserver", [_dec8], Object.getOwnPropertyDescriptor(_class3.prototype, "targetRecordObserver"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "disableRemove", [_dec9], Object.getOwnPropertyDescriptor(_class3.prototype, "disableRemove"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "add", [_dec10], Object.getOwnPropertyDescriptor(_class3.prototype, "add"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "remove", [_dec11], Object.getOwnPropertyDescriptor(_class3.prototype, "remove"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "addAgent", [_dec12], Object.getOwnPropertyDescriptor(_class3.prototype, "addAgent"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "removeAgent", [_dec13], Object.getOwnPropertyDescriptor(_class3.prototype, "removeAgent"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "_addAgent", [_dec14], Object.getOwnPropertyDescriptor(_class3.prototype, "_addAgent"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "_removeAgent", [_dec15], Object.getOwnPropertyDescriptor(_class3.prototype, "_removeAgent"), _class3.prototype), _class3);
  var _default = _exports.default = PreparationsComponent;
});
define("plutof/components/pikaday-date", ["exports", "@ember-decorators/object", "moment", "pikaday", "plutof/utils/formatters", "plutof/utils/i18n", "plutof/utils/notifications"], function (_exports, _object, moment, _pikaday, _formatters, _i18n, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var AMBIGUOUS_DATE_REGEXP = new RegExp('^((\\d{4})(-\\d{2})?(-\\d{2})?)$');
  var FULL_DATE_REGEXP = new RegExp('^(\\d{4}-\\d{2}-\\d{2})$');
  function removeEvent(el, e, callback, capture) {
    el.removeEventListener(e, callback, Boolean(capture));
  }
  var SHORT_DATE = 4;
  var MEDIUM_DATE = 7;
  var LONG_DATE = 10;
  var DEFAULT_FORMAT = 'YYYY-MM-DD';
  const i18n = (0, _i18n.getI18n)();
  let PikadayDate = (_dec = (0, _object.observes)('value'), _dec2 = (0, _object.observes)('value', 'after', 'isRequired', 'gte'), _class = class PikadayDate extends Ember.TextField {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_picker", null);
      _defineProperty(this, "validity", true);
      _defineProperty(this, "ambiguous", true);
      _defineProperty(this, "isRequired", false);
      _defineProperty(this, "placeholder", i18n.t('General.datepickerPlaceholder'));
      _defineProperty(this, "after", null);
    }
    // Date input as a string, after which this date will validate.

    init() {
      super.init(...arguments);
      this.checkForFuture();
      this.dateValidator();
    }
    focusOut() {
      // Checks whether the first value is year or not.
      var value = this.value || '';
      if (value.split && value.split('-')[0].length !== 4) {
        this.set('value', '');
      }
    }
    formatMatch(date, ambiguous) {
      if (ambiguous) {
        switch (date.length) {
          case SHORT_DATE:
            return 'YYYY';
          case MEDIUM_DATE:
            return 'YYYY-MM';
          case LONG_DATE:
            return DEFAULT_FORMAT;
          default:
            return null;
        }
      }
      if (date.length === LONG_DATE) {
        return DEFAULT_FORMAT;
      }
      return null;
    }
    _setValidity(validity) {
      this.set('validity', validity);
    }
    setValidity(isValid) {
      Ember.run.scheduleOnce('afterRender', this, '_setValidity', isValid);
    }
    checkForFuture() {
      if (Ember.isNone(this.value)) {
        return;
      }
      Ember.run.debounce(this, this._checkForFuture, 400);
    }
    _checkForFuture() {
      const value = this.value;
      const pickerValue = moment(value, (0, _formatters.getAmbiguousDateFormat)(value));
      const now = moment();
      if (pickerValue.isAfter(now) && value.length >= 4) {
        (0, _notifications.displayNotification)('warning', i18n.t('warnings.dateIsInFuture'), null, 5000, false);
      }
    }
    dateValidator() {
      const value = this.value;
      if (!Ember.isEmpty(value)) {
        const ambiguous = this.ambiguous;
        const after = this.after;
        const gte = this.gte;

        // loosely formatted ambigious date that accepts non-digits
        const date = moment(value, DEFAULT_FORMAT);
        // test value match to correct digit format, without range check
        const numericMatch = ambiguous ? AMBIGUOUS_DATE_REGEXP.test(value) : FULL_DATE_REGEXP.test(value);
        const isAfter = Ember.isEmpty(after) || moment(date).isAfter(moment(after, DEFAULT_FORMAT));
        const isAfterOrIsSame = Ember.isEmpty(gte) || moment(date).isAfter(moment(gte, DEFAULT_FORMAT)) || value === gte;
        const isValid = numericMatch && moment(date).isValid() && isAfter && isAfterOrIsSame;
        this.setValidity(isValid);
      } else {
        this.setValidity(!this.isRequired);
      }
    }
    didInsertElement() {
      var currentYear = new Date().getFullYear();
      var date = this.value || '';
      var format = this.formatMatch(date, this.ambiguous) || DEFAULT_FORMAT;
      var picker = new _pikaday.default({
        field: this.element,
        yearRange: [1900, currentYear + 8],
        format: format,
        firstDay: 1
      });

      // Initial format is done, set format to default.
      picker._o.format = DEFAULT_FORMAT;
      removeEvent(picker._o.field, 'change', picker._onInputChange);

      /*
      * Force outwards binding of the property by triggering its change event.
      * It's for dealing with validation binding issues that came wtih Ember
      * 1.8 update. It can be, perhaps, removed later.
      */
      Ember.run.scheduleOnce('afterRender', this, this._setup, picker);
    }
    _setup(picker) {
      this.set('_picker', picker);
    }
    willDestroyElement() {
      if (this._picker) {
        this._picker.destroy();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "checkForFuture", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "checkForFuture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateValidator", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "dateValidator"), _class.prototype), _class);
  var _default = _exports.default = PikadayDate;
});
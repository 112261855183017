define("plutof/controllers/sequence/add", ["exports", "ember-data", "plutof/controllers/sequence/edit", "plutof/misc/config", "plutof/mixins/add-controller", "plutof/utils/notifications"], function (_exports, _emberData, _edit, _config, _addController, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SequenceAddController = (_dec = Ember.inject.service, _dec2 = Ember.computed.equal('parentContentType.full_name', 'study/study'), _dec3 = Ember.computed.notEmpty('template_id'), _dec4 = Ember.computed('model.sequenceData.sequence.content_type', 'content_type'), _dec5 = Ember._action, _class = class SequenceAddController extends _edit.default.extend(_addController.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "listRoutes", _descriptor, this);
      _defineProperty(this, "queryParams", ['content_type', 'object_id', 'mainform_id', 'area_id', 'event_id', 'project_id']);
      _defineProperty(this, "content_type", null);
      _defineProperty(this, "object_id", null);
      _defineProperty(this, "template_id", null);
      _defineProperty(this, "mainform_id", null);
      _defineProperty(this, "event_id", null);
      _defineProperty(this, "area_id", null);
      _defineProperty(this, "project_id", null);
      _defineProperty(this, "allowAreaReset", true);
      _initializerDefineProperty(this, "parentIsProject", _descriptor2, this);
      _initializerDefineProperty(this, "preserveZoom", _descriptor3, this);
    }
    init() {
      super.init(...arguments);
      this.set('plutofMainforms', [{
        label: this.i18n.t('Forms.htsRepresentative'),
        id: _config.default.Sequence.HTS_REPRESENTATIVE_MAINFORM_ID
      }, {
        label: this.i18n.t('Forms.default'),
        id: _config.default.Sequence.DEFAULT_MAINFORM_ID
      }]);
    }
    get parentContentType() {
      return _emberData.default.PromiseObject.create({
        promise: this.model.sequenceData.sequence.content_type.then(ctype => {
          if (!ctype && this.content_type) {
            ctype = this.store.findRecord('contenttype', this.content_type);
          }
          return ctype;
        })
      });
    }
    _save() {
      return super._save().then(() => {
        this.listRoutes.reset('sequence/sequence');
      });
    }
    save() {
      return (0, _notifications.notifyProcess)(this._save().then(() => {
        this.set('routeHasBeenLoaded', false);
        this.send('goBack');
      }), this.i18n);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "listRoutes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "parentIsProject", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "preserveZoom", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "parentContentType", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "parentContentType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
  var _default = _exports.default = SequenceAddController;
});
define("plutof/components/experiment/common/material-selector", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let MaterialSelector = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('selection.[]', 'material'), _dec3 = Ember.computed('material.hasSource'), _dec4 = Ember._action, _dec(_class = (_class2 = class MaterialSelector extends Ember.Component {
    get selected() {
      return this.selection.includes(this.material);
    }
    get disable() {
      return !Ember.get(this, 'material.hasSource');
    }
    select(material) {
      this.toggle(this.material, !this.selection.includes(material));
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "selected", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "selected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "disable", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "disable"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "select", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "select"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = MaterialSelector;
});
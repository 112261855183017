define("plutof/components/observation/taxon-sheet/taimeatlas", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let TaimeatlasAcronyms = (_dec = (0, _component.classNames)('taimeatlas-acronyms'), _dec2 = Ember.computed('entries.[]', 'sheet.breaks'), _dec(_class = (_class2 = class TaimeatlasAcronyms extends Ember.Component {
    get columns() {
      if (Ember.isEmpty(this.entries)) {
        return [];
      }
      if (!this.sheet.breaks) {
        return [this.entries];
      }
      const acronyms = this.entries.mapBy('item.taxon_name');
      const breaks = this.sheet.breaks.map(columnBreak => acronyms.indexOf(columnBreak));
      let columns = [];
      let start = 0;
      breaks.concat(this.entries.length).forEach(columnBreak => {
        columns.push(this.entries.slice(start, columnBreak));
        start = columnBreak;
      });
      return columns;
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "columns", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "columns"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = TaimeatlasAcronyms;
});
define("plutof/components/filerepository/select-or-upload", ["exports", "@glimmer/component", "plutof/utils/notifications"], function (_exports, _component, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SelectOrUploadFileModel = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! TODO: has-error }}
  <div ...attributes>
      <div class="input-group">
          {{#if @model.pendingUpload}}
              <Input
                  @type="text"
                  @value={{@model.pendingUpload.file.name}}
                  @readonly={{true}}
                  class="form-control" />
          {{else}}
              <Filerepository::AutoComplete
                  @value={{@model.selection}}
                  @update={{@model.selectExisting}}
                  @filters={{hash access="edit"}}
                  @disabled={{@disabled}} />
          {{/if}}
  
          {{#if @model.present}}
              <span class="input-group-btn">
                  <button
                      {{on "click" @model.clear}}
                      class="btn btn-default"
                  >
                      <span class={{icon "remove"}}></span>
                  </button>
              </span>
          {{else}}
              <span class="input-group-btn">
                  <FileSelector
                      @selected={{@model.selectNew}}
                      @multiple={{false}}
                      @name="General.Upload"
                      @disabled={{@disabled}} />
              </span>
          {{/if}}
      </div>
  </div>
  
  */
  {
    "id": "i00nVpEw",
    "block": "{\"symbols\":[\"@model\",\"@disabled\",\"&attrs\"],\"statements\":[[11,\"div\"],[17,3],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\"],[6,[37,3],[[32,1,[\"pendingUpload\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@readonly\"],[\"text\",[32,1,[\"pendingUpload\",\"file\",\"name\"]],true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[8,\"filerepository/auto-complete\",[],[[\"@value\",\"@update\",\"@filters\",\"@disabled\"],[[32,1,[\"selection\"]],[32,1,[\"selectExisting\"]],[30,[36,2],null,[[\"access\"],[\"edit\"]]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,1,[\"present\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n                \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,0],[\"click\",[32,1,[\"clear\"]]],null],[12],[2,\"\\n                    \"],[10,\"span\"],[15,0,[30,[36,1],[\"remove\"],null]],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n                \"],[8,\"file-selector\",[],[[\"@selected\",\"@multiple\",\"@name\",\"@disabled\"],[[32,1,[\"selectNew\"]],false,\"General.Upload\",[32,2]]],null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"icon\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/filerepository/select-or-upload.hbs"
    }
  });
  let SelectOrUploadFileModel = _exports.SelectOrUploadFileModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember.computed('selection', 'pendingUpload'), _class = class SelectOrUploadFileModel {
    constructor({
      fileUpload,
      selection
    }) {
      _initializerDefineProperty(this, "selection", _descriptor, this);
      _initializerDefineProperty(this, "pendingUpload", _descriptor2, this);
      this.selection = selection;
      this.fileUpload = fileUpload;
    }
    selectExisting(file) {
      this.pendingUpload = null;
      this.selection = file;
    }
    async selectNew(file) {
      try {
        // TODO: Save should wait for this
        [this.pendingUpload] = await this.fileUpload.addLocalFiles([file]);
        this.selection = null;
      } catch (err) {
        (0, _notifications.reportError)(err);
      }
    }
    clear() {
      this.pendingUpload = null;
      this.selection = null;
    }
    get size() {
      if (!this.present) {
        return 0;
      }
      return this.selection ? this.selection.size : this.pendingUpload.size;
    }

    // @computed required only because of old-style WrapperSource, which somehow fails
    // to listed to this oterwise
    get present() {
      return Boolean(this.selection || this.pendingUpload);
    }
    async save(access) {
      if (this.pendingUpload) {
        const file = await this.pendingUpload.save(access);
        this.selectExisting(file);
      }
      return this.selection;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "selection", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pendingUpload", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectExisting", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectExisting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectNew", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "present", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "present"), _class.prototype), _class);
  class SelectOrUploadFile extends _component.default {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SelectOrUploadFile);
});
define("plutof/components/clipboard/bulk-update/permit", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Clipboard::InputWrapper @config={{@config}} as |iw|>
      <CollectionLab::Permit::Autocomplete
          @update={{fn this.addPermit iw}}
          @disabled={{iw.disabled}} />
  
      <Common::RecordList
          @records={{iw.value}}
          @remove={{fn this.removePermit iw}} />
  </Clipboard::InputWrapper>
  
  */
  {
    "id": "XXRXGvmR",
    "block": "{\"symbols\":[\"iw\",\"@config\"],\"statements\":[[8,\"clipboard/input-wrapper\",[],[[\"@config\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"collection-lab/permit/autocomplete\",[],[[\"@update\",\"@disabled\"],[[30,[36,0],[[32,0,[\"addPermit\"]],[32,1]],null],[32,1,[\"disabled\"]]]],null],[2,\"\\n\\n    \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,1,[\"value\"]],[30,[36,0],[[32,0,[\"removePermit\"]],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "plutof/components/clipboard/bulk-update/permit.hbs"
    }
  });
  let BulkUpdatePermit = (_dec = Ember._action, _dec2 = Ember._action, _class = class BulkUpdatePermit extends _component.default {
    addPermit(iw, permit) {
      iw.value.pushObject(permit);
    }
    removePermit(iw, permit) {
      iw.value.removeObject(permit);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "addPermit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "addPermit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removePermit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "removePermit"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BulkUpdatePermit);
});
define("plutof/components/map/base-map", ["exports", "@ember-decorators/object", "copy-anything", "ol/Feature", "ol/format/WKT", "ol/geom", "ol/layer/Vector", "ol/Map", "ol/Overlay", "ol/proj", "ol/source/Vector", "ol/View", "plutof/components/map/mixins/default-styling", "plutof/misc/abstract", "plutof/misc/config", "plutof/utils/map", "plutof/utils/promises", "plutof/utils/structures"], function (_exports, _object, _copyAnything, _Feature, _WKT, _geom, _Vector, _Map, _Overlay, _proj, _Vector2, _View, _defaultStyling, _abstract, _config, _map, _promises, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const MAXIMUM_AUTO_ZOOM = 10;
  let BaseMap = (_dec = Ember.computed('enabledControls'), _dec2 = Ember.computed('attachPoint'), _dec3 = (0, _object.observes)('popupEnabled', 'popupID', 'map'), _dec4 = (0, _object.observes)('layerSelection.layerset'), _dec5 = (0, _object.observes)('geodata', 'geomProperty'), _dec6 = (0, _object.observes)('geodata', 'geodata.[]'), _dec7 = (0, _object.observes)('geodata', 'labelProperty'), _dec8 = (0, _object.observes)('closeOverride'), _dec9 = Ember.computed('layerSelection.layerset.optionalLayers.@each.visible', 'layerSelection.layerset.optionalLayers.@each.isLoading', 'layerSelection.layerset.layers.@each.isLoading', 'dataIsLoading', 'externalLoading'), _dec10 = Ember._action, _dec11 = Ember._action, _class = class BaseMap extends Ember.Component.extend(_defaultStyling.default) {
    constructor(...args) {
      super(...args);
      // Data-related inputs
      _defineProperty(this, "geodata", null);
      _defineProperty(this, "geomProperty", '');
      // TODO: Selection should also be in a mixin
      _defineProperty(this, "selected", []);
      _defineProperty(this, "singular", true);
      _defineProperty(this, "mutable", false);
      // Control inputs
      // Those are probably not needed
      // exludeControls: [],
      // addControls: [],
      _defineProperty(this, "enabledControls", ['navigate', 'select', 'layerset']);
      _defineProperty(this, "defaultControl", 'select');
      // Low-level inputs
      _defineProperty(this, "attachPoint", 'map');
      _defineProperty(this, "popupEnabled", false);
      _defineProperty(this, "expanded", false);
      // If true, zoom can be changed only by user
      _defineProperty(this, "preserveZoom", false);
      // Decimal count for WKT output's coordinates
      _defineProperty(this, "wktPrecision", _config.default.Sample.MAP_DRAWING_DEFAULT_PRECISION);
      // Styling
      // XXX: This whole section is too specific - need to work with general OL styles at base-map level
      // and leave this in a mixin or a subclass
      _defineProperty(this, "styleProperty", '');
      _defineProperty(this, "dataIsLoading", false);
      // Parent component can indicate preparation of map data through this
      _defineProperty(this, "externalLoading", false);
    }
    // Advanced multiple mode: hoding extra data in addition to geometry
    // in geodata. createGeodata is supposed to be overriden by the using code:
    //
    // 1. User clicks on map:
    // 2. createData(wkt) is called
    // 3. its result is pushed into geodata
    createGeodata(wkt, map, extras) {
      return wkt;
    }
    get enabled() {
      let status = {};
      this.enabledControls.forEach(name => {
        status[name] = true;
      });
      return status;
    }
    get popupID() {
      return this.attachPoint + '-popup';
    }
    setPopup() {
      const map = this.map;
      const popupID = this.popupID;
      if (this.popupEnabled && !Ember.isNone(popupID) && !Ember.isNone(map)) {
        const popup = new _Overlay.default({
          element: document.getElementById(popupID)
        });
        map.addOverlay(popup);
        this.set('popup', popup);
      } else if (!Ember.isNone(map) && !Ember.isNone(this.popup)) {
        this.removePopup();
      }
    }
    // Initialization
    init() {
      super.init(...arguments);
      this.setProperties({
        map: null,
        view: null,
        featureSource: null,
        popupShown: false,
        fullscreen: false,
        toolPanelOpen: true,
        insertedIntoDOM: false,
        // List of cleanup funcs that will be called when destroying the map
        cleanupActions: []
      });
      const osmLayerset = _map.default.layersets.OSM();
      const selection = _map.default.LayerSelection.create({
        layerset: osmLayerset,
        possibleLayersets: [osmLayerset, _map.default.layersets.Mapbox(), _map.default.layersets.Maaamet()]
      });
      this.set('layerSelection', selection);
      this.setupWKTListeners();
      this.setupLabelListeners();
    }
    reinitializeMap() {
      const layerset = this.get('layerSelection.layerset');
      let extent = null;
      if (_config.default.Sample.PRESERVE_EXTENT_ON_LAYER_SWITCH) {
        const map = this.map;
        const view = this.view;
        if (!Ember.isNone(map) && !Ember.isNone(view)) {
          const currentExtent = view.calculateExtent(map.getSize());
          extent = (0, _proj.transformExtent)(currentExtent, this.internalProjection, this.externalProjection);
        }
      }
      this.cleanup();
      this.createMap(layerset, extent);
      this.reloadGeodata();
      if (this.preserveExtent) {
        this.fitToPreviousExtent();
      }
    }
    setupResizeListener() {
      if (this.disableResizeListener) {
        this.map.updateSize();
      } else {
        const self = this;
        const resizeObserver = new ResizeObserver(entries => {
          const fs = document.fullscreenElement;
          if (!self.isDestroyed) {
            self.set('fullscreen', fs);
            self.get('map').updateSize();
          }
        });
        const attachPoint = document.getElementById(this.attachPoint);
        resizeObserver.observe(attachPoint);
        this.set('elementResizeDetector', resizeObserver);
      }
    }

    // TODO: layer selector should be action-based?
    layersetChanged() {
      if (this.insertedIntoDOM) {
        this.reinitializeMap();
      }
    }

    // For override in subclasses
    createFullToolbar() {
      const featureLayer = this.featureLayer;
      const navigate = _map.default.controls.toolButtons.Navigate.create();
      let buttons = {
        navigate: navigate
      };
      if (!this.singular) {
        buttons.select = _map.default.controls.toolButtons.Select.create({
          layers: [featureLayer],
          getStyle: feature => {
            const data = feature.get('data');
            if (!Ember.isNone(data.rawStyle)) {
              return data.rawStyle(true);
            }
            const geometry = feature.getGeometry();
            const styleClass = data.styleClass || 'base';
            return this.getStyle(geometry.getType(), {
              styleClass: `${styleClass}.selected`
            }, geometry);
          },
          selectionChanged: selected => {
            this.selectionUpdated(selected);
          }
        });
      }
      return buttons;
    }
    createToolbarButtons() {
      const buttons = this.createFullToolbar();

      // Button cleanup
      this.cleanupActions.pushObject(function () {
        Object.keys(buttons).forEach(key => buttons[key].destroy());
      });
      const enabledControls = this.enabledControls;
      const defaultControl = this.defaultControl;
      if (!Ember.isNone(defaultControl) && enabledControls.indexOf(defaultControl) !== -1) {
        buttons[defaultControl].set('isToggled', true);
      }
      this.set('selectControl', buttons.select);
      const controls = enabledControls.map(name => buttons[name]).compact();
      Object.keys(buttons).filter(name => !enabledControls.includes(name)).forEach(name => buttons[name].destroy());
      controls.forEach(control => {
        control.set('interactionGroup', controls);
      });
      return controls;
    }
    createCommonLayers() {
      const featureSource = new _Vector2.default();
      const featureLayer = new _Vector.default({
        source: featureSource
      });
      this.setProperties({
        featureLayer: featureLayer,
        featureSource: featureSource
      });
      return [featureLayer];
    }
    createControls() {
      const mapElement = document.getElementById(this.attachPoint);
      function GetContainer(containerClass) {
        return mapElement.getElementsByClassName(containerClass).item(0);
      }
      return [_map.default.controls.FullscreenButton.create({
        target: GetContainer('fullscreen-container')
      }), _map.default.controls.Zoom.create({
        target: GetContainer('zoom-container')
      }), _map.default.controls.MousePosition.create({
        projection: this.externalProjection,
        target: GetContainer('latlon-container')
      }), _map.default.controls.ScaleLine.create({}), _map.default.controls.Attribution.create({})];
    }
    createMap(base, extent) {
      this.set('internalProjection', base.get('internalProjection'));
      this.set('externalProjection', base.get('externalProjection'));
      let layers = (0, _copyAnything.copy)(base.get('layers')).mapBy('layer');

      // Optional layers
      // XXX: Be careful about sharing layer instances
      const optionalLayers = (0, _copyAnything.copy)(base.get('optionalLayers'));
      const initializedLayers = optionalLayers.filterBy('initialized', true);
      const delayedLayers = optionalLayers.filterBy('initialized', false);

      // TODO: Pass previous extent as @extent arguments to createMap()
      const previousExtent = this.currentExtent; // Map resolution from save & use as template

      initializedLayers.forEach(layer => {
        layer.set('visible', false);
      });

      // Common layers
      layers.pushObjects(initializedLayers.mapBy('layer'));
      layers.pushObjects(this.createCommonLayers());

      // Controls
      const toolbarButtons = this.createToolbarButtons();
      this.set('toolbarButtons', toolbarButtons);
      const controls = this.createControls();

      // Map
      const view = new _View.default({
        center: this.geoToMapCoords([26, 58]),
        // XXX
        zoom: 5,
        projection: this.internalProjection,
        extent: base.get('extent'),
        maxResolutuon: base.get('maxResolution'),
        maxZoom: base.get('maxZoom')
      });
      const interactions = (0, _structures.concat)(toolbarButtons.mapBy('interactions'));
      const map = new _Map.default({
        target: this.attachPoint,
        view: view,
        layers: layers,
        controls: controls.mapBy('control'),
        interactions: interactions
      });

      // Delayed layers
      delayedLayers.forEach(layer => {
        function observer() {
          if (layer.get('initialized')) {
            map.addLayer(layer.get('layer'));
          }
        }

        // TODO: A better way (either just change to events or load layers manually, linking
        // them with the map in the callback)
        Ember.addObserver(layer, 'initialized', observer);
        this.cleanupActions.pushObject(() => {
          Ember.removeObserver(layer, 'initialized', observer);
        });
      });

      // Initial view
      if (Ember.isPresent(previousExtent)) {
        extent = previousExtent;
      }
      if (Ember.isNone(extent)) {
        extent = base.get('extent');
      } else {
        // Initial extent is passed in external projection, see this.initializeMap()
        // TODO: initializeMap should calculate initial extent itself
        extent = (0, _proj.transformExtent)(extent, this.externalProjection, this.internalProjection);
      }
      if (!Ember.isNone(extent)) {
        view.fit(extent, {
          constrainResolution: false
        });
      }

      // Finish
      this.setProperties({
        map: map,
        view: view
      });
    }
    fitToPreviousExtent() {
      if (Ember.isPresent(this.currentExtent)) {
        const extent = (0, _proj.transformExtent)(this.currentExtent, this.externalProjection, this.internalProjection);
        this.view.fit(extent, {
          constrainResolution: false
        });
      }
    }
    removePopup() {
      if (this.popup) {
        const el = document.getElementById(this.popupID);
        this.popup.element = null;

        // XXX
        this.element.appendChild(el);
        this.map.removeOverlay(this.popup);
        this.set('popupShown', false);
      }
    }

    // Cleanup
    willDestroyElement() {
      this.cleanup();
      this.teardownResizeListener();
      this.layerSelection.destroy();
    }
    teardownResizeListener() {
      const resizeObserver = this.elementResizeDetector;
      if (!Ember.isNone(resizeObserver)) {
        const attachPoint = document.getElementById(this.attachPoint);
        resizeObserver.unobserve(attachPoint);
      }
    }
    addEventListener(obj, event, handler) {
      obj.on(event, handler);
      this.cleanupActions.push(() => obj.un(event, handler));
    }
    cleanup() {
      const map = this.map;
      if (!Ember.isNone(map)) {
        map.getControls().getArray().slice().forEach(function (control) {
          map.removeControl(control);
        });
        map.getInteractions().getArray().slice().forEach(function (interaction) {
          map.removeInteraction(interaction);
        });
        if (this.popupEnabled) {
          this.removePopup();
        }
        this.cleanupActions.forEach(action => action());
        this.cleanupActions.clear();
        map.setTarget(null);
        this.set('map', null);
        this.set('toolbarButtons', []);
        this.set('view', null);
        this.set('popup', null);

        // Sublcasses need to clean their layers as well
        this.setProperties({
          map: null,
          toolbarButtons: [],
          view: null,
          popup: null,
          featureLayer: null,
          featureSource: null
        });
      }
    }

    // Popup
    showPopup(coords) {
      this.popup.setPosition(coords);
      this.set('popupShown', true);
    }
    hidePopup() {
      this.set('popupShown', false);
    }
    preprocessFeature(feature) {
      const geometry = feature.getGeometry();
      feature.setStyle(this.getFeatureStyle(geometry.getType(), feature.get('data'), geometry));
    }

    // Main internal interface
    // Modification methods are in subclasses
    showFeature(feature) {
      const source = this.featureSource;
      if (this.singular) {
        source.clear();
      }
      if (feature) {
        this.preprocessFeature(feature);
        source.addFeature(feature);
      }
    }
    showFeatures(features) {
      const source = this.featureSource;
      features.forEach(feature => this.preprocessFeature(feature));
      source.addFeatures(features);
      this.updateLabels();
    }
    focusOn(geometry, zoom) {
      const view = this.view;
      if (zoom && !this.preserveZoom && Ember.isPresent(geometry)) {
        view.fit(geometry.getExtent());
      } else {
        view.setCenter((0, _map.getGeometryCenter)(geometry));
      }
    }
    zoomAll() {
      Ember.run.debounce(this, 'zoomToIncludeEveryFeature', null, 100);
    }
    zoomToIncludeEveryFeature() {
      const view = this.view;
      const featureSource = this.featureSource;

      // TODO: Rename to noAutoZoom or something
      if (this.preserveZoom || Ember.isNone(featureSource)) {
        return;
      }
      if (featureSource.getFeatures().length === 0) {
        return;
      }
      const spanningExtent = this.featureSource.getExtent();
      view.fit(spanningExtent);

      // If there is only one geometry and it is a point, we don't want to zoom
      // as close as possible
      const zoomLevel = view.getZoom();
      if (zoomLevel > MAXIMUM_AUTO_ZOOM) {
        const features = featureSource.getFeatures();
        if (features.every(feature => feature.getGeometry().getType() === 'Point')) {
          view.setZoom(MAXIMUM_AUTO_ZOOM);
        }
      }
    }

    // Data transformations
    geoToMapCoords(coords) {
      return (0, _proj.transform)(coords, this.externalProjection, this.internalProjection);
    }
    mapToGeoCoords(coords) {
      const [lon, lat] = (0, _proj.transform)(coords, this.internalProjection, this.externalProjection);

      // OSM map is infititely scrolling horizontally and OL doesn't autonormalize longitude into [-180, 180] interval
      return [lon % 360, lat % 360];
    }

    // Note: use parseWKTNormalized for reading user data
    parseWKT(geom) {
      // Projections should really be properties of the format
      return new _WKT.default().readFeature(geom, {
        dataProjection: this.externalProjection,
        featureProjection: this.internalProjection
      });
    }
    writeWKT(feature) {
      return new _WKT.default().writeFeature(feature, {
        dataProjection: this.externalProjection,
        featureProjection: this.internalProjection,
        decimals: this.wktPrecision
      });
    }
    parseWKTNormalized(geom) {
      let geometry = new _WKT.default().readGeometry((0, _map.stripSRID)(geom), {
        dataProjection: this.externalProjection
      });
      const external = this.externalProjection;
      const internal = this.internalProjection;
      let coords;
      function normalize(coords) {
        if (coords[0] > 180) {
          coords[0] -= 360;
        }
        if (coords[1] > 90) {
          coords[1] -= 180;
        }
        return (0, _proj.transform)(coords, external, internal);
      }
      if (geometry.getType() === 'Point') {
        coords = geometry.getCoordinates();
        geometry.setCoordinates(normalize(coords));
      } else if (geometry.getType() === 'Polygon') {
        coords = geometry.getCoordinates().map(part => part.map(normalize));
        geometry.setCoordinates(coords);
      } else {
        return this.parseWKT(geom);
      }
      return new _Feature.default(geometry);
    }
    coordsToGeometry(lon, lat) {
      return new _geom.Point(this.geoToMapCoords([lon, lat]));
    }

    // Responding to input
    getDataWKT(object, geomProperty) {
      return Ember.isEmpty(geomProperty) ? object : Ember.get(object, geomProperty);
    }
    reloadGeodata() {
      const self = this;
      const geodata = self.get('geodata');
      const source = self.get('featureSource');
      if (Ember.isEmpty(geodata)) {
        if (!Ember.isNone(source)) {
          source.clear();
          self.dataLoaded();
        }
        return;
      }
      function parseData(object) {
        const wkt = self.getDataWKT(object, self.geomProperty);
        if (Ember.isEmpty(wkt)) {
          return null;
        }
        let feature = self.parseWKTNormalized(wkt);
        feature.set('data', object);
        const id = Ember.get(object, 'id');
        if (!Ember.isNone(id)) {
          feature.setId(id);
        }
        return feature;
      }
      if (self.get('singular')) {
        // XXX: feedbackWKT needs to go
        if (geodata !== self.get('feedbackWKT')) {
          const feature = parseData(geodata);
          if (!Ember.isNone(feature)) {
            self.showFeature(feature);
            const geometry = feature.getGeometry();
            self.focusOn(geometry, geometry.getType() !== 'Point');
          }
        }
      } else {
        if (Ember.isNone(source)) {
          return;
        }
        source.clear();
        function processBatch(geodata, num) {
          const features = geodata.map(parseData).compact();
          self.showFeatures(features);
        }
        if (Ember.get(geodata, 'length') < _config.default.Sample.MAP_BATCH_DATA_POINT) {
          processBatch(geodata, 0);
          self.zoomAll();
          self.dataLoaded();
        } else {
          self.set('dataIsLoading', true);
          Ember.run.later(() => {
            const source = this.featureSource;
            const features = [];
            const dataProcessed = _promises.default.processInBatches(geodata, batch => {
              batch.forEach(dataPoint => {
                const feature = parseData(dataPoint);
                self.preprocessFeature(feature);
                features.push(feature);
              });
            }, _config.default.Sample.MAP_PREPROCESS_BATCH_SIZE);
            const dataAdded = dataProcessed.then(() => {
              return _promises.default.processInBatches(features, batch => {
                source.addFeatures(batch);
              }, _config.default.Sample.MAP_INSERT_BATCH_SIZE);
            });
            dataAdded.then(() => {
              self.zoomAll();
              self.set('dataIsLoading', false);
              self.dataLoaded();
            });
          });
        }
      }
    }
    dataLoaded() {
      // Called when async data loading is finished
      // TODO: Call on normal finish as well
    }
    setupWKTListeners() {
      const geodata = this.geodata;
      const geomProperty = this.geomProperty;
      if (!Ember.isNone(geodata) && !Ember.isEmpty(geomProperty)) {
        const observedProperty = this.singular ? geomProperty : `@each.${geomProperty}`;
        Ember.addObserver(geodata, observedProperty, this, 'geodataChanged');
        this.cleanupActions.pushObject(() => {
          Ember.removeObserver(geodata, observedProperty, this, 'geodataChanged');
        });
      }
    }
    geodataChanged() {
      if (!Ember.isNone(this.map)) {
        const geodata = this.geodata;
        if (this.singular || Ember.isNone(geodata) || geodata.length < _config.default.Sample.MAP_BIG_DATA_POINT) {
          this.reloadGeodata();
        } else {
          Ember.run.debounce(this, 'reloadGeodata', null, 100);
        }
      }
    }

    // TODO: DRY: See setupWKTListeners().
    setupLabelListeners() {
      const geodata = this.geodata;
      const labelProperty = this.labelProperty;
      if (!Ember.isNone(geodata) && !Ember.isEmpty(labelProperty)) {
        const observedProperty = this.singular ? labelProperty : `@each.${labelProperty}`;
        Ember.addObserver(geodata, observedProperty, this, 'labelsChanged');
        this.cleanupActions.pushObject(() => {
          Ember.removeObserver(geodata, observedProperty, this, 'labelsChanged');
        });
      }
    }
    labelsChanged() {
      if (!Ember.isNone(this.map)) {
        Ember.run.debounce(this, 'updateLabels', null, 666);
      }
    }
    updateLabels() {
      const source = this.featureSource;
      if (Ember.isNone(this.featureSource) || Ember.isNone(this.labelProperty)) {
        return;
      }
      const features = source.getFeatures();
      features.forEach(feature => {
        const data = feature.get('data');
        const label = Ember.isPresent(data) ? (0, _abstract.nonstrictGet)(data, this.labelProperty) : null;
        const geometry = feature.getGeometry();
        const style = this.getFeatureStyle(geometry.getType(), data, geometry, label);
        feature.setStyle(style);
      });
    }

    // Selection
    selectionUpdated(selectedFeatures) {
      this.showSelectionInPopup(selectedFeatures);
      const selected = selectedFeatures.map(feature => feature.get('data'));
      this.set('selected', selected);
      if (this.selectionChanged) {
        this.selectionChanged(selected);
      }
    }
    showSelectionInPopup(selection) {
      if (this.popupEnabled) {
        if (selection.length === 1) {
          const feature = selection[0];
          this.showPopup((0, _map.getGeometryCenter)(feature.getGeometry()));
        } else {
          this.hidePopup();
        }
      }
    }
    // TODO: just replace with getFeatureStyle(geometry, data) or getFeatureStyle(feature)
    getFeatureStyle(type, data, geometry, label) {
      const styleProperty = this.styleProperty;
      const params = Ember.isNone(data) || typeof data !== 'object' ? {} : (0, _abstract.nonstrictGet)(data, styleProperty);
      return this.getStyle(type, params, geometry, label);
    }

    // Changing the map
    didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, this._setup);
    }
    _setup() {
      // XXX: closeOverride stuff has no place in base-map
      if (!this.closeOverride) {
        this.set('insertedIntoDOM', true);
        this.reinitializeMap();
        this.setupResizeListener();
      }
    }
    initMapUponPanelOpens() {
      if (!this.closeOverride && !this.insertedIntoDOM) {
        this.set('insertedIntoDOM', true);
        this.reinitializeMap();
      }
    }
    get isLoading() {
      const layerset = this.get('layerSelection.layerset');
      const baseLayers = layerset.get('layers');
      const optionalLayers = layerset.get('optionalLayers').filterBy('visible');
      return this.dataIsLoading || this.externalLoading || baseLayers.concat(optionalLayers).isAny('isLoading');
    }
    // Overridable in subclass
    searchCoordinates(lon, lat) {
      const geometry = this.coordsToGeometry(lon, lat);
      this.focusOn(geometry, false);
    }
    expand() {
      this.toggleProperty('expanded');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "enabled", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "enabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "popupID", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "popupID"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPopup", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setPopup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "layersetChanged", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "layersetChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupWKTListeners", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setupWKTListeners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "geodataChanged", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "geodataChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupLabelListeners", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setupLabelListeners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initMapUponPanelOpens", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "initMapUponPanelOpens"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isLoading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchCoordinates", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "searchCoordinates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expand", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "expand"), _class.prototype), _class);
  var _default = _exports.default = BaseMap;
});
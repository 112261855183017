define("plutof/translations/eng/projects", ["exports", "plutof/translations/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    infoTitle: (0, _helpers.alias)('General.Project'),
    area: 'Area',
    newP: 'New Project',
    editP: 'Edit Project',
    project: 'Project',
    samplingArea: 'Sampling Areas',
    samplingAreaOnMap: 'Sampling Areas on the Map',
    samplePresented: 'Given research has samples presented. Map controls are disabled for that matter.',
    predefinedAreas: 'Predefined Areas',
    areaOnMap: 'Sampling area',
    includeSubAreasOnMap: 'Include sub-sampling areas',
    predefinedAreasOnMap: 'Predefined areas',
    samplingAreasOnMap: 'Sampling areas',
    samplingAreasOnMapLegacy: 'Sampling areas (old version)',
    paginatedAreasOnMap: 'Listed sampling areas',
    allPointsOnMap: 'Points',
    searchTitle: 'Search Projects',
    noDefaultStudy: 'No default project set. Project can be set from user settings.',
    childProjects: 'Child projects',
    mainforms: 'Allowed mainforms',
    permissionsChangeWarning: 'Changing visibility will also affect all of the project\'s occurrences, except those that belong in a Collection',
    managingProjects: 'Managing projects',
    areaHierarchy: {
      title: 'Sampling Area Hierarchy',
      notInProject: 'not in project'
    },
    placeholders: {
      searchHint: 'Search by name'
    },
    actions: {
      editWorkgroup: 'Edit',
      newExperiment: 'New',
      exportToJSON: 'JSON'
    },
    fields: {
      name: 'Name'
    }
  };
});
define("plutof/controllers/collection/edit", ["exports", "plutof/controllers/organization/edit", "plutof/mixins/edit-controller", "plutof/utils/notifications", "plutof/utils/access"], function (_exports, _edit, _editController, _notifications, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CollectionEditController = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _class = class CollectionEditController extends Ember.Controller.extend(_editController.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fileUpload", _descriptor, this);
      _defineProperty(this, "recordsToUnload", [{
        record: 'collection',
        subRecords: ['address']
      }]);
    }
    async save() {
      (0, _notifications.clearNotifications)();
      (0, _notifications.displayNotification)('status', this.i18n.t('General.saving'));
      try {
        if (Ember.isPresent(this.logo)) {
          const logoURL = await (0, _edit.uploadLogo)(this.store, this.fileUpload, this.logo);
          this.model.collection.set('logo_url', logoURL);
        }
        const address = await this.model.collection.address;
        await address.save();
        await this.model.collection.save();
        if (Ember.isPresent(this.model.workgroupData)) {
          await this.model.workgroupData.save();
        }
        if (this.model.identifiers) {
          await this.model.identifiers.save();
        }
        this.model.repositories.forEach(repository => repository.set('parent_organization', this.model.collection));
        await Ember.RSVP.all(this.model.repositories.invoke('save'));
        await Ember.RSVP.all(this.model.objectsToDelete.invoke('destroyCompletely'));
        this.model.objectsToDelete.clear();
        const accessRights = await (0, _access.create_access_rights)(this.store);
        accessRights.visible = 'PUBLIC';
        await this.model.linkedItems.save(this.model.collection, accessRights);
        (0, _notifications.displayNotification)('success', this.i18n.t('General.done'));
        this.send('goBack');
        this.set('routeHasBeenLoaded', false);
      } catch (error) {
        (0, _notifications.reportError)(error);
        throw error;
      }
    }
    setLogo(image) {
      this.model.collection.set('logo', null);
      this.set('logo', image);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fileUpload", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setLogo", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setLogo"), _class.prototype), _class);
});
define("plutof/controllers/taxonomy/edit", ["exports", "plutof/mixins/component-validations", "plutof/mixins/edit-controller", "plutof/utils/i18n", "plutof/utils/notifications", "plutof/utils/structures", "plutof/utils/access"], function (_exports, _componentValidations, _editController, _i18n, _notifications, _structures, _access) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const i18n = (0, _i18n.getI18n)();
  let TaxonEditController = (_dec = Ember.computed.alias('model.taxon.tree.id'), _dec2 = Ember.computed.alias('model.taxon.id'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _class = class TaxonEditController extends Ember.Controller.extend(_editController.default, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "treeId", _descriptor, this);
      _initializerDefineProperty(this, "taxonId", _descriptor2, this);
      _defineProperty(this, "validationChildren", [{
        name: 'taxon',
        label: 'General.generalData'
      }, {
        name: 'linkedItems',
        label: 'General.associatedData'
      }]);
    }
    _save() {
      const {
        commonNames,
        taxon
      } = this.model;
      const objectsToDelete = this.get('objectsToDelete');
      return taxon.save().then(async () => {
        taxon.setProperties({
          remarks: '',
          citation_text: ''
        });
        const accessRights = await (0, _access.create_access_rights)(this.store, taxon);
        return Ember.RSVP.all((0, _structures.flatten)([commonNames.filterBy('isNew').invoke('save'), Ember.RSVP.all(objectsToDelete.invoke('destroyRecord')).then(() => objectsToDelete.clear()), this.model.linkedItems.save(taxon, accessRights)]));
      });
    }
    goBackToTree() {
      this.send('go', 'taxonomy.trees', null, {
        tree_id: this.treeId,
        taxon_id: this.taxonId
      });
    }
    addVernacularName(name, language, is_preferred) {
      const record = this.store.createRecord('taxonomy/commonname', {
        taxon_node: this.get('model.taxon'),
        iso_639: language,
        common_name: name,
        is_preferred: is_preferred
      });
      this.get('model.commonNames').pushObject(record);
    }
    removeVernacularName(name) {
      this.get('model.commonNames').removeObject(name);
      this.objectsToDelete.pushObject(name);
    }
    save() {
      (0, _notifications.displayNotification)('status', i18n.t('General.saving'));
      return this._save().then(() => {
        (0, _notifications.displayNotification)('success', i18n.t('General.done'));
        this.set('routeHasBeenLoaded', false);
        return this.keepQueryParams ? this.goBackToTree() : this.send('goBack');
      }, _notifications.reportError);
    }
    cancel() {
      const taxon = this.get('model.taxon');
      taxon.get('errors').clear();
      taxon.rollbackAttributes();
      return taxon.reload().then(() => {
        this.goBackToTree();
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "treeId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "taxonId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addVernacularName", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "addVernacularName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeVernacularName", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeVernacularName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _class);
  var _default = _exports.default = TaxonEditController;
});
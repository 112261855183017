define("plutof/components/transaction/view/agents", ["exports", "plutof/components/transaction/utils", "plutof/utils/structures"], function (_exports, _utils, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList
      @collapse={{this.pagination.isEmpty}}
      data-test="Transaction::View::Agents"
      as |panel|
  >
      <panel.header
          @title={{i18n-t "transactions.agents"}}
          @count={{this.pagination.objectCount}} />
  
      <panel.content>
          <Pagination::PageControls @pagination={{@pagination}} />
  
          <table class="plutof-table table-hover">
              <thead>
                  <tr>
                      <td>{{plutof-labelc path="transactionagent.agent"}}</td>
                      <td>{{plutof-labelc path="transactionagent.role"}}</td>
                      <th>{{plutof-labelc path="transactionagent.agent_name"}}</th>
                  </tr>
              </thead>
  
              <tbody>
                  {{#each this.sortedAgents as |agent|}}
                      <Transaction::View::Agent @agent={{agent}} />
                  {{/each}}
              </tbody>
          </table>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "MOg0Tf7U",
    "block": "{\"symbols\":[\"panel\",\"agent\",\"@pagination\"],\"statements\":[[8,\"plutof-panel/item-list\",[[24,\"data-test\",\"Transaction::View::Agents\"]],[[\"@collapse\"],[[32,0,[\"pagination\",\"isEmpty\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,0],[\"transactions.agents\"],null],[32,0,[\"pagination\",\"objectCount\"]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"pagination/page-controls\",[],[[\"@pagination\"],[[32,3]]],null],[2,\"\\n\\n        \"],[10,\"table\"],[14,0,\"plutof-table table-hover\"],[12],[2,\"\\n            \"],[10,\"thead\"],[12],[2,\"\\n                \"],[10,\"tr\"],[12],[2,\"\\n                    \"],[10,\"td\"],[12],[1,[30,[36,1],null,[[\"path\"],[\"transactionagent.agent\"]]]],[13],[2,\"\\n                    \"],[10,\"td\"],[12],[1,[30,[36,1],null,[[\"path\"],[\"transactionagent.role\"]]]],[13],[2,\"\\n                    \"],[10,\"th\"],[12],[1,[30,[36,1],null,[[\"path\"],[\"transactionagent.agent_name\"]]]],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"sortedAgents\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,\"transaction/view/agent\",[],[[\"@agent\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"plutof-labelc\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/transaction/view/agents.hbs"
    }
  });
  let ViewTransactionAgents = (_dec = Ember.computed.sort('pagination.objects', (0, _structures.compareVia)(agent => agent.role)), _class = class ViewTransactionAgents extends Ember.Component.extend((0, _utils.TransactionHasManyViewMixin)('transaction/transactionagent')) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "sortedAgents", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "sortedAgents", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ViewTransactionAgents);
});
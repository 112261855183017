define("plutof/components/linked-items/edit", ["exports", "plutof/misc/abstract", "plutof/misc/content_types", "plutof/mixins/component-validations", "plutof/utils/annotation/access", "plutof/utils/errors", "plutof/utils/pagination", "plutof/utils/structures", "plutof/components/linked-items/-edit/files", "plutof/components/collection-lab/permit/items/edit"], function (_exports, _abstract, _content_types, _componentValidations, _access, _errors, _pagination, _structures, _files, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ToggleTabsMixin = void 0;
  _exports.createLinkedItemsData = createLinkedItemsData;
  _exports.createMultipleLinkedItemsData = createMultipleLinkedItemsData;
  _exports.default = void 0;
  _exports.loadLinkedItemsData = loadLinkedItemsData;
  var _dec, _class, _dec2, _class2, _dec3, _dec4, _class3;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let SingleItemStore = (_dec = Ember.computed('localChanges', 'pagination.objectCount'), _class = class SingleItemStore extends Ember.Object {
    init() {
      super.init(...arguments);
      this.set('items', []);
      // item.id -> record
      this.set('records', {});
      this.set('deletedRecords', []);

      // Difference in item numbers between local and server state
      this.set('localChanges', 0);
    }
    get count() {
      return this.localChanges + this.get('pagination.objectCount');
    }
    generateID() {
      this.incrementProperty('lastID');
      return this.lastID;
    }
    _addItem(itemData) {
      const id = this.generateID();
      const item = Object.assign({
        id
      }, itemData);
      this.items.pushObject(item);
      return item;
    }
    add(itemData) {
      this.incrementProperty('localChanges');
      return this._addItem(itemData);
    }

    // TODO: Maybe add object here as parameter, so we don't have to hold it inside?
    addRecord(record, extraItemProperties = {}) {
      const itemData = Object.assign({}, extraItemProperties, select(record, this.itemProperties));
      const item = this._addItem(itemData);
      this._registerRecord(item, this.object, record);
    }
    remove(item) {
      this.items.removeObject(item);
      const records = this._getAllItemRecords(item);
      this.deletedRecords.pushObjects(records);
      this.decrementProperty('localChanges');
    }
    _getContentType(record) {
      return (0, _content_types.get_object_ctype)(this.store, record);
    }
    async _createRecord(item, object) {
      const ctype = await this._getContentType(object);
      const props = select(item, this.itemProperties);
      if (this.store.inAnnotation) {
        // XXX: This will do
        function isModel(value) {
          return Boolean(value && value.id);
        }
        await Ember.RSVP.all(Object.values(props).filter(isModel).map(value => {
          return (0, _access.extendPermissionsToModerators)(this.store.emberDataStore, object, value);
        }));
      }
      const properties = Object.assign(props, {
        content_type: ctype,
        object_id: object.id
      });
      return this.store.createRecord(this.itemModel, properties);
    }
    createRecord(item, object) {
      return this._createRecord(item, object);
    }

    // Single store cache ignores object completely - all records are assumed to be attached to a single
    // object (even though this is not 100% the case - event files have a different object but are still
    // treated the same)
    //
    // Object here is so that we can reuse the code for multiple store
    _getRecord(item, object) {
      return this.records[item.id];
    }
    _getAllItemRecords(item) {
      return [this.records[item.id]];
    }
    _registerRecord(item, object, record) {
      this.records[item.id] = record;
    }
    save(object, access) {
      const recordsDestroyed = Ember.RSVP.all(this.deletedRecords.invoke('destroyRecord')).then(() => {
        this.set('deletedRecords', []);
      });
      return recordsDestroyed.then(() => {
        return Ember.RSVP.all(this.items.map(item => {
          const existingRecord = this._getRecord(item, object);
          if (Ember.isNone(existingRecord)) {
            return this.createRecord(item, object).then(record => {
              // XXX: Hack to prevent duplicating event items
              //
              // If multiple objects are processed simultaneuously, they both might (and do) try
              // to write into the same cache entry:
              // - A checks the cache, sees that habitat record is missing
              // - A pushes record creation to event loop
              // - Meanwhile, B check is executed, while the cache is still empty
              // - B pushed its own record creation
              //
              // As a result, there are two distinct records.
              //
              // With the way we "handle" event files, there are several possible solutions, each one
              // unsatisfying. This is one of the uglier ones, but it's at least on a safe side
              //
              // TODO: Rethink
              const existingCheck = this._getRecord(item, object);
              if (Ember.isNone(existingCheck)) {
                this._registerRecord(item, object, record);
                return record.save();
              } else {
                record.deleteRecord();
                return (0, _abstract.wrap_as_promise)(existingCheck);
              }
            });
          } else {
            return (0, _abstract.wrap_as_promise)(existingRecord);
          }
        }));
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "count", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "count"), _class.prototype), _class);
  class MultipleItemStore extends SingleItemStore {
    _getRecord(item, object) {
      let records = this.records;
      if (Ember.isNone(records[item.id])) {
        records[item.id] = {};
      }
      return records[item.id][object.get('id')];
    }
    _getAllItemRecords(item) {
      const records = this.records;
      if (Ember.isNone(records[item.id])) {
        return [];
      }
      return (0, _structures.concat)(Object.values(records[item.id]));
    }
    _registerRecord(item, object, record) {
      let records = this.records;
      if (Ember.isNone(records[item.id])) {
        records[item.id] = {};
      }
      records[item.id][object.get('id')] = record;
    }
    _getContentType(record) {
      return (0, _content_types.get_object_ctype)(this.store, record);
    }
  }
  const ItemPaginationMixin = Ember.Mixin.create({
    handlePage: function (page) {
      // Like AccumulatingPaginationMixin
      this.objects.pushObjects(page);

      // But also register newly arrived records with item store
      const itemStore = this.itemStore;
      page.forEach(itemRecord => {
        itemStore.addRecord(itemRecord);
      });
    }
  });
  function select(object, properties) {
    let result = {};
    properties.forEach(prop => result[prop] = Ember.get(object, prop));
    return result;
  }
  function createSingleItemStore(store, itemModel, itemProperties, object) {
    const pagination = _pagination.EmptyPagination.create();
    const itemStore = SingleItemStore.create({
      store,
      itemModel,
      itemProperties,
      object,
      pagination
    });
    return (0, _abstract.wrap_as_promise)(itemStore);
  }
  function loadSingleItemStore(store, itemModel, itemProperties, object, {
    useContentTypeID = true
  } = {}) {
    const itemStore = SingleItemStore.create({
      store: store,
      itemModel: itemModel,
      itemProperties: itemProperties,
      object: object
    });
    return (0, _content_types.get_object_ctype)(store, object).then(ctype => {
      const filters = {
        content_type: ctype.get(useContentTypeID ? 'id' : 'full_name'),
        object_id: object.get('id')
      };
      return (0, _pagination.paginateModel)(store, itemModel, filters, [ItemPaginationMixin], {
        itemStore: itemStore
      }).then(pagination => {
        itemStore.set('pagination', pagination);
        return itemStore;
      });
    });
  }
  function createMultipleItemStore(store, itemModel, itemProperties) {
    const pagination = _pagination.EmptyPagination.create();
    const itemStore = MultipleItemStore.create({
      store,
      itemModel,
      itemProperties,
      pagination
    });
    return (0, _abstract.wrap_as_promise)(itemStore);
  }

  // Temporary adapter while we have to support both filerepos.
  // Afterwards, just split files and eventFiles
  //
  // TODO: Do just that. Or don't? This kinda works too (though is not symmetric)
  let FileItemsAdapter = (_dec2 = Ember.computed('files.count', 'eventFiles.count'), _class2 = class FileItemsAdapter {
    constructor({
      files,
      eventFiles
    }) {
      this.files = files;
      this.eventFiles = eventFiles;
    }
    get count() {
      let count = this.files.count;
      if (this.eventFiles) {
        count += this.eventFiles.count;
      }
      return count;
    }
    add({
      file
    }) {
      this.files.addExisting(file);
    }
    async save(record, access) {
      await this.files.save(record, access);
      if (this.eventFiles) {
        const event = await record.samplingevent;
        if (event) {
          await this.eventFiles.save(event, access);
        } else if (this.eventFiles.count > 0) {
          throw new _errors.userError('Can\t add event files if the record doesn\'t own an event');
        }
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "count", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "count"), _class2.prototype), _class2);
  function createFileItemStore(store, object, useEventFiles = false) {
    const files = (0, _files.createFileItemsModel)(store);
    let eventFiles;
    if (useEventFiles) {
      eventFiles = (0, _files.createFileItemsModel)(store);
    }
    return new FileItemsAdapter({
      files,
      eventFiles
    });
  }
  function createMultipleFileItemStore(store, useEventFiles = false) {
    const files = (0, _files.createFileItemsMultipleModel)(store);
    let eventFiles;
    if (useEventFiles) {
      eventFiles = (0, _files.createFileItemsMultipleModel)(store);
    }
    return new FileItemsAdapter({
      files,
      eventFiles
    });
  }
  async function loadFileItemStore(store, object, useEventFiles = false) {
    const files = await (0, _files.loadFileItemsModel)(store, object);
    let eventFiles;
    if (useEventFiles) {
      const event = await object.samplingevent;
      eventFiles = await (0, _files.loadFileItemsModel)(store, event);
    }
    return new FileItemsAdapter({
      files,
      eventFiles
    });
  }
  let LinkedItemsData = (_dec3 = Ember.computed('files.files.count', 'files.eventFiles.count'), _dec4 = Ember.computed('enabledTabs.files', 'files.files.local.length', 'files.files.existing.length'), _class3 = class LinkedItemsData extends Ember.Object {
    init() {
      super.init(...arguments);
      this.set('newGlossaries', []);
      this.set('filesInQueue', []);
    }
    get fileCount() {
      const files = this.get('files.files');
      const eventFiles = this.get('files.eventFiles');
      let count = files.count;
      if (eventFiles) {
        count += eventFiles.count;
      }
      return count;
    }
    get sharedFileCount() {
      if (!this.enabledTabs.files) {
        return 0;
      }
      const files = this.get('files.files');
      return files.count;
    }
    save(objects, access) {
      objects = (0, _structures.makeArray)(objects);
      const fileStore = this.files;
      let saveGlossaries = Ember.RSVP.Promise.resolve();
      if (this.enabledTabs.glossaries) {
        const newGlossaries = this.newGlossaries;
        saveGlossaries = Ember.RSVP.all(newGlossaries.invoke('save')).then(() => access.saveWithTarget(newGlossaries)).then(() => this.set('newGlossaries', []));
      }
      const savePermits = this.enabledTabs.permits ? Ember.RSVP.all(objects.map(obj => this.permits.save(obj))) : Ember.RSVP.Promise.resolve();
      return Ember.RSVP.all([saveGlossaries, savePermits]).then(() => {
        const itemStores = [fileStore, this.references, this.glossaries, this.links];
        return Ember.RSVP.all(objects.filter(store => Boolean(store)).map(object => {
          return Ember.RSVP.all(itemStores.invoke('save', object, access));
        }));
      });
    }
  }, _applyDecoratedDescriptor(_class3.prototype, "fileCount", [_dec3], Object.getOwnPropertyDescriptor(_class3.prototype, "fileCount"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "sharedFileCount", [_dec4], Object.getOwnPropertyDescriptor(_class3.prototype, "sharedFileCount"), _class3.prototype), _class3); // TODO: DRY
  async function createLinkedItemsData(store, object, {
    useEventFiles = false,
    disabledTabs = ['permits']
  } = {}) {
    const enabledTabs = fillEnabledTabs(disabledTabs);
    const fields = {
      enabledTabs,
      useEventFiles
    };
    addStores(fields, enabledTabs, {
      files: () => createFileItemStore(store, object, useEventFiles),
      references: () => createSingleItemStore(store, 'reference/item', ['reference', 'pages'], object),
      glossaries: () => createSingleItemStore(store, 'glossary/item', ['glossary'], object),
      links: () => createSingleItemStore(store, 'reference/externallink', ['link', 'description'], object),
      permits: () => (0, _edit.createPermitItemsEditModel)(store)
    });
    return LinkedItemsData.create(await Ember.RSVP.hash(fields));
  }
  function addStores(fields, enabledTabs, loaders) {
    for (const [tab, loader] of Object.entries(loaders)) {
      if (enabledTabs[tab]) {
        fields[tab] = loader();
      }
    }
  }

  // If useEventFiles is true, event's linked files will be used together with object's own
  async function loadLinkedItemsData(store, object, {
    useEventFiles = false,
    disabledTabs = ['permits']
  } = {}) {
    const enabledTabs = fillEnabledTabs(disabledTabs);
    const fields = {
      enabledTabs,
      useEventFiles
    };
    addStores(fields, enabledTabs, {
      files: () => loadFileItemStore(store, object, useEventFiles),
      references: () => loadSingleItemStore(store, 'reference/item', ['reference', 'pages'], object),
      glossaries: () => loadSingleItemStore(store, 'glossary/item', ['glossary'], object),
      links: () => loadSingleItemStore(store, 'reference/externallink', ['link', 'description'], object),
      permits: () => (0, _edit.loadPermitItemsEditModel)(store, object)
    });
    return LinkedItemsData.create(await Ember.RSVP.hash(fields));
  }
  async function createMultipleLinkedItemsData(store, {
    useEventFiles = false,
    disabledTabs = ['permits']
  } = {}) {
    const enabledTabs = fillEnabledTabs(disabledTabs);
    const fields = {
      enabledTabs,
      useEventFiles
    };
    addStores(fields, enabledTabs, {
      files: () => createMultipleFileItemStore(store, 'filerepository/item', ['file']),
      references: () => createMultipleItemStore(store, 'reference/item', ['reference', 'pages']),
      glossaries: () => createMultipleItemStore(store, 'glossary/item', ['glossary']),
      links: () => createMultipleItemStore(store, 'reference/externallink', ['link', 'description']),
      permits: () => (0, _edit.createPermitItemsEditModel)(store)
    });
    return LinkedItemsData.create(await Ember.RSVP.hash(fields));
  }
  function fillEnabledTabs(disabled) {
    let enabled = {
      files: true,
      references: true,
      glossaries: true,
      links: true,
      permits: true
    };
    disabled.forEach(tab => enabled[tab] = false);
    return enabled;
  }
  const ToggleTabsMixin = _exports.ToggleTabsMixin = Ember.Mixin.create({
    disabledTabs: ['permits'],
    enabledTabs: Ember.computed('disabledTabs.[]', function () {
      return fillEnabledTabs(this.disabledTabs);
    })
  });
  class LinkedItems extends Ember.Component.extend(ToggleTabsMixin, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", ['files']);
    }
  }
  var _default = _exports.default = LinkedItems;
});
define("plutof/components/clipboard/bulk-update-sampling-event", ["exports", "@ember-decorators/component", "plutof/components/clipboard/bulk-update"], function (_exports, _component, _bulkUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BulkUpdateSamplingEvent = (_dec = (0, _component.classNames)('bulk-update-sampling-event'), _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('data.inputs'), _dec(_class = (_class2 = class BulkUpdateSamplingEvent extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "inputs", _descriptor2, this);
    }
    init() {
      super.init(...arguments);
      const inputs = {
        description: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'samplingevent.description'
        }),
        gathering_agents: (0, _bulkUpdate.createInput)('hasMany', {
          labelPath: 'SamplingEvent.gatheringAgents',
          labelSource: 'local'
        }),
        timespan_begin: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'samplingevent.timespan_begin'
        }),
        timespan_end: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'samplingevent.timespan_end'
        })
      };

      // XXX, See bulk-update-specimen-general
      Ember.run.next(() => {
        this.set('data.inputs', (0, _bulkUpdate.processInputs)(inputs, this.data));
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "inputs", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2)) || _class);
  var _default = _exports.default = BulkUpdateSamplingEvent;
});
define("plutof/components/layer/view/-areas/row", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
      <td>{{@area.name}}</td>
  
      <td>
          {{short-string @area.geom 40}}
  
          <button
              {{on "click" (fn this.openGeomView @area)}}
              class="btn-link"
          >
              <span class={{icon "preview"}}></span>
          </button>
  
          {{#if this.geomViewOpen}}
              <Area::Geometry::View
                  @area={{@area}}
                  @dialogOpen={{this.geomViewOpen}}
                  @closeDialog={{this.closeGeomView}} />
          {{/if}}
      </td>
  </tr>
  
  */
  {
    "id": "l3zMoiv1",
    "block": "{\"symbols\":[\"@area\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"geom\"]],40],null]],[2,\"\\n\\n        \"],[11,\"button\"],[24,0,\"btn-link\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"openGeomView\"]],[32,1]],null]],null],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[30,[36,3],[\"preview\"],null]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n\"],[6,[37,4],[[32,0,[\"geomViewOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"area/geometry/view\",[],[[\"@area\",\"@dialogOpen\",\"@closeDialog\"],[[32,1],[32,0,[\"geomViewOpen\"]],[32,0,[\"closeGeomView\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"short-string\",\"fn\",\"on\",\"icon\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/layer/view/-areas/row.hbs"
    }
  });
  let LayerRowView = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _class = class LayerRowView extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "geomViewOpen", _descriptor, this);
      _initializerDefineProperty(this, "geomViewArea", _descriptor2, this);
    }
    openGeomView(area) {
      this.geomToolArea = area;
      this.geomViewOpen = true;
    }
    closeGeomView() {
      this.geomViewOpen = false;
      this.geomViewArea = null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "geomViewOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "geomViewArea", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openGeomView", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "openGeomView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeGeomView", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "closeGeomView"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LayerRowView);
});
define("plutof/models/common/preparation", ["exports", "ember-data", "@ember-data/model", "plutof/misc/abstract", "plutof/models/plutof-model", "plutof/utils/store"], function (_exports, _emberData, _model, _abstract, _plutofModel, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getPreparationModelset = getPreparationModelset;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // XXX: There should just be one model for preparations, connected using ctype/oid.
  // The only thing to solve would be that type choices depend on the model. Then we
  // wouldn't need to do this
  const preparationModelsets = {
    'taxonoccurrence/specimen/specimen': {
      model: 'taxonoccurrence/specimen/specimen',
      preparationModel: 'taxonoccurrence/specimen/preparation',
      objectField: 'specimen',
      // Field in prep model which hold the object
      agentModel: 'taxonoccurrence/specimen/preparingagent',
      optionalFields: {
        storage_temperature: true,
        prepared_location: true
      }
    },
    'photobank/collectionobject': {
      model: 'photobank/collectionobject',
      preparationModel: 'photobank/preparation',
      objectField: 'collection_object',
      agentModel: 'photobank/preparingagent',
      optionalFields: {
        prepared_location: true
      }
    },
    'taxonoccurrence/livingculture/strain': {
      model: 'taxonoccurrence/livingculture/strain',
      preparationModel: 'taxonoccurrence/livingculture/preparation',
      objectField: 'living_specimen',
      agentModel: 'taxonoccurrence/livingculture/preparing-agent',
      optionalFields: {
        storage_temperature: true
      }
    }
  };
  function getPreparationModelset(model) {
    return preparationModelsets[model];
  }
  let Preparation = (_dec = (0, _model.attr)('enum'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('text'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('text'), _dec9 = Ember.computed('id', 'agentModel'), _class = class Preparation extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "type", _descriptor, this);
      _initializerDefineProperty(this, "name", _descriptor2, this);
      _initializerDefineProperty(this, "count", _descriptor3, this);
      _initializerDefineProperty(this, "prepared_date", _descriptor4, this);
      _initializerDefineProperty(this, "prepared_location", _descriptor5, this);
      _initializerDefineProperty(this, "method_desc", _descriptor6, this);
      _initializerDefineProperty(this, "storage_location", _descriptor7, this);
      _initializerDefineProperty(this, "remarks", _descriptor8, this);
    }
    get persons() {
      if (Ember.isNone(this.id)) {
        return (0, _abstract.wrap_as_promise_array)([]);
      }
      return _emberData.default.PromiseArray.create({
        promise: this.store.query(this.agentModel, {
          preparation: this.id
        })
      });
    }
    async destroyCompletely() {
      const agents = await (0, _store.query)(this.store, this.agentModel, {
        preparation: this.id
      });
      await Ember.RSVP.all(agents.invoke('destroyCompletely'));
      return this.destroyRecord();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "count", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "prepared_date", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "prepared_location", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "method_desc", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "storage_location", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "persons", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "persons"), _class.prototype), _class);
  var _default = _exports.default = Preparation;
});
define("plutof/controllers/application", ["exports", "@ember-decorators/object", "plutof/config/environment", "plutof/misc/fileupload", "plutof/misc/profile_settings", "plutof/utils/access", "plutof/utils/cookies", "plutof/utils/errors", "plutof/utils/menu", "plutof/utils/notifications", "plutof/utils/useragent"], function (_exports, _object, _environment, _fileupload, _profile_settings, _access, _cookies, _errors, _menu, _notifications, _useragent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ApplicationController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.computed('session.isAuthenticated', 'router.currentRouteName'), _dec9 = (0, _object.observes)('session.isAuthenticated'), _dec10 = Ember.computed.alias('currentRoute.hideLoginButton'), _dec11 = Ember.computed.alias('currentRoute.hideRegisterButton'), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _class = class ApplicationController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "emberDataStore", _descriptor2, this);
      _initializerDefineProperty(this, "locale", _descriptor3, this);
      _initializerDefineProperty(this, "maintenanceNotice", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "session", _descriptor6, this);
      _initializerDefineProperty(this, "settings", _descriptor7, this);
      _defineProperty(this, "emptyUI", false);
      _defineProperty(this, "collapseMenu", false);
      _defineProperty(this, "environment", _environment.default);
      _defineProperty(this, "currentRoute", null);
      _initializerDefineProperty(this, "hideLoginButton", _descriptor8, this);
      _initializerDefineProperty(this, "hideRegisterButton", _descriptor9, this);
    }
    init() {
      super.init(...arguments);
      if (_environment.default.MAINTENANCE_NOTICE) {
        const notice = _environment.default.MAINTENANCE_NOTICE[this.locale.locale];
        this.maintenanceNotice.show(Ember.String.htmlSafe(notice), {
          force: true
        });
      }
      this.loggedIn();
      this.setupConnectionStatusHandlers();
      this.setupUpdateNotifications();
    }
    get showSidebar() {
      const authenticated = this.get('session.isAuthenticated');
      const path = this.get('router.currentRouteName');
      return authenticated && !_menu.ROUTES_WITHOUT_MENU.includes(path);
    }
    loggedIn() {
      if (!this.get('session.isAuthenticated')) {
        return;
      }
      var store = this.store;
      (0, _profile_settings.get_personal_settings)(store).then(profile => {
        if (Ember.isNone(profile)) {
          throw 'Missing profile settings (possibly expired access token)';
        }
        if (_environment.default.ENABLE_TERMS_OF_SERVICE && !profile.terms_of_service_accepted) {
          this.showTermsOfService();
        }
        if (_environment.default.SYSTEM_NOTIFICATION) {
          this.showSystemNotification();
        }
        const token = this.get('session.data').authenticated.access_token;
        (0, _fileupload.init_uploader)(this.emberDataStore, this.ajax, token);
        this.settings.set('profile', profile);
        profile.get('language').then(defaultLang => {
          this.changeI18nSettings(defaultLang);
        });
        (0, _access.init_default_rights)((0, _access.get_permissions)(store, this.ajax, profile));
        if (_environment.default.ENABLE_ERROR_REPORTING) {
          (0, _profile_settings.get_current_user)(store).then(user => {
            let payloadConfig = {
              environment: _environment.default.environment,
              build: _environment.default.currentRevision
            };
            if (!Ember.isNone(user)) {
              payloadConfig.person = user.getProperties(['id', 'username', 'email']);
            }
            payloadConfig.client = {
              javascript: {
                source_map_enabled: true,
                code_version: `${_environment.default.environment}-${_environment.default.currentRevision}`
              }
            };
            (0, _errors.configureRollbar)({
              payload: payloadConfig
            });
          });
        }
      }).catch(err => {
        /* eslint-disable no-console */
        console.error('Error during profile load:', err);
        console.error('Invalidating session');
        /* eslint-enable no-console */

        this.session.invalidate().then(() => {
          window.location.reload();
        });
      });
    }
    showSystemNotification() {
      const texts = _environment.default.SYSTEM_NOTIFICATION;
      (0, _notifications.displayNotification)('warning', texts[this.locale.locale] || texts.eng, null, 0);
    }
    setupUpdateNotifications() {
      if (window._plutofUpdateAvailable) {
        this.notifyUpdateAvailable();
      } else {
        window._plutofNotifyUpdateAvailable = () => {
          this.notifyUpdateAvailable();
          delete window._plutofNotifyUpdateAvailable;
        };
      }
    }
    notifyUpdateAvailable() {
      const i18n = this.i18n;
      if (window.Notification) {
        function handler(permission) {
          if (permission === 'granted') {
            navigator.serviceWorker.ready.then(registration => {
              const message = i18n.t('General.browserUpdateNotification');
              if (registration.showNotification) {
                registration.showNotification(message);
              } else {
                // NB: untested, probably doesn't work
                /* eslint-disable no-new */
                new window.Notification(message);
                /* eslint-enable no-new */
              }
            });
          }
        }

        // Safari at least as of version 12 has a different requestPermission signature. Of course.
        if ((0, _useragent.onSafari)()) {
          Notification.requestPermission(handler);
        } else {
          Notification.requestPermission().then(handler);
        }
      }
      const message = i18n.t('General.updateNotification');
      (0, _notifications.displayHtmlNotification)('warning', message, undefined, 0);
    }
    changeI18nSettings(defaultLang) {
      const iso = defaultLang.get('iso_639');
      if (this.get('i18n.locale') !== iso) {
        (0, _cookies.setCookie)('language', iso);
        window.location.reload();
      }
    }
    setupConnectionStatusHandlers() {
      window.addEventListener('online', () => {
        (0, _notifications.displayConnectionStatus)(true, this.i18n.t('General.connection.restored'));
      });
      window.addEventListener('offline', () => {
        (0, _notifications.displayConnectionStatus)(false, this.i18n.t('General.connection.lost'));
      });
    }
    showTermsOfService() {
      this.set('termsOfServiceOpen', true);
    }
    async acceptTermsOfService() {
      await this.ajax.post(`${_environment.default.API_HOST}/public/profile/tos-accepted/`);
      this.set('termsOfServiceOpen', false);
    }
    login() {
      this.transitionToRoute('login');
    }
    register() {
      this.transitionToRoute('step-one-register');
    }
    setMenuState(state) {
      this.set('collapseMenu', state);
      const container = document.querySelector('#outlet-container');
      if (state) {
        container.classList.add('collapsed');
      } else {
        container.classList.remove('collapsed');
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "emberDataStore", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "maintenanceNotice", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showSidebar", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showSidebar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loggedIn", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "loggedIn"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hideLoginButton", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hideRegisterButton", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "acceptTermsOfService", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "acceptTermsOfService"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "login", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "register", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setMenuState", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setMenuState"), _class.prototype), _class);
  var _default = _exports.default = ApplicationController;
});
define("plutof/components/collection/view/annotations", ["exports", "ember-concurrency", "plutof/utils/pagination/builder", "plutof/utils/reflection"], function (_exports, _emberConcurrency, _builder, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::View
      @pagination={{this.annotations}}
      @title={{i18n-t "annotation.table.title"}}
  >
      <I18n::ModelStrings @model="annotation.annotation" as |translations|>
          <RelatedObjects::Content @pagination={{this.annotations}} as |Field annotation|>
              <Annotation::Fields
                  @field={{Field}}
                  @annotation={{annotation}}
                  @translations={{translations}}
                  as |fields|
              >
                  <fields.icon />
                  <fields.link />
                  <fields.status />
                  <fields.author/>
                  <fields.comment />
                  <fields.annotatedAt />
              </Annotation::Fields>
          </RelatedObjects::Content>
      </I18n::ModelStrings>
  </RelatedObjects::View>
  
  */
  {
    "id": "A4AmzbmQ",
    "block": "{\"symbols\":[\"translations\",\"Field\",\"annotation\",\"fields\"],\"statements\":[[8,\"related-objects/view\",[],[[\"@pagination\",\"@title\"],[[32,0,[\"annotations\"]],[30,[36,0],[\"annotation.table.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"i18n/model-strings\",[],[[\"@model\"],[\"annotation.annotation\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,0,[\"annotations\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"annotation/fields\",[],[[\"@field\",\"@annotation\",\"@translations\"],[[32,2],[32,3],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,[32,4,[\"icon\"]],[],[[],[]],null],[2,\"\\n                \"],[8,[32,4,[\"link\"]],[],[[],[]],null],[2,\"\\n                \"],[8,[32,4,[\"status\"]],[],[[],[]],null],[2,\"\\n                \"],[8,[32,4,[\"author\"]],[],[[],[]],null],[2,\"\\n                \"],[8,[32,4,[\"comment\"]],[],[[],[]],null],[2,\"\\n                \"],[8,[32,4,[\"annotatedAt\"]],[],[[],[]],null],[2,\"\\n            \"]],\"parameters\":[4]}]]],[2,\"\\n        \"]],\"parameters\":[2,3]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "meta": {
      "moduleName": "plutof/components/collection/view/annotations.hbs"
    }
  });
  let CollectionAnnotations = (_dec = Ember.inject.service, _class = class CollectionAnnotations extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "update", _descriptor2, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.update.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "update", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const collectionURL = (0, _reflection.get_record_url)(this.collection);
        const pagination = yield (0, _builder.default)({
          ajax: this.ajax
        }).fromEndpoint(collectionURL + 'annotations/').withPreprocess(r => r.objects).withCount.fromResponse(r => r.count).build();
        this.set('annotations', pagination);
      };
    }
  }), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CollectionAnnotations);
});
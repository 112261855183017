define("plutof/utils/search/filters", ["exports", "ol/format", "@turf/buffer", "plutof/components/search/range-input", "plutof/utils/search", "plutof/utils/structures", "plutof/helpers/read-only-boolean"], function (_exports, _format, _buffer, _rangeInput, _search, _structures, _readOnlyBoolean) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TimeFilter = _exports.SearchFilter = _exports.RangeOperator = _exports.OldStyleSearchFilter = _exports.FullTextFilter = _exports.EnumFilterWithBackendChoices = _exports.EnumFilter = void 0;
  _exports.createFilter = createFilter;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _dec3, _dec4, _class2, _descriptor3, _dec5, _class3, _descriptor4, _dec6, _dec7, _dec8, _class4, _descriptor5, _descriptor6, _descriptor7, _dec9, _dec10, _class5, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // TODO: Add representation to this that gets used by "active filters" list
  let SearchFilter = _exports.SearchFilter = (_dec = Ember._tracked, _dec2 = Ember._tracked, _class = class SearchFilter {
    constructor({
      config,
      name,
      value,
      operator,
      store
    }) {
      _initializerDefineProperty(this, "value", _descriptor, this);
      _initializerDefineProperty(this, "operator", _descriptor2, this);
      this.config = config;
      this.name = name;
      this.value = value;
      this.operator = operator;
      this.store = store;
    }
    get label() {
      return this.config.label;
    }
    get valueRepresentation() {
      return this.value;
    }
    get isEmpty() {
      return Ember.isEmpty(this.value);
    }
    get urlParam() {
      // return this.operator ? this.fullName : (this.config.alias || this.name);
      return this.operator ? this.fullName : this.name;
    }

    // XXX: Probably unncesessary in future
    get fullName() {
      return this.operator ? `${this.name}__${this.operator}` : this.name;
    }
    async addToSearchURL(searchParams) {
      searchParams.set(this.fullName, this.value);
    }
    async addToURL(searchParams) {
      this.addToSearchURL(searchParams);
    }

    // TODO: Seriously consider doing everything through query:
    // (url/searchulr) <-> query <-> (filters/columns/etc)
    // This should make the flow easy to reason about, but before that have to
    // work on other search parts as well (primarily, response handling)
    async extractFromURL(value, operator = undefined) {
      // XXX: to split or not must depend on filter type, not on a comma
      this.value = value.includes(',') ? value.split(',') : value;
      this.operator = operator;
    }
    addToQuery(query) {
      query.filters[this.fullName] = this.value;
    }
    async extractFromQuery(value, operator) {
      this.value = value;
      this.operator = operator;
    }
    clear() {
      this.value = null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "operator", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class); // Compatibility for unrefactored stuff that was just EmberObject before
  //
  // TODO: This is transition stuff. But even before we throw its old parts away,
  // sublcasses should list constructor params explicitly
  class OldStyleSearchFilter extends SearchFilter {
    constructor(params) {
      super(params);
      const {
        measurements = [],
        queryArguments
      } = params;
      this.measurements = measurements;
      this.queryArguments = queryArguments;
    }
    set(path, value) {
      return Ember.set(this, path, value);
    }
    setProperties(props) {
      return Ember.setProperties(this, props);
    }
    get(path) {
      return Ember.get(this, path);
    }
    get valueRepresentation() {
      if (this.operator) {
        return `${this.operator} ${this.value}`;
      } else {
        return this.value;
      }
    }
  }
  _exports.OldStyleSearchFilter = OldStyleSearchFilter;
  class RelationFilter extends OldStyleSearchFilter {
    constructor(params) {
      params = Object.assign({}, params);
      if (!params.value) {
        params.value = [];
      }
      super(params);
    }
    get valueRepresentation() {
      return this.value.map(record => record.representation || record).join(', ');
    }
    addToQuery(query) {
      const value = (0, _structures.makeArray)(this.value).mapBy('id').map(id => parseInt(id));
      query.filters[this.fullName] = value;
    }
    async extractFromQuery(value) {
      await this._resolve((0, _structures.makeArray)(value));
    }
    addToSearchURL(searchParams) {
      const value = (0, _structures.makeArray)(this.value).mapBy('id').join(',');
      searchParams.set(this.urlParam, value);
    }
    async extractFromURL(value, operator = undefined) {
      await this._resolve(value.split(','));
      this.operator = operator;
    }
    async _resolve(value) {
      const ctypeID = this.config.content_type.match(/\d+/g).pop();
      const contentType = await this.store.findRecord('contenttype', ctypeID);
      let model = contentType.model_name;

      // XXX TODO: Those should be subclasses of RelationFilter (or at least pass model in constructor)
      if (model === 'agent/organization' && (this.name === 'deposited_in' || this.name === 'collection' || this.name === 'dna_collection')) {
        model = 'agent/collection';
      }
      this.value = await Ember.RSVP.Promise.all(value.map(id => this.store.findRecord(model, id)));
    }
    clear() {
      this.value = [];
    }
  }

  // XXX: Value of this must be a list of (trait, value) pairs. Atm it is, however,
  // encoded old-way so the handling is messy; TODO
  // (XXX NB: Instead of valueRepresentation, Search::Query::ActiveFilter does the
  // translation itself)
  class MeasurementsFilter extends OldStyleSearchFilter {
    addToQuery(query) {
      const value = {};
      this.value.split(';').forEach(encodedMeasurement => {
        const [mid, mvalue] = encodedMeasurement.split(':');
        value[mid] = mvalue;
      });
      query.filters[this.fullName] = value;
    }
    async extractFromURL(value) {
      if (value) {
        const measurements = await Ember.RSVP.Promise.all(value.split(';').map(singleValue => {
          const idPair = singleValue.split('__').shift().replace('parent_area_trait_', '');
          const mid = idPair.split(':').shift();
          return this.store.findRecord('measurement/measurement', mid);
        }));
        this.value = value;
        this.set('measurements', measurements);
      }
    }
    async extractFromQuery(value) {
      if (value) {
        const measurements = await Ember.RSVP.Promise.all(Object.keys(value).map(mid => {
          // XXX Remove parent area prefix and any '__' suffix from measurement ID.
          const id = mid.split('__')[0].replace('parent_area_trait_', '');
          return this.store.findRecord('measurement/measurement', id);
        }));
        this.value = Object.entries(value).map(([mid, mvalue]) => `${mid}:${mvalue}`).join(';');
        Ember.set(this, 'measurements', measurements);
      }
    }
    async addToSearchURL(searchParams) {
      await Ember.RSVP.Promise.all(this.value.split(';').map(async encodedMeasurement => {
        const [mid, mvalue] = encodedMeasurement.split(':');
        if (mid.includes('__isnull')) {
          searchParams.set(mid, mvalue);
        } else {
          // XXX Remove parent area prefix.
          const id = mid.replace('parent_area_trait_', '');
          const measurement = await this.store.findRecord('measurement/measurement', id);
          if (measurement && ['int', 'float'].includes(measurement.type)) {
            // XXX XXX
            const range = (0, _rangeInput.initRange)(mid, mvalue);
            range.query.split('&').forEach(part => {
              const [filter, value] = part.split('=');
              searchParams.set(filter, value);
            });
          } else {
            searchParams.set(mid, mvalue);
          }
        }
      }));
    }
    async addToURL(searchParams) {
      searchParams.set(this.fullName, this.value);
    }
  }
  class BooleanFilter extends OldStyleSearchFilter {
    get valueRepresentation() {
      return (0, _readOnlyBoolean.translateBoolean)([this.value]);
    }
    async extractFromURL(value) {
      this.value = value === 'true';
    }
  }
  class IntegerFilter extends OldStyleSearchFilter {
    async extractFromURL(value) {
      if (value.includes(',')) {
        // XXX: to split or not must depend on filter type, not on a comma
        this.value = value.split(',').map(id => parseInt(id));
      } else {
        this.value = parseInt(value);
      }
    }
  }
  class OldEnumFilter extends OldStyleSearchFilter {
    get valueRepresentation() {
      const choices = this.config.choices;
      if (Ember.isEmpty(choices)) {
        return String(this.value);
      } else {
        const choice = choices.find(choice => {
          return String(choice.value) === String(this.value);
        });
        return Ember.isEmpty(choice) ? null : choice.display_name;
      }
    }
  }

  // This should be default for every enum with choices
  // coming from backend, but don't want to just blanket-switch them
  // without asking anybody
  class EnumFilter extends SearchFilter {
    // choices :: [{ value, label }]
    constructor({
      config,
      name,
      value = [],
      store,
      choices,
      label
    }) {
      super({
        config,
        name,
        value,
        store
      });
      this.choices = choices;
      // TODO: Move this upward
      this._label = label || this.config.label;
      this._byID = {};
      for (const choice of choices) {
        this._byID[choice.value] = choice;
      }
    }
    get label() {
      return this._label;
    }
    get valueRepresentation() {
      return this.value.map(choice => choice.label).join(', ');
    }
    async addToSearchURL(searchParams) {
      searchParams.set(this.fullName, this.value.map(choice => choice.value).join(','));
    }
    async extractFromURL(value, operator = undefined) {
      this.value = value.split(',').map(id => this._byID[id.trim()]).filter(id => !Ember.isEmpty(id));
    }
    addToQuery(query) {
      query.filters[this.fullName] = this.value.map(choice => choice.value);
    }
    async extractFromQuery(value, operator) {
      this.value = value.map(id => this._byID[id]);
    }
    get ui() {
      return 'enum';
    }
  }
  _exports.EnumFilter = EnumFilter;
  class EnumFilterWithBackendChoices extends EnumFilter {
    constructor({
      config,
      name,
      store,
      label
    }) {
      super({
        config,
        name,
        store,
        label,
        choices: config.choices.map(choice => ({
          value: choice.value,
          label: choice.display_name
        }))
      });
    }
  }
  _exports.EnumFilterWithBackendChoices = EnumFilterWithBackendChoices;
  let GeometryFilter = (_dec3 = Ember._tracked, _dec4 = Ember.computed('value', 'buffer'), _class2 = class GeometryFilter extends OldStyleSearchFilter {
    constructor(...args) {
      super(...args);
      // is a string for ease of binding
      _initializerDefineProperty(this, "buffer", _descriptor3, this);
    }
    get bufferValid() {
      if (Ember.isEmpty(this.buffer)) {
        return false;
      }
      const value = parseInt(this.buffer.trim());
      return !isNaN(value) && value >= 0;
    }
    get bufferedValue() {
      if (!this.bufferValid || !this.value) {
        return this.value;
      }
      const buffer = parseInt(this.buffer.trim());
      if (buffer === 0) {
        return this.value;
      }
      const wktFormat = new _format.WKT();
      const geojsonFormat = new _format.GeoJSON();
      const geometry = geojsonFormat.writeGeometryObject(wktFormat.readGeometry(this.value));
      const bufferedGeometry = (0, _buffer.default)(geometry, buffer, {
        units: 'meters'
      }).geometry;
      const bufferedWKT = wktFormat.writeGeometry(geojsonFormat.readGeometry(bufferedGeometry));
      return bufferedWKT;
    }
    addToSearchURL(searchParams) {
      searchParams.set(this.fullName, this.bufferedValue);
    }
    addToQuery(query) {
      query.filters[this.fullName] = this.bufferedValue;
    }
    async extractFromURL(value, operator) {
      this.value = value;
      this.operator = operator;
    }
  }, _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "buffer", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "bufferedValue", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "bufferedValue"), _class2.prototype), _class2);
  class GBIFDatasetFilter extends RelationFilter {
    async extractFromQuery(value) {
      this.value = await Ember.RSVP.Promise.all((0, _structures.makeArray)(value).map(id => {
        return this.store.findRecord('publishing/gbif/dataset', id);
      }));
    }
  }
  class MainformFilter extends RelationFilter {
    // This filter has an alias, but that break pheno search, so we're forcing
    // the full name
    get urlName() {
      return 'mainform';
    }
  }
  let FullTextFilter = _exports.FullTextFilter = (_dec5 = Ember._tracked, _class3 = class FullTextFilter extends SearchFilter {
    constructor({
      value = '',
      searchAllFields = false,
      store
    }) {
      super({
        store,
        // TODO: In future, should split the config onto parts
        // that each filter type requires
        config: {
          // This is just debugging stub for now, it's bettern than tracking
          // where the null config comes from
          label: 'textQuery'
        },
        name: 'textQuery',
        value
      });
      _initializerDefineProperty(this, "searchAllFields", _descriptor4, this);
      // XXX
      _defineProperty(this, "marker", 'fulltext');
      this.searchAllFields = searchAllFields;
    }
    get urlParam() {
      return this.urlParam;
    }
    get fullName() {
      return this.searchAllFields ? 'q' : 'name';
    }
    addToQuery(query) {
      query.filters.textQuery = {
        value: this.value,
        searchAllFields: this.searchAllFields
      };
    }
    async extractFromQuery(value, operator) {
      if (value) {
        this.value = value.value;
        this.searchAllFields = value.searchAllFields;
      } else {
        this.value = '';
        this.searchAllFields = false;
      }
    }
  }, _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "searchAllFields", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class3);
  const RangeOperator = _exports.RangeOperator = {
    Equal: {
      id: '=',
      addToSearchURL(name, lower, upper, query) {
        const value = Ember.isEmpty(lower) ? upper : lower;
        query.set(name, value);
      },
      representation(lower, upper) {
        return Ember.isEmpty(lower) ? upper : lower;
      }
    },
    Inclusive: {
      id: '<=',
      addToSearchURL(name, lower, upper, query) {
        if (!Ember.isEmpty(lower)) {
          query.set(`${name}__gte`, lower);
        }
        if (!Ember.isEmpty(upper)) {
          query.set(`${name}__lte`, upper);
        }
      },
      representation(lower, upper) {
        return `${lower}<=${upper}`;
      }
    },
    Exclusive: {
      id: '<',
      addToSearchURL(name, lower, upper, query) {
        if (!Ember.isEmpty(lower)) {
          query.set(`${name}__gt`, lower);
        }
        if (!Ember.isEmpty(upper)) {
          query.set(`${name}__lt`, upper);
        }
      },
      representation(lower, upper) {
        return `${lower}<${upper}`;
      }
    }
  };
  let RangeFilter = (_dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _class4 = class RangeFilter extends OldStyleSearchFilter {
    constructor(params) {
      super(params);
      _initializerDefineProperty(this, "lowerBound", _descriptor5, this);
      _initializerDefineProperty(this, "upperBound", _descriptor6, this);
      _initializerDefineProperty(this, "rangeOperator", _descriptor7, this);
    }
    get fullName() {
      return this.name;
    }
    get valueRepresentation() {
      const lower = this.lowerBound.trim();
      const upper = this.upperBound.trim();
      return this.rangeOperator.representation(lower, upper);
    }
    get isEmpty() {
      return Ember.isEmpty(this.lowerBound) && Ember.isEmpty(this.upperBound);
    }
    async addToSearchURL(searchParams) {
      this.rangeOperator.addToSearchURL(this.name, this.lowerBound, this.upperBound, searchParams);
    }
    async addToURL(searchParams) {
      const lower = this.lowerBound.trim();
      const upper = this.upperBound.trim();
      searchParams.set(this.name, `${this.rangeOperator.id},${lower},${upper}`);
    }
    async extractFromURL(value) {
      const [opid, lower, upper] = value.split(',');
      this.rangeOperator = Object.values(RangeOperator).find(op => op.id === opid);
      if (!this.rangeOperator) {
        // Want to know about this
        throw new Error(`Unknown range operator for ${this.name}: ${opid}`);
      }
      this.lowerBound = lower;
      this.upperBound = upper;
    }
    addToQuery(query) {
      query.filters[this.fullName] = {
        operator: this.rangeOperator.id,
        lower: this.lowerBound,
        upper: this.upperBound
      };
    }
    async extractFromQuery(value) {
      this.rangeOperator = Object.values(RangeOperator).find(op => op.id === value.operator);
      if (!this.rangeOperator) {
        // Want to know about this
        throw new Error(`Unknown range operator for ${this.name}: ${value.operator}`);
      }
      this.lowerBound = value.lower;
      this.upperBound = value.upper;
    }
    clear() {
      this.rangeOperator = RangeOperator.Inclusive;
      this.lowerBound = '';
      this.upperBound = '';
    }
  }, _descriptor5 = _applyDecoratedDescriptor(_class4.prototype, "lowerBound", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class4.prototype, "upperBound", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class4.prototype, "rangeOperator", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RangeOperator.Inclusive;
    }
  }), _class4);
  class TimeFilter extends RangeFilter {}
  _exports.TimeFilter = TimeFilter;
  let AdditionalIdentifiersFilter = (_dec9 = Ember._tracked, _dec10 = Ember._tracked, _class5 = class AdditionalIdentifiersFilter extends SearchFilter {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "code", _descriptor8, this);
      _initializerDefineProperty(this, "source", _descriptor9, this);
    }
    async extractFromURL(value) {
      const match = /^(.*?):(.*)$/.exec(value);
      if (match) {
        this.source = match[1];
        this.code = match[2];
      } else {
        this.source = '';
        this.code = '';
      }
      this.value = [this.source, this.code];
    }
    get valueRepresentation() {
      if (this.source) {
        if (this.code) {
          return `${this.code} (${this.source})`;
        } else {
          return `* (${this.source})`;
        }
      } else if (this.code) {
        return this.code;
      } else {
        return '';
      }
    }
    get isEmpty() {
      return !this.code && !this.source;
    }
    async addToSearchURL(searchParams) {
      searchParams.set(this.fullName, `${this.source}:${this.code}`);
    }
    async extractFromQuery(value, operator) {
      [this.source, this.code] = value;
    }
    clear() {
      this.value = ['', ''];
      this.code = '';
      this.source = '';
    }
  }, _descriptor8 = _applyDecoratedDescriptor(_class5.prototype, "code", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class5.prototype, "source", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _class5);
  const _RANGE_FILTERS = new Set(_search.RANGE_FILTERS);
  const _TIME_FILTERS = new Set(_search.TIME_FILTERS);
  function createFilter({
    store,
    key,
    config,
    queryArguments
  }) {
    const attrs = {
      store,
      config,
      value: [],
      measurements: [],
      name: key,
      queryArguments
    };
    if (_RANGE_FILTERS.has(key)) {
      return new RangeFilter(attrs);
    }
    if (_TIME_FILTERS.has(key)) {
      return new TimeFilter(attrs);
    }

    // TODO: Move stuff like this to module config (createFilter)
    switch (key) {
      case 'measurements':
        return new MeasurementsFilter(attrs);
      case 'gbif_dataset':
        return new GBIFDatasetFilter(attrs);
      case 'mainform':
        return new MainformFilter(attrs);
      case 'additional_identifiers':
        return new AdditionalIdentifiersFilter(attrs);
      default:
        break;
    }
    switch (config.type) {
      case 'boolean':
        return new BooleanFilter(attrs);
      case 'geo_shape':
        return new GeometryFilter(attrs);
      case 'integer':
        return config.content_type ? new RelationFilter(attrs) : new IntegerFilter(attrs);
      case 'choice':
        return new OldEnumFilter(attrs);
      default:
        return new OldStyleSearchFilter(attrs);
    }
  }
});
define("plutof/components/study/view/area-tree/index", ["exports", "ember-concurrency", "plutof/utils/pagination"], function (_exports, _emberConcurrency, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList
      @collapse={{true}}
      data-test="Project::View::AreaTree"
      as |panel|
  >
      <panel.header @title={{i18n-t "Projects.areaHierarchy.title"}} />
  
      <panel.content>
          <div class="area-tree">
              <ol class="area-tree__children">
                  {{#each this.setupPagination.last.value.objects as |topLevelArea|}}
                      <Study::View::AreaTree::Node
                          @area={{topLevelArea}}
                          @project={{@project}} />
                  {{/each}}
  
                  <Study::View::AreaTree::Pagination @pagination={{this.setupPagination.last.value}} />
              </ol>
          </div>
      </panel.content>
  </PlutofPanel::ItemList>
  
  
  */
  {
    "id": "r8Fc4h0W",
    "block": "{\"symbols\":[\"panel\",\"topLevelArea\",\"@project\"],\"statements\":[[8,\"plutof-panel/item-list\",[[24,\"data-test\",\"Project::View::AreaTree\"]],[[\"@collapse\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\"],[[30,[36,0],[\"Projects.areaHierarchy.title\"],null]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"area-tree\"],[12],[2,\"\\n            \"],[10,\"ol\"],[14,0,\"area-tree__children\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"setupPagination\",\"last\",\"value\",\"objects\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,\"study/view/area-tree/node\",[],[[\"@area\",\"@project\"],[[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n                \"],[8,\"study/view/area-tree/pagination\",[],[[\"@pagination\"],[[32,0,[\"setupPagination\",\"last\",\"value\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/study/view/area-tree/index.hbs"
    }
  });
  let AreaTree = (_dec = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec2 = Ember.computed.or('setupPagination.isRunning', 'pagination.isLoading'), _class = class AreaTree extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "setupPagination", _descriptor, this);
      _initializerDefineProperty(this, "loading", _descriptor2, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.setupPagination.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "setupPagination", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const pagination = yield (0, _pagination.paginateModel)(this.store, 'sample/samplingarea', {
          study: this.project.id,
          // If area is null, we want filter to be exactly null, not undefined, as the latter doesn't
          // get applied
          parent_samplingarea: 'null'
        }, [_pagination.AccumulatingPaginationMixin]);
        return pagination;
      };
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AreaTree);
});
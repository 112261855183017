define("plutof/components/plutof-select/multiple/-wrap", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes>
      {{! value is just passed to clear the select }}
      {{yield (hash
          value=this.selectValue
          update=this.add
      ) to="select"}}
  
      <Common::RecordList
          @records={{@value}}
          @remove={{@remove}}
          as |item|
      >
          {{yield item to="selection"}}
      </Common::RecordList>
  </div>
  
  */
  {
    "id": "t6FHjNVS",
    "block": "{\"symbols\":[\"item\",\"&attrs\",\"&default\",\"@value\",\"@remove\"],\"statements\":[[11,\"div\"],[17,2],[12],[2,\"\\n\"],[2,\"    \"],[18,3,[[30,[36,1],[\"select\"],null],[30,[36,0],null,[[\"value\",\"update\"],[[32,0,[\"selectValue\"]],[32,0,[\"add\"]]]]]]],[2,\"\\n\\n    \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,3,[[30,[36,1],[\"selection\"],null],[32,1]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"-named-block-invocation\"]}",
    "meta": {
      "moduleName": "plutof/components/plutof-select/multiple/-wrap.hbs"
    }
  });
  // import { tracked } from '@glimmer/tracking';
  let MultipleSelectWrapper = (_dec = Ember._action, _class = class MultipleSelectWrapper extends _component.default {
    constructor(...args) {
      super(...args);
      // @tracked
      _defineProperty(this, "selectValue", null);
    }
    add(value) {
      if (value) {
        this.args.add(value);
        Ember.set(this, 'selectValue', value);
        Ember.run.later(() => {
          Ember.set(this, 'selectValue', null);
        });
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "add", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MultipleSelectWrapper);
});
define("plutof/utils/notifications", ["exports", "ember-ajax/errors", "moment", "plutof/utils/errors", "plutof/utils/i18n"], function (_exports, _errors, moment, _errors2, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clearNotifications = clearNotifications;
  _exports.displayConnectionStatus = displayConnectionStatus;
  _exports.displayHtmlNotification = displayHtmlNotification;
  _exports.displayNotification = displayNotification;
  _exports.getNotifications = getNotifications;
  _exports.notifyProcess = notifyProcess;
  _exports.removeAllNotifications = removeAllNotifications;
  _exports.removeNotification = removeNotification;
  _exports.reportError = reportError;
  const i18n = (0, _i18n.getI18n)();
  var messages = [];
  function removeNotification(object) {
    messages.removeObject(object);
  }
  function removeAllNotifications() {
    messages.clear();
  }
  function convertObjectsToText(errorString) {
    var object = JSON.parse(errorString);
    var additionalInfo = '';
    var errorMessage = '';
    for (var key in object) {
      if (Ember.typeOf(object[key]) === 'array' && Ember.typeOf(object[key][0]) === 'object') {
        var errorByName = object[key].mapBy('name');
        var wholeObjects = additionalInfo.concat(JSON.stringify(object[key]).replace(/['"]+/g, ' '));
        additionalInfo += Ember.isEmpty(errorByName) ? wholeObjects : errorByName;
      } else {
        // var str = object[key].join().replace(',', '; ').replace('.;', ';');
        var str = object[key].toString().replace(',', '; ').replace('.;', ';');
        if (key === 'error_message') {
          errorMessage += str + ' - ';
        } else {
          var sentence = key + ': ' + str.toLowerCase();
          additionalInfo += sentence.concat(sentence.slice(-1) === '.' ? ' ' : '. ');
        }
      }
    }
    return errorMessage.concat(additionalInfo);
  }
  function displayConnectionStatus(connected, message) {
    const connectionLostMessage = messages.findBy('type', 'connection-error');
    if (Ember.isPresent(connectionLostMessage)) {
      removeNotification(connectionLostMessage);
    }
    if (connected) {
      displayNotification('info', message, null, 5000, false);
    } else {
      displayNotification('connection-error', message, null, 0, false);
    }
  }
  function displayHtmlNotification(type, text, detail, successTime = 5000, scrollToTop = true) {
    displayNotification(type, Ember.String.htmlSafe(text.trim()), detail, successTime, scrollToTop);
  }

  // TODO: latter parameters to param object
  // TODO: enum for notification types
  function displayNotification(type, text, detail = null, successTime = 5000, scrollToTop = true) {
    if (Ember.isEmpty(type) || Ember.isEmpty(text)) {
      type = 'error';
      text = 'Illegal input.';
    }

    // TODO: This should be in getErrorMessage
    var stringContainsObjects = text[0] === '{';
    if (stringContainsObjects) {
      text = convertObjectsToText(text);
    }
    if (!Ember.isNone(detail)) {
      text += ' ' + detail;
    }
    var message = Ember.Object.create({
      type: type,
      text: text.trim ? text.trim() : text,
      success: type === 'success',
      error: type === 'error',
      info: type === 'info',
      status: type === 'status',
      warning: type === 'warning',
      timestamp: moment()
    });

    // If the last message is of into or status type then remove it.
    if (messages.length > 0) {
      var lastMessage = messages[messages.length - 1];
      if (lastMessage.type === 'info' || lastMessage.type === 'status') {
        removeNotification(lastMessage);
      }
    }
    messages.pushObject(message);
    if (type !== 'error' && type !== 'status' && successTime > 0) {
      Ember.run.later(function () {
        removeNotification(message);
      }, successTime);
    }
    if (scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
  function reportError(error, {
    omitTitle = false
  } = {}) {
    let errors;
    let noTitle = omitTitle;
    if (error.isAdapterError || (0, _errors.isAjaxError)(error)) {
      errors = Ember.get(error, 'payload.errors') || Ember.get(error, 'errors');
    } else {
      errors = Ember.get(error, 'jqXHR.responseJSON.errors');
    }
    if (Ember.isArray(errors)) {
      errors.forEach(err => {
        let message = err.detail;
        let key;
        if (err.status === '500') {
          noTitle = true;
          message = i18n.t('queryError.status.500');
        } else if (!Ember.isEmpty(err.title)) {
          key = err.title;
        } else if (!Ember.isNone(err.source) && !Ember.isEmpty(err.source.pointer)) {
          key = err.source.pointer;
        } else {
          key = 'Error';
        }

        // Concerning aesthetics
        if (noTitle || ['/data', '/data/attributes/non_field_errors', '/data/attributes/detail'].includes(key)) {
          displayNotification('error', `${message}`);
        } else {
          displayNotification('error', `${key}: ${message}`);
        }
      });
    } else {
      displayNotification('error', (0, _errors2.getErrorMessage)(error));
    }

    /* eslint-disable no-console */
    console.error(error);
    /* eslint-enable no-console */

    (0, _errors2.processError)(error);
  }
  function clearNotifications(clearErrors = true) {
    // Do not clear connection errors.
    const nonConnectionMessages = messages.rejectBy('type', 'connection-error');
    if (clearErrors) {
      messages.removeObjects(nonConnectionMessages);
    } else {
      const nonErrors = nonConnectionMessages.rejectBy('type', 'error');
      messages.removeObjects(nonErrors);
    }
  }
  function getNotifications() {
    return messages;
  }

  // TODO: Better place for this? Not default to saving? Allow skipping the
  // name display altogethr
  function notifyProcess(promise, i18n, name = undefined) {
    if (name === undefined) {
      name = i18n.t('General.saving');
    }
    clearNotifications();
    displayNotification('status', name);
    return promise.then(value => {
      displayNotification('success', i18n.t('General.done'));
      return value;
    }).catch(err => {
      reportError(err);

      // Rethrow to let caller handle
      throw err;
    });
  }
});
define("plutof/services/file-upload", ["exports", "plutof/config/environment", "plutof/misc/abstract", "plutof/misc/fileupload", "plutof/utils/errors", "plutof/utils/filetypes", "plutof/utils/i18n", "plutof/utils/reflection"], function (_exports, _environment, _abstract, _fileupload, _errors, _filetypes, _i18n, _reflection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _dec2, _dec3, _dec4, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // Deals with the scenario of user setting the same file for upload multiple times. Inside a pool,
  // each file is supposed to be unique, though keep in mind that equality test is imperfect. Hashing
  // file contents is overkill to prevent a situation that will probably never happen: same-sized same-named
  // file with the same timestamp. If somebody uploads stuff like that, it's their own problem.
  let FileUploadService = _exports.default = (_dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _class2 = class FileUploadService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      // TODO yet again: make i18n a service
      _defineProperty(this, "i18n", (0, _i18n.getI18n)());
      // :: filename -> PoolFile
      _defineProperty(this, "localFiles", {});
    }
    _probablySameFile(fx, fy) {
      return fx.name === fy.name && fx.size === fy.size && fx.lastModified === fy.lastModified;
    }
    _checkFilenameUniqueness(files) {
      // You might be tempted to remove this and just rely of file upload failure. BUT
      // that will upload the entire file before reject it, so we do need the pre-check
      return this.ajax.request(`${_environment.default.API_HOST}/filerepository/fileuploads/exists/`, {
        data: {
          filenames: files.map(file => file.name).join(',')
        }
      });
    }
    async _createPoolFile(file) {
      let entry = new PoolFile(file, this.ajax);
      entry.licence = await this.settings.profile.default_licence;
      entry.owner = await this.settings.profile.default_rights_owner;
      for (let agent of (await this.settings.profile.default_creators).toArray()) {
        const creator = this.store.createRecord('filerepository/creator', {
          agent
        });
        entry.creators.push(creator);
      }
      entry.title = file.name;
      entry.language = await this.settings.profile.language;
      entry.toBeRetransformed = (0, _filetypes.is_file_image)(file);
      return entry;
    }
    async _addLocalFile(file) {
      let entry = this.localFiles[file.name];
      if (Ember.isNone(entry) || entry.record && entry.record.isDeleted) {
        if (file.size > _environment.default.MAXIMUM_FILE_SIZE) {
          const message = this.i18n.t('FileRepository.fileTooBig').apply(this, [_environment.default.MAXIMUM_FILE_SIZE]);
          throw (0, _errors.userError)(message);
        }
        entry = await this._createPoolFile(file, this.ajax);
        this.localFiles[file.name] = entry;
      } else {
        // If "probably" actual turns out to be a problem, might actually calculate file hashes, now
        // that this method is async
        if (!this._probablySameFile(entry.file, file)) {
          const message = this.i18n.t('FileRepository.filenameNotUnique')(file.name);
          throw (0, _errors.userError)(message);
        }
      }
      return entry;
    }
    async addLocalFiles(files) {
      const duplicates = await this._checkFilenameUniqueness(files);
      if (duplicates.exists) {
        const message = this.i18n.t('FileRepository.alreadyExists')(duplicates.existing_filenames || []);
        throw (0, _errors.userError)(message);
      }
      return Ember.RSVP.all(files.map(file => this._addLocalFile(file)));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "settings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2);
  let PoolFile = (_dec = Ember.computed('_uploadProcess.progress'), _class = class PoolFile {
    constructor(file, ajax) {
      _defineProperty(this, "savingPromise", null);
      _defineProperty(this, "licence", null);
      _defineProperty(this, "owner", null);
      _defineProperty(this, "creators", []);
      _defineProperty(this, "metadata", null);
      // Image retransform status. Held here because we don't want user
      // to retransform the same image multiple times
      _defineProperty(this, "toBeRetransformed", false);
      _defineProperty(this, "retransformRect", null);
      _defineProperty(this, "_uploadProcess", null);
      this.file = file;
      // Don't like this much
      this.ajax = ajax;
    }
    get uploadProgress() {
      if (!this._uploadProcess) {
        return 0;
      }
      return this._uploadProcess.progress;
    }
    _saveCreators() {
      return Ember.RSVP.all(this.creators.filter(creator => creator.hasDirtyAttributes).map(creator => {
        creator.set('file', this.record);
        return creator.save();
      }));
    }
    _retransform() {
      if (Ember.isNone(this.retransformRect)) {
        return;
      }

      // Using construct_request, because method is PUT, but uses QP-s ~_~
      const url = (0, _abstract.construct_request)((0, _reflection.get_record_url)(this.record) + 'retransform/', this.retransformRect);
      return this.ajax.put(url);
    }

    // TODO: Don't resave if nothing changed? Requires much more in-depth checking than usual, for a case
    // that won't happen often
    async _save(access) {
      if (Ember.isNone(this.record)) {
        const uploader = await (0, _fileupload.get_uploader)();
        const upload = await uploader.upload(this.file, {
          frame: this.retransformRect,
          isPublic: access.public
        });
        Ember.set(this, '_uploadProcess', upload);
        this.record = await this._uploadProcess.wait();
      }
      this.record.language = this.language;
      this.record.licence = this.licence;
      this.record.rights_owner = this.owner;
      this.record.title = this.title;
      await this.record.save();
      await this._saveCreators();
      await access.saveWithTarget(this.record);
      return this.record;
    }

    // Accept creators here?
    async save(access) {
      if (!Ember.isNone(this._savingPromise)) {
        return this._savingPromise;
      }
      return this._savingPromise = await this._save(access).then(() => {
        // Allows modification post-save
        this._savingPromise = null;
        // XXX: A meh hack to enable fetching EXIF tags without redownloading
        // the image
        this.record._localFile = this.file;
        return this.record;
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "uploadProgress", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "uploadProgress"), _class.prototype), _class);
});
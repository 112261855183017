define("plutof/components/measurements/add-extra/wrap", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Measurements::AddExtra::WrapGeneric
      @mainform={{@measurements.mainform}}
      @add={{this.add}}
      @module={{@module}}
      @ignoredTraits={{this.ignoredTraits}}
      as |extraMeasurements|
  >
      {{yield extraMeasurements}}
  </Measurements::AddExtra::WrapGeneric>
  
  */
  {
    "id": "cZQ1JlmI",
    "block": "{\"symbols\":[\"extraMeasurements\",\"@measurements\",\"@module\",\"&default\"],\"statements\":[[8,\"measurements/add-extra/wrap-generic\",[],[[\"@mainform\",\"@add\",\"@module\",\"@ignoredTraits\"],[[32,2,[\"mainform\"]],[32,0,[\"add\"]],[32,3],[32,0,[\"ignoredTraits\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,4,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/measurements/add-extra/wrap.hbs"
    }
  });
  let ExtraMeasurementsWrapper = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.measurements.objectMeasurements.[]'), _dec3 = Ember._action, _class = class ExtraMeasurementsWrapper extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get ignoredTraits() {
      return new Set(this.args.measurements.objectMeasurements.map(om => parseInt(om.measurementData.id)));
    }
    add(traits) {
      this.args.measurements.includeExternal(this.store, traits);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "ignoredTraits", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "ignoredTraits"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ExtraMeasurementsWrapper);
});
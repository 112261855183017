define("plutof/components/transaction/view/objects/ordering-button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
     {{on "click" this._updateOrdering}}
     disabled={{this.pagination.isLoading}}
     class="btn-link {{if this.isActive 'table-header-btn' 'table-header-btn table-header-btn--inactive'}}"
  >
      {{#if (and this.isActive this.pagination.isLoading)}}
          <span class={{icon "loading"}}></span>
      {{else if this.isActive}}
          <span class="fas {{if this.isAscending 'fa-sort-up' 'fa-sort-down'}}"></span>
      {{else}}
          <span class="fas fa-sort"></span>
      {{/if}}
  </button>
  
  */
  {
    "id": "kS/m43D/",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,0,[\"pagination\",\"isLoading\"]]],[16,0,[31,[\"btn-link \",[30,[36,0],[[32,0,[\"isActive\"]],\"table-header-btn\",\"table-header-btn table-header-btn--inactive\"],null]]]],[4,[38,2],[\"click\",[32,0,[\"_updateOrdering\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,3],[[32,0,[\"isActive\"]],[32,0,[\"pagination\",\"isLoading\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[30,[36,1],[\"loading\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isActive\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[\"fas \",[30,[36,0],[[32,0,[\"isAscending\"]],\"fa-sort-up\",\"fa-sort-down\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"fas fa-sort\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"icon\",\"on\",\"and\"]}",
    "meta": {
      "moduleName": "plutof/components/transaction/view/objects/ordering-button.hbs"
    }
  });
  let TransactionViewObjectsOrderingButton = _exports.default = (_dec = Ember._action, _class = class TransactionViewObjectsOrderingButton extends _component.default {
    get pagination() {
      return this.args.pagination;
    }
    get currentOrdering() {
      return this.pagination.get('filters.ordering');
    }
    get isActive() {
      return this.args.ordering === this.orderingProperty;
    }
    get isAscending() {
      if (!this.pagination) {
        return false;
      }
      const ordering = this.currentOrdering;
      return Ember.isPresent(ordering) && !ordering.startsWith('-');
    }
    get orderingProperty() {
      if (!this.pagination) {
        return null;
      }
      const ordering = this.currentOrdering;
      return this.isAscending ? ordering : ordering.substring(1);
    }
    _updateOrdering() {
      const ordering = this.args.ordering;
      let newOrdering = ordering;
      if (ordering === this.currentOrdering) {
        newOrdering = this.isAscending ? `-${ordering}` : ordering;
      }
      this.pagination.set('filters.ordering', newOrdering);
      this.pagination.switchPage(1);
      this.pagination.reload();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_updateOrdering", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_updateOrdering"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TransactionViewObjectsOrderingButton);
});
define("plutof/components/generic-views/record-link", ["exports", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.module}}
      <span class={{this.module.icon}}></span>
  
      {{#link-to this.module.routes.view @record.id}}
          {{@record.representation}}
      {{/link-to}}
  {{else}}
      {{@record.representation}}
  {{/if}}
  
  */
  {
    "id": "N6rASda1",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[6,[37,1],[[32,0,[\"module\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[32,0,[\"module\",\"icon\"]]],[12],[13],[2,\"\\n\\n\"],[6,[37,0],null,[[\"route\",\"model\"],[[32,0,[\"module\",\"routes\",\"view\"]],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/generic-views/record-link.hbs"
    }
  });
  let RecordLink = (_dec = Ember.computed('args.record'), _class = class RecordLink extends _component.default {
    get module() {
      return this.args.record ? (0, _modules.getRecordModule)(this.args.record) : null;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "module", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "module"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RecordLink);
});
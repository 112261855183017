define("plutof/components/habitat/view-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#resolve-promise @habitat as |habitat|}}
      <Measurements::MeasurementView::Wrapper
          @mainform={{@mainform}}
          @object={{habitat}}
          as |measurements measurementsEmpty expandMeasurement|
      >
          <PlutofPanel::Simple
              @title={{i18n-t "SamplingEvent.habitat"}}
              @collapse={{and (not this.attributesPresent) measurementsEmpty}}
          >
              <Habitat::HabitatView
                  @habitat={{habitat}}
                  @mainform={{@mainform}}
                  @measurements={{measurements}}
                  @expandMeasurement={{expandMeasurement}} />
          </PlutofPanel::Simple>
      </Measurements::MeasurementView::Wrapper>
  {{/resolve-promise}}
  
  */
  {
    "id": "NHTUIOJs",
    "block": "{\"symbols\":[\"habitat\",\"measurements\",\"measurementsEmpty\",\"expandMeasurement\",\"@mainform\",\"@habitat\"],\"statements\":[[6,[37,3],[[32,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"measurements/measurement-view/wrapper\",[],[[\"@mainform\",\"@object\"],[[32,5],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"SamplingEvent.habitat\"],null],[30,[36,2],[[30,[36,1],[[32,0,[\"attributesPresent\"]]],null],[32,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"habitat/habitat-view\",[],[[\"@habitat\",\"@mainform\",\"@measurements\",\"@expandMeasurement\"],[[32,1],[32,5],[32,2],[32,4]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2,3,4]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"not\",\"and\",\"resolve-promise\"]}",
    "meta": {
      "moduleName": "plutof/components/habitat/view-panel.hbs"
    }
  });
  let HabitatViewPanel = (_dec = Ember.computed('habitat.description', 'habitat.count'), _class = class HabitatViewPanel extends Ember.Component {
    get attributesPresent() {
      return !Ember.isEmpty(this.get('habitat.description')) || this.get('habitat.count') > 0;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "attributesPresent", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "attributesPresent"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HabitatViewPanel);
});
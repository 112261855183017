define("plutof/models/dna-lab/dna", ["exports", "@ember-data/model", "plutof/mixins/display-name-from-value", "plutof/models/dna-lab/common", "plutof/components/experiment/utils", "plutof/utils/model"], function (_exports, _model, _displayNameFromValue, _common, _utils, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DNA = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('decimal'), _dec17 = (0, _model.attr)('decimal'), _dec18 = (0, _model.attr)('decimal'), _dec19 = (0, _model.belongsTo)('agent/collection'), _dec20 = (0, _model.belongsTo)('dna-lab/dna-extraction', {
    inverse: null
  }), _dec21 = (0, _model.belongsTo)('taxonoccurrence/specimen/specimen'), _dec22 = (0, _model.belongsTo)('taxonoccurrence/materialsample/materialsample'), _dec23 = (0, _model.belongsTo)('taxonoccurrence/livingculture/strain'), _dec24 = (0, _model.belongsTo)('users/user'), _dec25 = (0, _model.belongsTo)('users/user'), _dec26 = (0, _model2.privateGuard)('specimen'), _dec27 = (0, _model2.privateGuard)('material_sample'), _dec28 = (0, _model2.privateGuard)('living_specimen'), _dec29 = (0, _displayNameFromValue.displayName)('quality'), _dec30 = (0, _displayNameFromValue.displayName)('purification_method'), _dec31 = (0, _displayNameFromValue.displayName)('preservation_type'), _dec32 = (0, _common.hasSource)('guardedSpecimen', 'materialSample', 'livingSpecimen'), _dec33 = Ember.computed('name'), _dec34 = Ember.computed('tube_nr'), _class = class DNA extends _model.default.extend(_displayNameFromValue.DisplayNameMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "identifier", _descriptor2, this);
      _initializerDefineProperty(this, "secondary_identifier", _descriptor3, this);
      _initializerDefineProperty(this, "primary_id", _descriptor4, this);
      _initializerDefineProperty(this, "remarks", _descriptor5, this);
      _initializerDefineProperty(this, "purification_method", _descriptor6, this);
      _initializerDefineProperty(this, "quality", _descriptor7, this);
      _initializerDefineProperty(this, "quality_check_date", _descriptor8, this);
      _initializerDefineProperty(this, "quality_remarks", _descriptor9, this);
      _initializerDefineProperty(this, "sample_size", _descriptor10, this);
      _initializerDefineProperty(this, "location_in_collection", _descriptor11, this);
      _initializerDefineProperty(this, "preservation_type", _descriptor12, this);
      _initializerDefineProperty(this, "updated_at", _descriptor13, this);
      _initializerDefineProperty(this, "created_at", _descriptor14, this);
      _initializerDefineProperty(this, "tube_nr", _descriptor15, this);
      _initializerDefineProperty(this, "concentration", _descriptor16, this);
      _initializerDefineProperty(this, "preservation_temp", _descriptor17, this);
      _initializerDefineProperty(this, "unit_of_volume", _descriptor18, this);
      _initializerDefineProperty(this, "collection", _descriptor19, this);
      _initializerDefineProperty(this, "dna_extraction", _descriptor20, this);
      _initializerDefineProperty(this, "specimen", _descriptor21, this);
      _initializerDefineProperty(this, "material_sample", _descriptor22, this);
      _initializerDefineProperty(this, "living_specimen", _descriptor23, this);
      _initializerDefineProperty(this, "created_by", _descriptor24, this);
      _initializerDefineProperty(this, "updated_by", _descriptor25, this);
      _initializerDefineProperty(this, "guardedSpecimen", _descriptor26, this);
      _initializerDefineProperty(this, "materialSample", _descriptor27, this);
      _initializerDefineProperty(this, "livingSpecimen", _descriptor28, this);
      _defineProperty(this, "optionMixinModelName", 'dna-lab.dna');
      _initializerDefineProperty(this, "qualityRepresentation", _descriptor29, this);
      _initializerDefineProperty(this, "purificationRepresentation", _descriptor30, this);
      _initializerDefineProperty(this, "preservationTypeRepresentation", _descriptor31, this);
      _initializerDefineProperty(this, "hasSource", _descriptor32, this);
    }
    get representation() {
      return this.name;
    }
    get tubeRepresentation() {
      return Ember.isPresent(this.tube_nr) ? (0, _utils.labwareTubeLabel)(this.tube_nr) : null;
    }
    destroyCompletely() {
      return this.destroyRecord().catch(error => {
        this.rollbackAttributes();
        throw error;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "identifier", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "secondary_identifier", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "primary_id", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "purification_method", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "quality", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "quality_check_date", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "quality_remarks", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "sample_size", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "location_in_collection", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "preservation_type", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "tube_nr", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "concentration", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "preservation_temp", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "unit_of_volume", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "collection", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "dna_extraction", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "specimen", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "material_sample", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "living_specimen", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "created_by", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "updated_by", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "guardedSpecimen", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "materialSample", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "livingSpecimen", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "qualityRepresentation", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "purificationRepresentation", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "preservationTypeRepresentation", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "hasSource", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "representation", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "representation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tubeRepresentation", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "tubeRepresentation"), _class.prototype), _class);
  var _default = _exports.default = DNA;
});
define("plutof/controllers/profile/stats", ["exports", "plutof/utils/file-serialization"], function (_exports, _fileSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProfileStatsController = (_dec = Ember.computed.alias('model.profile.clipboard_size'), _dec2 = Ember.computed.alias('model.clipboardUsage'), _dec3 = Ember.computed('model.profile.storage_used'), _class = class ProfileStatsController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "clipboardSize", _descriptor, this);
      _initializerDefineProperty(this, "usage", _descriptor2, this);
    }
    get fileStorageUsed() {
      const usage = this.get('model.profile.storage_used');
      if (Ember.isNone(usage)) {
        return null;
      }
      return (0, _fileSerialization.getHumanReadableSize)(usage);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "clipboardSize", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "usage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fileStorageUsed", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "fileStorageUsed"), _class.prototype), _class);
  var _default = _exports.default = ProfileStatsController;
});
define("plutof/components/access/view-rights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const ELURIKKUS_BASIS = {
    'taxonoccurrence/observation/observation': 'Observation',
    // NB: Not a real basis, expands to (HumanObservation or MachineObservation) in ERK
    'taxonoccurrence/specimen/specimen': 'PreservedSpecimen',
    'taxonoccurrence/livingculture/strain': 'LivingSpecimen',
    'taxonoccurrence/sequence/sequence': 'GenomicDNA',
    'taxonoccurrence/referencebased/occurrence': 'Literature',
    'taxonoccurrence/materialsample/materialsample': 'MaterialSample'
  };
  const PUBLISHED_IN = [{
    name: 'inElurikkus',
    classes: 'icon-elurikkus view-rights__elurikkus',
    link(record) {
      if (!record || !record.constructor) {
        return null;
      }
      const basis = ELURIKKUS_BASIS[record.constructor.modelName];
      if (!basis) {
        return null;
      }
      return `https://elurikkus.ee/app/datasets/plutof-main/${basis}/${record.id}`;
    }
  }, {
    name: 'inGbif',
    classes: 'published-in-icon--large icon-gbif view-rights__gbif'
  }, {
    name: 'inGenBank',
    classes: 'icon-genbank view-rights__genbank'
  }];
  let AccessView = (_dec = Ember.computed.reads('object.download_count'), _dec2 = Ember.computed.notEmpty('downloads'), _dec3 = Ember.computed.reads('object.in_elurikkus'), _dec4 = Ember.computed.reads('object.in_gbif'), _dec5 = Ember.computed.notEmpty('object.GenBank_ENA_database.id_in_database'), _dec6 = Ember.computed.bool('publishedIn.length'), _dec7 = Ember.computed.mapBy('rights.user_view', 'representation'), _dec8 = Ember.computed.mapBy('rights.user_edit', 'representation'), _dec9 = Ember.computed(...PUBLISHED_IN.map(target => target.name)), _dec10 = Ember.computed('object'), _class = class AccessView extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "downloads", _descriptor, this);
      _initializerDefineProperty(this, "canBeDownloaded", _descriptor2, this);
      _initializerDefineProperty(this, "inElurikkus", _descriptor3, this);
      _initializerDefineProperty(this, "inGbif", _descriptor4, this);
      _initializerDefineProperty(this, "inGenBank", _descriptor5, this);
      _initializerDefineProperty(this, "isPublished", _descriptor6, this);
      _initializerDefineProperty(this, "viewUsernames", _descriptor7, this);
      _initializerDefineProperty(this, "editUsernames", _descriptor8, this);
    }
    get publishedIn() {
      return PUBLISHED_IN.filter(platform => Boolean(this.get(platform.name))).map(platform => ({
        classes: platform.classes,
        link: platform.link ? platform.link(this.get('object')) : null
      }));
    }
    get downloadCountLabelPath() {
      const location = this.get('object.constructor.modelName') || '';
      return location.split('/').join('.') + '.download_count';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "downloads", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "canBeDownloaded", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "inElurikkus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "inGbif", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "inGenBank", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isPublished", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "viewUsernames", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "editUsernames", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "publishedIn", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "publishedIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadCountLabelPath", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "downloadCountLabelPath"), _class.prototype), _class);
  var _default = _exports.default = AccessView;
});
define("plutof/components/referencebased/shared-fields", ["exports", "@glimmer/component", "plutof/utils/validations"], function (_exports, _component, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Referencebased::SharedFields"
  >
      <div class={{unless this.referenceValid 'has-error'}}>
          {{plutof-labelc "referencebased.occurrence.reference"}}
  
          {{#async/bind-relation @reference as |value update|}}
              <Reference::AutoComplete
                  @value={{value}}
                  @update={{update}}
                  data-test="reference" />
          {{/async/bind-relation}}
      </div>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "OTR3LjD8",
    "block": "{\"symbols\":[\"value\",\"update\",\"@reference\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Referencebased::SharedFields\"]],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,1],[[32,0,[\"referenceValid\"]],\"has-error\"],null]],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"referencebased.occurrence.reference\"],null]],[2,\"\\n\\n\"],[6,[37,3],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"reference/auto-complete\",[[24,\"data-test\",\"reference\"]],[[\"@value\",\"@update\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"unless\",\"plutof-labelc\",\"async/bind-relation\"]}",
    "meta": {
      "moduleName": "plutof/components/referencebased/shared-fields.hbs"
    }
  });
  let RefbasedSharedFields = (_dec = Ember.computed.notEmpty('args.reference'), _class = class RefbasedSharedFields extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "referenceValid", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "referenceValid", [_validations.validator, _dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RefbasedSharedFields);
});
define("plutof/components/pagination/page-controls", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PageControls = (_dec = Ember.computed.alias('args.pagination'), _dec2 = Ember.computed('pagination.pageCount', 'args.alwaysShowInterface'), _dec3 = Ember.computed('pagination.reload', 'args.refresh'), _dec4 = Ember.computed('pagination.pageCount', 'args.maxPages'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _class = class PageControls extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "pagination", _descriptor, this);
    }
    get showInterface() {
      return this.args.alwaysShowInterface || Ember.get(this, 'pagination.pageCount') > 1;
    }
    get canRefresh() {
      return !Ember.isNone(this.args.refresh) || !Ember.isNone(Ember.get(this, 'pagination.reload'));
    }
    get browsablePagesCount() {
      const pageCount = Ember.get(this, 'pagination.pageCount');
      const maxPages = this.args.maxPages;
      return Ember.isNone(maxPages) ? pageCount : Math.min(pageCount, maxPages);
    }
    firstPage() {
      this.pagination.switchPage(1);
    }
    lastPage() {
      this.pagination.switchPage(this.pagination.pageCount);
    }
    previousPage() {
      this.pagination.previousPage();
    }
    nextPage() {
      this.pagination.nextPage();
    }
    _refresh() {
      const refreshAction = this.args.refresh;
      if (!Ember.isNone(refreshAction)) {
        refreshAction();
      } else if (!Ember.isNone(this.pagination.reload)) {
        this.pagination.reload();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "pagination", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showInterface", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showInterface"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canRefresh", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "canRefresh"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "browsablePagesCount", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "browsablePagesCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "firstPage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "firstPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lastPage", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "lastPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previousPage", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "previousPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextPage", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "nextPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_refresh", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "_refresh"), _class.prototype), _class);
  var _default = _exports.default = PageControls;
});
define("plutof/components/view-determinations", ["exports", "ember-data", "plutof/components/edit-determinations", "plutof/misc/content_types", "plutof/models/determination/determination", "plutof/utils/store", "plutof/utils/validations", "plutof/utils/notifications"], function (_exports, _emberData, _editDeterminations, _content_types, _determination, _store, _validations, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DeterminationsComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed('targetRecord'), _dec3 = Ember.computed('parentCType.id', 'parentId'), _dec4 = Ember.computed('entries.[]', 'haParentRecord'), _dec5 = Ember.computed('targetRecord'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember.computed, _class = class DeterminationsComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "beingEdited", _descriptor2, this);
      _initializerDefineProperty(this, "pendingDetermination", _descriptor3, this);
      _initializerDefineProperty(this, "validationContext", _descriptor4, this);
      _initializerDefineProperty(this, "taxonImportOpen", _descriptor5, this);
    }
    get entries() {
      const store = this.store;
      const target = this.targetRecord;
      if (Ember.isNone(target) || target.get('isNew')) {
        return [];
      }
      return _emberData.default.PromiseArray.create({
        promise: (0, _content_types.get_object_ctype)(store, target).then(ctype => {
          return (0, _store.query)(store, 'determination/determination', {
            content_type: ctype.get('id'),
            object_id: target.get('id'),
            ordering: '-is_current,-id'
          }).then(determinations => {
            return Ember.RSVP.all(determinations.map(determination => {
              return (0, _store.query)(store, 'determination/determiner', {
                determination: determination.get('id')
              }).then(determiners => {
                return {
                  determination: determination,
                  determiners: determiners
                };
              });
            }));
          });
        })
      });
    }
    get hasParentRecord() {
      return !Ember.isNone(this.parentId) && !Ember.isNone(this.get('parentCType.id'));
    }
    get determinationsCount() {
      // 0 determinations but there's a parent object's determination present.
      const actualCount = this.get('entries.length');
      const parentCount = this.hasParentRecord ? 1 : 0;
      return actualCount + parentCount;
    }
    get enableBasis() {
      return this.targetRecord && (0, _determination.isBasisEnabled)(this.targetRecord);
    }
    add() {
      const ownerInjection = Ember.getOwner(this).ownerInjection();
      this.pendingDetermination = _editDeterminations.DeterminationEntry.create(ownerInjection, {
        determination: this.store.createRecord('determination/determination'),
        determiners: []
      });
      this.validationContext = _validations.ValidationContext.create();
      this.beingEdited = true;
    }
    async save() {
      try {
        const ctype = await (0, _content_types.get_object_ctype)(this.store, this.targetRecord);
        this.pendingDetermination.determination.setProperties({
          content_type: ctype,
          object_id: this.targetRecord.id
        });
        await this.pendingDetermination.save();
        this.entries.unshiftObject({
          determination: this.pendingDetermination.determination,
          determiners: this.pendingDetermination.determiners
        });
        this.finishEdit();
      } catch (err) {
        (0, _notifications.reportError)(err);
        throw err;
      }
    }
    cancel() {
      this.finishEdit();
      this.pendingDetermination.destroy();
      this.pendingDetermination = null;
    }
    finishEdit() {
      this.beingEdited = false;
      this.taxonImportOpen = false;
      this.validationContext.destroy();
    }
    openTaxonImport() {
      this.taxonImportOpen = true;
    }
    closeTaxonImport() {
      this.taxonImportOpen = false;
    }
    get typifications() {
      return (0, _store.query)(this.store, 'determination/typification', {
        is_culture: this.targetRecord.constructor.modelName === 'livinculture/strain' ? 'True' : 'False'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "entries", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "entries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasParentRecord", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasParentRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "determinationsCount", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "determinationsCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "enableBasis", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "enableBasis"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "beingEdited", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pendingDetermination", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "validationContext", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "taxonImportOpen", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openTaxonImport", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "openTaxonImport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeTaxonImport", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "closeTaxonImport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "typifications", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "typifications"), _class.prototype), _class);
  var _default = _exports.default = DeterminationsComponent;
});
define("plutof/templates/components/plutof-select/value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zMg08jbb",
    "block": "{\"symbols\":[\"xs\",\"option\",\"@skipDefault\"],\"statements\":[[6,[37,14],null,[[\"value\",\"action\",\"class\",\"disabled\",\"skipDefault\"],[[35,13],[30,[36,12],[[32,0],\"valueChanged\"],null],[35,11],[35,10],[32,3]]],[[\"default\"],[{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('plutof/templates/components/plutof-select/value.hbs' @ L10:C11) \"],null]],[[\"value\"],[null]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[34,5]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('plutof/templates/components/plutof-select/value.hbs' @ L16:C11) \"],null]],[[\"value\"],[[30,[36,1],[[32,2],[35,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],[[32,2],[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"optionLabelPath\",\"get\",\"optionValuePath\",\"-assert-implicit-component-helper-argument\",\"component\",\"prompt\",\"if\",\"content\",\"-track-array\",\"each\",\"disabled\",\"selectClass\",\"action\",\"value\",\"x-select\"]}",
    "meta": {
      "moduleName": "plutof/templates/components/plutof-select/value.hbs"
    }
  });
});
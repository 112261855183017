define("plutof/controllers/specimen/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let SpecimenViewController = (_dec = Ember.computed('model.contentType.id', 'model.specimen.id'), _dec2 = Ember.computed('model.specimen.id'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _class = class SpecimenViewController extends Ember.Controller {
    get relatedOccurrencesQuery() {
      return {
        content_type: this.model.contentType.id,
        object_id: this.model.specimen.id
      };
    }
    get relatedOccurrencesClipboardQuery() {
      return {
        specimen: this.model.specimen.id
      };
    }
    addWithCType(route) {
      return this.transitionToRoute(route, {
        queryParams: {
          object_id: this.get('model.specimen.id'),
          content_type: this.get('model.contentType.id')
        }
      });
    }
    newSequence() {
      return this.addWithCType('sequence.add');
    }
    newCulture() {
      return this.addWithCType('livingspecimen.add');
    }
    convertToObservation() {
      this._convertToObservation.perform();
    }
    closeConverter() {
      this.set('converterOpen', false);
    }
    openConverter() {
      this.set('converterOpen', true);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "relatedOccurrencesQuery", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "relatedOccurrencesQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "relatedOccurrencesClipboardQuery", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "relatedOccurrencesClipboardQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newSequence", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "newSequence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newCulture", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "newCulture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "convertToObservation", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "convertToObservation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeConverter", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "closeConverter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openConverter", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "openConverter"), _class.prototype), _class);
  var _default = _exports.default = SpecimenViewController;
});
define("plutof/components/measurements/measurement-view/wrapper", ["exports", "@glimmer/component", "ember-data", "plutof/misc/measurements"], function (_exports, _component, _emberData, _measurements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield this.measurements this.empty this.expand}}
  
  <Ui::Modal::Dialog
      @open={{this.modalOpen}}
      @close={{this.collapse}}
  >
      <:header>
          {{this.expandedMeasurement.fullName}}
      </:header>
  
      <:body>
          <Measurements::MeasurementView::Expanded @measurement={{this.expandedMeasurement}} />
      </:body>
  </Ui::Modal::Dialog>
  
  */
  {
    "id": "MJc7DlbB",
    "block": "{\"symbols\":[\"__arg0\",\"&default\"],\"statements\":[[18,2,[[32,0,[\"measurements\"]],[32,0,[\"empty\"]],[32,0,[\"expand\"]]]],[2,\"\\n\\n\"],[8,\"ui/modal/dialog\",[],[[\"@open\",\"@close\",\"@namedBlocksInfo\"],[[32,0,[\"modalOpen\"]],[32,0,[\"collapse\"]],[30,[36,2],null,[[\"header\",\"body\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,0,[\"expandedMeasurement\",\"fullName\"]]],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"body\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"measurements/measurement-view/expanded\",[],[[\"@measurement\"],[[32,0,[\"expandedMeasurement\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/measurements/measurement-view/wrapper.hbs"
    }
  });
  let MeasurementsViewWrapper = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.object.id', 'args.mainform.id'), _dec3 = Ember.computed('measurements.@each.value'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _class = class MeasurementsViewWrapper extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "modalOpen", _descriptor2, this);
      _initializerDefineProperty(this, "expandedMeasurement", _descriptor3, this);
    }
    async load() {
      const [object, form] = await Ember.RSVP.Promise.all([this.args.object, this.args.mainform]);
      if (Ember.isNone(object)) {
        return [];
      }
      return (0, _measurements.get_object_form_measurements)(this.store, object, form);
    }
    get measurements() {
      return _emberData.default.PromiseArray.create({
        promise: this.load()
      });
    }
    get empty() {
      return this.measurements.mapBy('value').compact().length === 0;
    }
    expand(om) {
      this.expandedMeasurement = om;
      this.modalOpen = true;
    }
    collapse() {
      this.expandedMeasurement = null;
      this.modalOpen = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "measurements", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "measurements"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "empty", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "empty"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalOpen", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "expandedMeasurement", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "expand", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "expand"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "collapse", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "collapse"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MeasurementsViewWrapper);
});
define("plutof/components/analysis/view/wrapper-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "Analysis.sourceData"}}>
      <Layout::RegularGrid @columns={{2}}>
          <div>
              {{plutof-labelc path="wrapper-run.source_file"}}
  
              <a href={{@run.source_file.download_links.link}} download>
                  <p class="view-analysis__general-data__source-file">
                      {{short-string @run.source_file.representation 25}}&nbsp;
                  </p>
              </a>
          </div>
  
          <div>
              {{plutof-labelc path="wrapper-run.results_file"}}
  
              <a href={{@run.results_file.download_links.link}} download>
                  <p class="view-analysis__general-data__results-file">
                      {{short-string @run.results_file.representation 25}}&nbsp;
                  </p>
              </a>
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "faJujDT8",
    "block": "{\"symbols\":[\"@run\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"Analysis.sourceData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,1],null,[[\"path\"],[\"wrapper-run.source_file\"]]]],[2,\"\\n\\n            \"],[10,\"a\"],[15,6,[32,1,[\"source_file\",\"download_links\",\"link\"]]],[14,\"download\",\"\"],[12],[2,\"\\n                \"],[10,\"p\"],[14,0,\"view-analysis__general-data__source-file\"],[12],[2,\"\\n                    \"],[1,[30,[36,2],[[32,1,[\"source_file\",\"representation\"]],25],null]],[2,\" \\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,1],null,[[\"path\"],[\"wrapper-run.results_file\"]]]],[2,\"\\n\\n            \"],[10,\"a\"],[15,6,[32,1,[\"results_file\",\"download_links\",\"link\"]]],[14,\"download\",\"\"],[12],[2,\"\\n                \"],[10,\"p\"],[14,0,\"view-analysis__general-data__results-file\"],[12],[2,\"\\n                    \"],[1,[30,[36,2],[[32,1,[\"results_file\",\"representation\"]],25],null]],[2,\" \\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"plutof-labelc\",\"short-string\"]}",
    "meta": {
      "moduleName": "plutof/components/analysis/view/wrapper-content.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});
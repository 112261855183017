define("plutof/controllers/unite/taxonomy", ["exports", "@ember-decorators/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let UNITETaxonomyController = (_dec = (0, _object.observes)('searchedTaxon'), _dec2 = (0, _object.observes)('taxon'), _dec3 = Ember._action, _dec4 = Ember._action, _class = class UNITETaxonomyController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "queryParams", ['taxon_id']);
      _defineProperty(this, "taxon_id", null);
      _defineProperty(this, "level", null);
      _defineProperty(this, "taxon", null);
      _defineProperty(this, "showInfo", false);
      _defineProperty(this, "terminal", false);
      _defineProperty(this, "includeSingletons", null);
      _defineProperty(this, "isRoot", true);
      _defineProperty(this, "isExpanded", true);
    }
    taxonSearchedfunction() {
      var taxon = this.searchedTaxon;
      if (!Ember.isNone(taxon) && this.taxon !== taxon) {
        this.set('showInfo', false);
        this.set('taxon', taxon);
        this.set('isExpanded', true);
        this.set('isRoot', true);
      }
    }
    updateQueryParams() {
      this.set('taxon_id', this.get('taxon.id'));
    }
    resetCluster() {
      this.set('showInfo', false);
      this.set('terminal', false);
      this.set('isExpanded', false);
      this.set('isRoot', false);
      this.set('level', null);
      this.set('taxon', null);
      this.set('parentTaxon', null);
      this.set('parentLevel', null);
      this.set('includeSingletons', null);
      this.set('searchedTaxon', null);
    }

    // XXX: TODO: Don't split, just use as it is everywhere - in any case all those
    // fields usually change together, so wrap them into a Node object
    showNodeInfo({
      terminal,
      taxon,
      level,
      parentTaxon,
      parentLevel
    }) {
      this.set('showInfo', true);
      this.set('terminal', terminal);
      this.set('isExpanded', true);
      this.set('isRoot', true);
      this.set('taxon', taxon);
      this.set('level', level);
      this.set('parentTaxon', parentTaxon);
      this.set('parentLevel', parentLevel);
      this.set('searchedTaxon', taxon);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "taxonSearchedfunction", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "taxonSearchedfunction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQueryParams", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateQueryParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetCluster", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "resetCluster"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showNodeInfo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "showNodeInfo"), _class.prototype), _class);
  var _default = _exports.default = UNITETaxonomyController;
});
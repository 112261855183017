define("plutof/components/datacite/view/general-panel", ["exports", "@glimmer/component", "plutof/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let DataciteViewGeneralData = _exports.default = (_dec = Ember.computed('args.metadata.identifier'), _class = class DataciteViewGeneralData extends _component.default {
    get identifierURL() {
      return `${_environment.default.DOI_HOST}${this.args.metadata.identifier}`;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "identifierURL", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "identifierURL"), _class.prototype), _class);
});
define("plutof/controllers/analysis/add", ["exports", "plutof/controllers/analysis/edit", "plutof/mixins/add-controller"], function (_exports, _edit, _addController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let AnalysisAddController = (_dec = Ember.computed('type', 'model.{wrapperRun,matchingRun}'), _dec2 = Ember.computed('type', 'model.{wrapperSourceData,matchingSourceData}'), _dec3 = Ember._action, _class = class AnalysisAddController extends _edit.default.extend(_addController.default) {
    get run() {
      return this.get('type.wrapper') ? this.model.wrapperRun : this.model.matchingRun;
    }
    get sourceData() {
      return this.get('type.wrapper') ? this.model.wrapperSourceData : this.model.matchingSourceData;
    }
    async _save() {
      this.run.setProperties({
        analysis_type: this.type.id,
        name: this.name
      });
      await super._save();
    }
    switchType(type) {
      this.set('type', type);
      if (!Ember.isNone(type)) {
        this.run.setProperties(type.defaultParameters);
      }
    }
    updateType(type) {
      this.switchType(type);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "run", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "run"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sourceData", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "sourceData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateType", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateType"), _class.prototype), _class);
  var _default = _exports.default = AnalysisAddController;
});
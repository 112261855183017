define("plutof/components/plutof-panel/item-list", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel" ...attributes>
      {{yield (hash
          header=(component "plutof-panel/item-list/header" toggle=this.toggle open=this.open)
          content=(component "plutof-panel/item-list/content" open=this.open)
          innerPanels=(component "plutof-panel/item-list/content" open=this.open panelBodyClass="panel-body no-padding")
          footer=(component "plutof-panel/item-list/footer")
          button=(component "plutof-panel/item-list/button")
          toggle=(component "plutof-panel/item-list/toggle")
      )}}
  </div>
  
  */
  {
    "id": "dzFF8Lq7",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"panel\"],[17,1],[12],[2,\"\\n    \"],[18,2,[[30,[36,1],null,[[\"header\",\"content\",\"innerPanels\",\"footer\",\"button\",\"toggle\"],[[30,[36,0],[\"plutof-panel/item-list/header\"],[[\"toggle\",\"open\"],[[32,0,[\"toggle\"]],[32,0,[\"open\"]]]]],[30,[36,0],[\"plutof-panel/item-list/content\"],[[\"open\"],[[32,0,[\"open\"]]]]],[30,[36,0],[\"plutof-panel/item-list/content\"],[[\"open\",\"panelBodyClass\"],[[32,0,[\"open\"]],\"panel-body no-padding\"]]],[30,[36,0],[\"plutof-panel/item-list/footer\"],null],[30,[36,0],[\"plutof-panel/item-list/button\"],null],[30,[36,0],[\"plutof-panel/item-list/toggle\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/item-list.hbs"
    }
  });
  let ItemList = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _class = class ItemList extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_open", _descriptor, this);
      _initializerDefineProperty(this, "overridden", _descriptor2, this);
    }
    get open() {
      const collapse = this.args.collapse;
      if (!Ember.isNone(collapse) && !this.overridden) {
        return this._open = !collapse;
      }
      return this._open;
    }
    toggle() {
      this.overridden = true;
      this._open = !this._open;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_open", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "overridden", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ItemList);
});
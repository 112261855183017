define("plutof/services/group-membership", ["exports", "plutof/models/users/usergroup"], function (_exports, _usergroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let GroupMembershipService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _class = class GroupMembershipService extends Ember.Service {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor, this);
      _initializerDefineProperty(this, "i18n", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "settings", _descriptor4, this);
      _defineProperty(this, "_membershipCache", new Map());
    }
    async currentUserBelongs(groupID, {
      minRights = _usergroup.UserRights.NeedsModerating,
      minStatus = _usergroup.UserStatus.ReadOnly
    } = {}) {
      const membership = await this._getMembership(groupID);
      return membership && membership.rights >= minRights && membership.status >= minStatus;
    }
    async leaveGroup(groupID) {
      const group = await this.store.findRecord('users/workgroup', groupID);
      const confirmed = await this.confirmationDialog.confirm({
        title: this.i18n.translate('Settings.groups.leave.title'),
        content: this.i18n.translate('Settings.groups.leave.content', {
          hash: {
            group: group.representation
          }
        })
      });
      if (!confirmed) {
        return;
      }
      const membership = await this._getMembership(groupID);
      if (!membership) {
        throw new Error('Dev error: leaveGroup called for a non-member');
      }
      await membership.destroyRecord();
      return membership;
    }
    async _getMembership(groupID) {
      if (!this._membershipCache.has(groupID)) {
        const settings = await this.settings.wait();
        const user = await settings.user;
        this._membershipCache.set(groupID, this.store.queryRecord('users/usergroup', {
          user: user.id,
          group: groupID
        }));
      }
      return this._membershipCache.get(groupID);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "confirmationDialog", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = GroupMembershipService;
});
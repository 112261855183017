define("plutof/components/three-state-checkbox", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label class="plutof-label">
      <Input
          @type="checkbox"
          @checked={{this._checked}}
          @indeterminate={{this.indetermined}}
          {{on "click" this.cycleValue}} />
  
      {{yield}}
  </label>
  
  */
  {
    "id": "+0oKnsXs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"label\"],[14,0,\"plutof-label\"],[12],[2,\"\\n    \"],[8,\"input\",[[4,[38,0],[\"click\",[32,0,[\"cycleValue\"]]],null]],[[\"@type\",\"@checked\",\"@indeterminate\"],[\"checkbox\",[32,0,[\"_checked\"]],[32,0,[\"indetermined\"]]]],null],[2,\"\\n\\n    \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/three-state-checkbox.hbs"
    }
  });
  let ThreeStateCheckbox = (_dec = Ember._tracked, _dec2 = Ember.computed('args.checked'), _dec3 = Ember.computed('args.checked', '_value'), _dec4 = Ember._action, _class = class ThreeStateCheckbox extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_value", _descriptor, this);
      this._value = this.args.checked;
    }
    get indetermined() {
      return typeof this.args.checked !== 'boolean';
    }
    get _checked() {
      const checked = this.args.checked;
      return checked === this._value ? this._value : checked;
    }
    set _checked(value) {
      return;
    }
    _cycleValue() {
      switch (this._checked) {
        case true:
          {
            this._value = false;
            break;
          }
        case false:
          {
            this._value = null;
            break;
          }
        default:
          {
            this._value = true;
          }
      }
      if (!Ember.isNone(this.args.changed)) {
        this.args.changed(this._value);
      }
    }
    cycleValue() {
      Ember.run.next(this, this._cycleValue);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "indetermined", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "indetermined"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_checked", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_checked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cycleValue", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "cycleValue"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ThreeStateCheckbox);
});
define("plutof/controllers/compound/view", ["exports", "plutof/config/environment", "plutof/controllers/unite/view"], function (_exports, _environment, _view) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let CompoundViewController = (_dec = Ember.computed('model.cluster.{mostSpecificTaxonName,code}'), _dec2 = Ember._action, _class = class CompoundViewController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "permissions", {
        canModify: false,
        canDelete: false
      });
    }
    get title() {
      return [this.model.cluster.mostSpecificTaxonName, this.model.cluster.code].compact().join(' | ') || 'Unidentified';
    }
    downloadFASTA() {
      const url = _environment.default.API_HOST + '/globalkey/clustersequencestabels/fasta-file/';
      const clusterID = this.model.cluster.id;
      return (0, _view.downloadClusterFASTA)(this.store, `${url}?cluster=${clusterID}`);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "title", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadFASTA", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "downloadFASTA"), _class.prototype), _class);
  var _default = _exports.default = CompoundViewController;
});
define("plutof/components/agent/organization/associations/-edit-row", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
      <td class={{if @association.fieldErrors.person "has-error"}}>
          {{#async/bind-relation @association.person as |value update|}}
              <Agent::Person::AutoComplete
                  @value={{value}}
                  @update={{update}}
                  @params={{hash mini=true}}
                  @noDefault={{true}} />
          {{/async/bind-relation}}
      </td>
  
      <td>
          {{! Not sure why, but two-way bound inputs stop working here after a while :shrug: }}
          <OneWayInput
              @value={{@association.agent_role}}
              @update={{this.updateRole}}
              class="mini-input" />
      </td>
  
      <td>
          <PikadayDate
              @value={{@association.start_date}}
              @class="mini-input" />
      </td>
  
      <td>
          <PikadayDate
              @value={{@association.end_date}}
              @class="mini-input" />
      </td>
  
      <td>
          <Tables::RemoveButton @clicked={{@remove}} />
      </td>
  </tr>
  
  */
  {
    "id": "DJDDYccG",
    "block": "{\"symbols\":[\"value\",\"update\",\"@association\",\"@remove\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[15,0,[30,[36,1],[[32,3,[\"fieldErrors\",\"person\"]],\"has-error\"],null]],[12],[2,\"\\n\"],[6,[37,2],[[32,3,[\"person\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"agent/person/auto-complete\",[],[[\"@value\",\"@update\",\"@params\",\"@noDefault\"],[[32,1],[32,2],[30,[36,0],null,[[\"mini\"],[true]]],true]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n\"],[2,\"        \"],[8,\"one-way-input\",[[24,0,\"mini-input\"]],[[\"@value\",\"@update\"],[[32,3,[\"agent_role\"]],[32,0,[\"updateRole\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[8,\"pikaday-date\",[],[[\"@value\",\"@class\"],[[32,3,[\"start_date\"]],\"mini-input\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[8,\"pikaday-date\",[],[[\"@value\",\"@class\"],[[32,3,[\"end_date\"]],\"mini-input\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[8,\"tables/remove-button\",[],[[\"@clicked\"],[[32,4]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\",\"async/bind-relation\"]}",
    "meta": {
      "moduleName": "plutof/components/agent/organization/associations/-edit-row.hbs"
    }
  });
  let OrganizationAssociationsEditRow = (_dec = Ember._action, _class = class OrganizationAssociationsEditRow extends _component.default {
    updateRole(value) {
      this.args.association.set('agent_role', value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "updateRole", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateRole"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OrganizationAssociationsEditRow);
});
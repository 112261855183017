define("plutof/components/related-objects/clipboard-buttons", ["exports", "@glimmer/component", "plutof/misc/clipboard"], function (_exports, _component, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToolTip
      @tooltip={{i18n-t "Clipboard.actions.addPage.tooltip"}}
      class="plutof-tooltip-hover plutof-tooltip-hover--inline"
  >
      <PromiseButton
          @clicked={{this.sendPage}}
          class="plutof-btn__controls plutof-btn__controls--xs"
          @restIcon={{icon "clipboard"}}
          @disabled={{@disabled}}
      >
          {{i18n-t "Clipboard.actions.addPage.label"}}
      </PromiseButton>
  </ToolTip>
  
  {{#if @clipboardQuery}}
      <ToolTip
          @tooltip={{i18n-t "Clipboard.actions.addAll.tooltip"}}
          class="plutof-tooltip-hover plutof-tooltip-hover--inline"
      >
          <PromiseButton
              @clicked={{this.sendQuery}}
              class="plutof-btn__controls plutof-btn__controls--xs"
              @restIcon={{icon "clipboard"}}
              @disabled={{@disabled}}
          >
              {{i18n-t "Clipboard.actions.addAll.label"}}
          </PromiseButton>
      </ToolTip>
  {{/if}}
  
  */
  {
    "id": "s6O0aVOk",
    "block": "{\"symbols\":[\"@disabled\",\"@clipboardQuery\"],\"statements\":[[8,\"tool-tip\",[[24,0,\"plutof-tooltip-hover plutof-tooltip-hover--inline\"]],[[\"@tooltip\"],[[30,[36,0],[\"Clipboard.actions.addPage.tooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"promise-button\",[[24,0,\"plutof-btn__controls plutof-btn__controls--xs\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[32,0,[\"sendPage\"]],[30,[36,1],[\"clipboard\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"Clipboard.actions.addPage.label\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tool-tip\",[[24,0,\"plutof-tooltip-hover plutof-tooltip-hover--inline\"]],[[\"@tooltip\"],[[30,[36,0],[\"Clipboard.actions.addAll.tooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"promise-button\",[[24,0,\"plutof-btn__controls plutof-btn__controls--xs\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[32,0,[\"sendQuery\"]],[30,[36,1],[\"clipboard\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"Clipboard.actions.addAll.label\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/related-objects/clipboard-buttons.hbs"
    }
  });
  let RelatedObjectsClipboardButtons = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _class = class RelatedObjectsClipboardButtons extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor2, this);
    }
    async prepareAndSend(fn) {
      const dialog = this.confirmationDialog.CLIPBOARD_APPEND(this.args.clipboard);
      const option = await this.confirmationDialog.selectOption(dialog);
      if (option === 'cancel') {
        return;
      }
      if (option === 'overwrite') {
        await (0, _clipboard.clearClipboard)(this.ajax, this.args.clipboard);
      }
      await fn();
    }
    sendPage() {
      return this.prepareAndSend(async () => {
        const objects = this.args.pagination.objects.slice();
        const ids = objects.map(record => record.id);
        await (0, _clipboard.sendToClipboard)(this.ajax, this.args.clipboard, ids);
        objects.forEach(object => Ember.set(object, 'on_clipboard', true));
      });
    }
    sendQuery() {
      return this.prepareAndSend(async () => {
        await (0, _clipboard.sendToClipboardRaw)(this.ajax, this.args.clipboard, this.args.clipboardQuery);
        this.args.pagination.objects.forEach(object => Ember.set(object, 'on_clipboard', true));
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "confirmationDialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sendPage", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "sendPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendQuery", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "sendQuery"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RelatedObjectsClipboardButtons);
});
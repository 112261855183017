define("plutof/components/annotation/unreviewed-annotations", ["exports", "@glimmer/component", "plutof/models/annotation/annotation"], function (_exports, _component, _annotation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @accessRights.canModify}}
      {{#if this.haveUnreviewedAnnotations}}
          <div  class="unreviewed-annotations">
              <button
                  {{on "click" this.scrollToAnnotations}}
                  class="btn-reset"
              >
                  <span class={{icon "annotation"}}></span>
                  {{i18n-t "annotation.unreviewedAnnotations"}}
              </button>
          </div>
      {{/if}}
  {{/if}}
  
  */
  {
    "id": "MnU6kQup",
    "block": "{\"symbols\":[\"@accessRights\"],\"statements\":[[6,[37,3],[[32,1,[\"canModify\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,0,[\"haveUnreviewedAnnotations\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"unreviewed-annotations\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn-reset\"],[4,[38,0],[\"click\",[32,0,[\"scrollToAnnotations\"]]],null],[12],[2,\"\\n                \"],[10,\"span\"],[15,0,[30,[36,1],[\"annotation\"],null]],[12],[13],[2,\"\\n                \"],[1,[30,[36,2],[\"annotation.unreviewedAnnotations\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"icon\",\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/annotation/unreviewed-annotations.hbs"
    }
  });
  let UnreviewedAnnotations = (_dec = Ember.computed('args.annotations.objects.@each.status'), _dec2 = Ember._action, _class = class UnreviewedAnnotations extends _component.default {
    get haveUnreviewedAnnotations() {
      return this.args.annotations.objects.some(ann => ann.status === _annotation.AnnotationStatus.BeingReviewed);
    }
    scrollToAnnotations() {
      const annotations = document.querySelector('[data-anchor=annotations]');
      if (annotations) {
        window.scrollTo({
          top: annotations.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "haveUnreviewedAnnotations", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "haveUnreviewedAnnotations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollToAnnotations", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToAnnotations"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UnreviewedAnnotations);
});
define("plutof/components/photobank/custom-classifications", ["exports", "@ember-decorators/component", "plutof/utils/model"], function (_exports, _component, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _dec2, _dec3, _dec4, _dec5, _class2, _class3, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let Classification = (_dec = Ember.computed('items.@each.custom_list_item', 'classification'), _class = class Classification extends Ember.Object {
    get selectedClassificationItems() {
      const classification = this.classification;
      return this.items.filterBy('custom_list_item.custom_classification', classification);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "selectedClassificationItems", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectedClassificationItems"), _class.prototype), _class);
  let CustomClassifications = (_dec2 = (0, _component.classNames)('photobank-custom-classifications'), _dec3 = Ember.inject.service, _dec4 = (0, _model.choices)('photobank.customlistitem.custom_classification'), _dec5 = Ember.computed.map('classificationTypes', function (type) {
    return Classification.create({
      name: type.display_name,
      classification: type.value,
      items: this.items
    });
  }), _dec2(_class2 = (_class3 = class CustomClassifications extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "object", null);
      _defineProperty(this, "items", null);
      _defineProperty(this, "objectsToDelete", []);
      _initializerDefineProperty(this, "classificationTypes", _descriptor2, this);
      _initializerDefineProperty(this, "classificationsData", _descriptor3, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class3.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "classificationTypes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "classificationsData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class3)) || _class2);
  var _default = _exports.default = CustomClassifications;
});
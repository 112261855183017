define("plutof/components/layer/general-data", ["exports", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Layer::GeneralData"
  >
      <GenericForms::Record @record={{@layer}} as |form|>
          <Layout::RegularGrid @columns={{2}}>
              {{form.bind "name" valid=this.validations.attrs.layer.name.isValid}}
              {{form.bind "description" valid=this.validations.attrs.layer.description.isValid}}
              {{form.bind "public_linking"}}
          </Layout::RegularGrid>
      </GenericForms::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "Tss2DWMp",
    "block": "{\"symbols\":[\"form\",\"@layer\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Layer::GeneralData\"]],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-forms/record\",[],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"bind\"]],\"expected `form.bind` to be a contextual component but found a string. Did you mean `(component form.bind)`? ('plutof/components/layer/general-data.hbs' @ L7:C14) \"],null],\"name\"],[[\"valid\"],[[32,0,[\"validations\",\"attrs\",\"layer\",\"name\",\"isValid\"]]]]]],[2,\"\\n            \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"bind\"]],\"expected `form.bind` to be a contextual component but found a string. Did you mean `(component form.bind)`? ('plutof/components/layer/general-data.hbs' @ L8:C14) \"],null],\"description\"],[[\"valid\"],[[32,0,[\"validations\",\"attrs\",\"layer\",\"description\",\"isValid\"]]]]]],[2,\"\\n            \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"bind\"]],\"expected `form.bind` to be a contextual component but found a string. Did you mean `(component form.bind)`? ('plutof/components/layer/general-data.hbs' @ L9:C14) \"],null],\"public_linking\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/components/layer/general-data.hbs"
    }
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'layer.name': (0, _emberCpValidations.validator)('presence', true),
    'layer.description': (0, _emberCpValidations.validator)('length', {
      max: 2048
    })
  });
  class LayerGeneralData extends Ember.Component.extend(Validations, _componentValidations.default) {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LayerGeneralData);
});
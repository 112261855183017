define("plutof/components/plutof-panel/item-list/header", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
      {{on "click" @toggle}}
      class="panel-title-container {{unless @open 'panel-title-container--collapsed'}}"
  >
      <h4 class="panel-title">
          {{@title}}&nbsp;
  
          {{#unless this.hideCount}}
              <span class="simplified-selection__count panel-title__badge {{if @invalid 'has-error'}}">
                  {{@count}}
              </span>
          {{/unless}}
      </h4>
  </div>
  
  <div class="panel-header-controls panel-header-controls--flat">
      <div class="plutof-btn__controls-container">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "Ri4i8UzP",
    "block": "{\"symbols\":[\"@invalid\",\"@count\",\"@open\",\"@toggle\",\"@title\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"panel-title-container \",[30,[36,1],[[32,3],\"panel-title-container--collapsed\"],null]]]],[4,[38,2],[\"click\",[32,4]],null],[12],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"panel-title\"],[12],[2,\"\\n        \"],[1,[32,5]],[2,\" \\n\\n\"],[6,[37,1],[[32,0,[\"hideCount\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[15,0,[31,[\"simplified-selection__count panel-title__badge \",[30,[36,0],[[32,1],\"has-error\"],null]]]],[12],[2,\"\\n                \"],[1,[32,2]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"panel-header-controls panel-header-controls--flat\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-btn__controls-container\"],[12],[2,\"\\n        \"],[18,6,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"unless\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/plutof-panel/item-list/header.hbs"
    }
  });
  let Header = (_dec = (0, _component.classNames)('panel-heading', 'simplified-selection__heading'), _dec2 = Ember.computed.none('count'), _dec(_class = (_class2 = class Header extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "hideCount", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "hideCount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class2)) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Header);
});
define("plutof/components/multiple-selectizer", ["exports", "@ember-decorators/object", "plutof/utils/notifications"], function (_exports, _object, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let MultiSelector = (_dec = (0, _object.observes)('selection'), _dec2 = Ember._action, _class = class MultiSelector extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "labelPath", 'display_name');
      _defineProperty(this, "valuePath", 'value');
    }
    init() {
      super.init(...arguments);
      this.selectionChanged();
    }
    selectionChanged() {
      const selection = this.selection;
      if (Ember.isNone(selection)) {
        return;
      }
      if (this.selected.includes(selection)) {
        (0, _notifications.displayNotification)('info', 'Already in the list');
        return;
      }
      let record = selection;
      const add = this.add;
      if (!Ember.isNone(add)) {
        record = add(selection);
      }
      if (record) {
        this.selected.pushObject(record);
      }

      // TODO: Why?
      // Probably this selection change is put into the current run-loop frame, but why
      // doesn't it trigger update?
      Ember.run.later(() => {
        this.set('selection', null);
      });
    }
    removeRecord(record) {
      const remove = this.remove;
      if (!Ember.isNone(remove)) {
        remove(record);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "selectionChanged", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectionChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRecord", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "removeRecord"), _class.prototype), _class);
  var _default = _exports.default = MultiSelector;
});
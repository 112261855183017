define("plutof/components/dmp/dataset-security-and-privacy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AddMultipleForm @objects={{@dataset.security_and_privacy}} as |adder|>
      <div class="add-component">
          <adder.column @path="dmp.datasetSecurityAndPrivacy.title"}}>
              <Input @type="text" @value={{this.newTitle}} @class="form-control control" />
          </adder.column>
  
          <adder.column @path="dmp.datasetSecurityAndPrivacy.description"}}>
              <Input @type="text" @value={{this.newDescription}} @class="form-control control" />
          </adder.column>
  
          <adder.addButton @add={{this.add}} @disable={{this.disableAddButton}} />
      </div>
  
      <Common::RecordList @records={{@dataset.security_and_privacy}} @remove={{adder.remove}} as |record|>
          {{record.title}} ({{record.description}})
      </Common::RecordList>
  </AddMultipleForm>
  
  */
  {
    "id": "p21M50z4",
    "block": "{\"symbols\":[\"adder\",\"record\",\"@dataset\"],\"statements\":[[8,\"add-multiple-form\",[],[[\"@objects\"],[[32,3,[\"security_and_privacy\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"add-component\"],[12],[2,\"\\n        \"],[8,[32,1,[\"column\"]],[[24,\"}}\",\"\"]],[[\"@path\"],[\"dmp.datasetSecurityAndPrivacy.title\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@class\"],[\"text\",[32,0,[\"newTitle\"]],\"form-control control\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"column\"]],[[24,\"}}\",\"\"]],[[\"@path\"],[\"dmp.datasetSecurityAndPrivacy.description\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@class\"],[\"text\",[32,0,[\"newDescription\"]],\"form-control control\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"addButton\"]],[],[[\"@add\",\"@disable\"],[[32,0,[\"add\"]],[32,0,[\"disableAddButton\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,3,[\"security_and_privacy\"]],[32,1,[\"remove\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"title\"]]],[2,\" (\"],[1,[32,2,[\"description\"]]],[2,\")\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/dmp/dataset-security-and-privacy.hbs"
    }
  });
  let DMPDatasetSecurityAndPrivacy = (_dec = Ember.computed('newTitle', 'newDescription'), _dec2 = Ember._action, _class = class DMPDatasetSecurityAndPrivacy extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "newTitle", '');
      _defineProperty(this, "newDescription", '');
    }
    get disableAddButton() {
      return Ember.isEmpty(this.newTitle.trim()) || Ember.isEmpty(this.newDescription.trim());
    }
    add() {
      this.dataset.security_and_privacy.pushObject({
        title: this.newTitle,
        description: this.newDescription
      });
      this.setProperties({
        newTitle: '',
        newDescription: ''
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "disableAddButton", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "disableAddButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DMPDatasetSecurityAndPrivacy);
});
define("plutof/models/determination/determination", ["exports", "ember-data", "@ember-data/model", "plutof/misc/abstract", "plutof/mixins/display-name-from-value", "plutof/models/plutof-model", "plutof/utils/cloning"], function (_exports, _emberData, _model, _abstract, _displayNameFromValue, _plutofModel, _cloning) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isBasisEnabled = isBasisEnabled;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Determination = (_dec = (0, _model.belongsTo)('contenttype'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.belongsTo)('taxonomy/taxonnode'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.belongsTo)('determination/typification'), _dec7 = (0, _model.attr)('enum'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('enum-multiple', {
    defaultValue: () => []
  }), _dec10 = (0, _model.attr)('boolean'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('enum-multiple', {
    jsonAPI: true,
    defaultValue: () => []
  }), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('boolean'), _dec15 = (0, _model.attr)('number', {
    defaultValue: 3
  }), _dec16 = (0, _displayNameFromValue.displayName)('assessment'), _dec17 = (0, _displayNameFromValue.displayName)('basis'), _dec18 = Ember.computed('id'), _dec19 = Ember.computed.reads('taxon_node.representation'), _class = class Determination extends _plutofModel.default.extend(_displayNameFromValue.DisplayNameMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "content_type", _descriptor, this);
      _initializerDefineProperty(this, "object_id", _descriptor2, this);
      _initializerDefineProperty(this, "taxon_node", _descriptor3, this);
      _initializerDefineProperty(this, "new_name", _descriptor4, this);
      _initializerDefineProperty(this, "remarks", _descriptor5, this);
      _initializerDefineProperty(this, "typification", _descriptor6, this);
      _initializerDefineProperty(this, "assessment", _descriptor7, this);
      _initializerDefineProperty(this, "determined_date", _descriptor8, this);
      _initializerDefineProperty(this, "basis", _descriptor9, this);
      _initializerDefineProperty(this, "is_current", _descriptor10, this);
      _initializerDefineProperty(this, "grex", _descriptor11, this);
      _initializerDefineProperty(this, "cultivar_groups", _descriptor12, this);
      _initializerDefineProperty(this, "owner_name", _descriptor13, this);
      _initializerDefineProperty(this, "is_deleted", _descriptor14, this);
      _initializerDefineProperty(this, "can_edit", _descriptor15, this);
      _defineProperty(this, "optionMixinModelName", 'determination.determination');
      _initializerDefineProperty(this, "assessmentDisplayName", _descriptor16, this);
      _initializerDefineProperty(this, "basisDisplayName", _descriptor17, this);
      _initializerDefineProperty(this, "representation", _descriptor18, this);
    }
    get determiners() {
      return this.getDeterminers();
    }
    getDeterminers() {
      if (Ember.isNone(this.id)) {
        return (0, _abstract.wrap_as_promise_array)([]);
      }
      return _emberData.default.PromiseArray.create({
        promise: this.store.query('determination/determiner', {
          determination: this.id
        }).then(function (determiners) {
          return determiners.toArray();
        })
      });
    }
    async cloneExtras(clone) {
      const determiners = await Ember.RSVP.Promise.resolve(this.determiners);
      const clonedDeterminers = await Ember.RSVP.all(determiners.map(det => {
        return (0, _cloning.clone_record)(this.store, det).then(detClone => {
          detClone.set('determination', clone);
          return detClone;
        });
      }));
      clone.set('determiners', _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.resolve(clonedDeterminers)
      }));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "content_type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "object_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "taxon_node", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "new_name", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "remarks", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "typification", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "assessment", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "determined_date", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "basis", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "is_current", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "grex", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "cultivar_groups", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "owner_name", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "is_deleted", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "can_edit", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "assessmentDisplayName", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "basisDisplayName", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "determiners", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "determiners"), _class.prototype), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  const BASIS_ENABLED_MODELS = new Set(['taxonoccurrence/specimen/specimen', 'taxonoccurrence/livingculture/strain', 'taxonoccurrence/observation/observation']);
  function isBasisEnabled(occurrence) {
    return BASIS_ENABLED_MODELS.has(occurrence.constructor.modelName);
  }
  var _default = _exports.default = Determination;
});
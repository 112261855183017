define("plutof/controllers/profile/groups/join", ["exports", "plutof/misc/profile_settings", "plutof/models/users/usergroup", "plutof/utils/notifications"], function (_exports, _profile_settings, _usergroup, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let JoinGroupController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _class = class JoinGroupController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "queryParams", ['group_id']);
      _defineProperty(this, "group_id", null);
      _initializerDefineProperty(this, "reasonDialogOpen", _descriptor2, this);
      _initializerDefineProperty(this, "reason", _descriptor3, this);
    }
    goBack() {
      this.router.transitionTo('profile.groups.index');
    }
    get reasonIsEmpty() {
      return this.reason.trim().length === 0;
    }
    askForReason() {
      this.reasonDialogOpen = true;
    }
    cancelReasonDialog() {
      this.reasonDialogOpen = false;
    }
    async join() {
      const membership = this.store.createRecord('users/usergroup', {
        group: this.model.group,
        user: await (0, _profile_settings.get_current_user)(this.store),
        // Those area not real, but need to send something to pass backend
        // validation
        status: _usergroup.UserStatus.ReadWrite,
        rights: _usergroup.UserRights.NeedsModerating,
        join_reason: this.reason
      });
      return membership.save().then(() => {
        (0, _notifications.displayNotification)('success', this.i18n.t('group.messages.joinRequestSent'));
        this.reason = '';
        this.goBack();
      }).catch(_notifications.reportError).finally(() => this.reasonDialogOpen = false);
    }
    cancel() {
      this.goBack();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "reasonDialogOpen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "reason", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "askForReason", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "askForReason"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelReasonDialog", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "cancelReasonDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "join", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "join"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _class);
  var _default = _exports.default = JoinGroupController;
});
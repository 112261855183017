define("plutof/routes/specimen/add", ["exports", "plutof/components/specimen/edit", "plutof/misc/profile_settings", "plutof/utils/exif", "plutof/utils/model", "plutof/utils/routes"], function (_exports, _edit, _profile_settings, _exif, _model, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SpecimenAddRoute = (_dec = Ember.inject.service, _dec2 = Ember._action, _class = class SpecimenAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "geocoding", _descriptor, this);
      _defineProperty(this, "defaultReturnRoute", 'specimen.index');
      _defineProperty(this, "paramsClearedOnReset", ['template_id']);
      _defineProperty(this, "title", 'Specimen.newS');
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      let specimenData;
      if (!(Ember.isNone(params) || Ember.isNone(params.template_id))) {
        const template = await this.store.findRecord('taxonoccurrence/specimen/specimen', params.template_id);
        specimenData = await (0, _edit.cloneSpecimenData)({
          store: this.store,
          template,
          cloneArea: params.clone_area === 'true',
          cloneEvent: params.clone_event === 'true'
        });
      } else {
        const samplingData = await (0, _model.getSamplingDataForParams)(this.store, params);
        const specimen = this.store.createRecord('taxonoccurrence/specimen/specimen', {
          substrate: this.store.createRecord('substrate/substrate', {}),
          related_materialsample: samplingData.materialsample
        });
        const settings = await (0, _profile_settings.get_personal_settings)(this.store);
        const mainform = await (Ember.isNone(params.mainform_id) ? settings.get('specimen_form') : this.store.findRecord('measurement/mainform', params.mainform_id));
        const collection = await (Ember.isNone(params.collection_id) ? settings.get('default_specimen_collection') : this.store.findRecord('agent/collection', params.collection_id));
        const project = await (Ember.isNone(params.project_id) ? settings.get('default_study') : this.store.findRecord('study/study', params.project_id));
        specimen.setProperties({
          mainform,
          deposited_in: collection,
          project: project
        });
        let files = [];
        if (params.files) {
          files = await Ember.RSVP.Promise.all(params.files.split(',').map(id => this.store.findRecord('filerepository/file', id)));
        }
        specimenData = await (0, _edit.createSpecimenData)({
          store: this.store,
          specimen,
          sampleData: (0, _exif.fillSampleFromEXIF)(this.store, this.geocoding, samplingData.formData, files)
        });
        for (const file of files) {
          specimenData.itemData.files.add({
            file
          });
        }
      }
      return {
        specimenData
      };
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.setProperties({
        routeHasBeenLoaded: true,
        closePanels: false
      });
      if (!Ember.isNone(model)) {
        controller.set('objectsToDelete', []);
      }
    }
    doRefresh() {
      this.set('controller.routeHasBeenLoaded', false);
      this.refresh();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "geocoding", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "doRefresh", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "doRefresh"), _class.prototype), _class);
  var _default = _exports.default = SpecimenAddRoute;
});
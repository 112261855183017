define("plutof/components/experiment/common/primer-tag", ["exports", "@glimmer/component", "plutof/components/auto-complete/backends", "plutof/utils/caching", "plutof/components/experiment/primers"], function (_exports, _component, _backends, _caching, _primers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AutoComplete::Base
      @backend={{this.autocomplete}}
      @value={{@value}}
      @disabled={{@disabled}}
      @params={{merge-params @params (hash
          inputClasses="mini-autocomplete-input"
          mini=true
      )}}
  >
      <:result as |result context|>
          {{hl-subs result context.query}}
      </:result>
  </AutoComplete::Base>
  
  */
  {
    "id": "e4MhMhJN",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"__arg2\",\"result\",\"context\",\"@value\",\"@disabled\",\"@params\"],\"statements\":[[8,\"auto-complete/base\",[],[[\"@backend\",\"@value\",\"@disabled\",\"@params\",\"@namedBlocksInfo\"],[[32,0,[\"autocomplete\"]],[32,6],[32,7],[30,[36,3],[[32,8],[30,[36,2],null,[[\"inputClasses\",\"mini\"],[\"mini-autocomplete-input\",true]]]],null],[30,[36,2],null,[[\"result\"],[2]]]]],[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1],\"result\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2],[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[[32,4],[32,5,[\"query\"]]],null]],[2,\"\\n    \"]],\"parameters\":[4,5]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hl-subs\",\"let\",\"hash\",\"merge-params\",\"-is-named-block-invocation\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/experiment/common/primer-tag.hbs"
    }
  });
  const getAutocomplete = (0, _caching.lazy)(async function (ajax) {
    const tagSets = await (0, _primers.getTagSets)(ajax);
    const tags = tagSets.reduce((tags, set) => tags.concat(set.tags), []);
    return function (query, pageSize = 20) {
      return tags.filter(tag => tag.startsWith(query || '')).slice(0, pageSize);
    };
  });

  // Not returned from getAutocomplete, because this way AC creation is not async
  class PrimerTagAutocomplete extends _backends.AutocompleteBackend {
    constructor(ajax) {
      super();
      this.ajax = ajax;
    }
    resolveResult(tag) {
      return Ember.RSVP.Promise.resolve(tag);
    }
    async autocomplete(query) {
      const autocomplete = await getAutocomplete(this.ajax);
      return autocomplete(query);
    }
    recordID(tag) {
      return tag;
    }
    recordValue(tag) {
      return tag;
    }
  }
  let PrimerTagInput = (_dec = Ember.inject.service, _class = class PrimerTagInput extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "autocomplete", new PrimerTagAutocomplete(this.ajax));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PrimerTagInput);
});
define("plutof/components/edit-determinations", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-cp-validations", "plutof/misc/content_types", "plutof/mixins/component-validations", "plutof/models/determination/determination", "plutof/utils/model", "plutof/utils/promises", "plutof/utils/store"], function (_exports, _component, _object, _emberCpValidations, _content_types, _componentValidations, _determination, _model, _promises, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DeterminationsData = _exports.DeterminationEntry = void 0;
  _exports.createDeterminationData = createDeterminationData;
  _exports.default = void 0;
  _exports.loadDeterminationData = loadDeterminationData;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _dec5, _class2, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class3, _class4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const EntryValidations = (0, _emberCpValidations.buildValidations)({
    'taxonExists': (0, _emberCpValidations.validator)('is-truthy'),
    'determination.new_name': (0, _emberCpValidations.validator)('length', {
      max: 255
    }),
    'determination.remarks': (0, _emberCpValidations.validator)('length', {
      max: 1024
    }),
    // XXX Not ideal.
    'dateValidity': (0, _emberCpValidations.validator)('is-truthy')
  });
  let DeterminationEntry = _exports.DeterminationEntry = (_dec = Ember.computed.gt('determination.can_edit', 1), _dec2 = Ember.computed.equal('determination.can_edit', 2), _dec3 = Ember.computed.notEmpty('determination.taxon_node.id'), _dec4 = Ember.computed.alias('validations.isValid'), _class = class DeterminationEntry extends Ember.Object.extend(EntryValidations) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "canEdit", _descriptor, this);
      _initializerDefineProperty(this, "partialEdit", _descriptor2, this);
      _initializerDefineProperty(this, "taxonExists", _descriptor3, this);
      _initializerDefineProperty(this, "isValid", _descriptor4, this);
    }
    init() {
      super.init(...arguments);
      this.set('dateValidity', true);
      this.set('removedDeterminers', []);
      this.set('taxonImportOpen', false);
      this.set('taxonImportTarget', null);
    }
    removeDeterminer(determiner) {
      this.removedDeterminers.pushObject(determiner);
      this.determiners.removeObject(determiner);
    }
    delete() {
      this.determination.deleteRecord();

      // Determination DELETE will remove determiners as well and if we leave them here
      // .save will try to double-detete them (in parallel with determination DELETE)
      this.removedDeterminers.clear();
      this.set('determiners', []);
    }
    save() {
      const determination = this.determination;
      return Ember.RSVP.all([determination.save(), Ember.RSVP.all(this.removedDeterminers.invoke('destroyRecord')).then(() => {
        this.set('removedDeterminers', []);
      })])
      // Determiner records are never modified, so can filter by isNew to prevent
      // no-op PUTs
      .then(() => Ember.RSVP.all(this.determiners.filterBy('isNew').invoke('save'))).then(() => determination);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "canEdit", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "partialEdit", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "taxonExists", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  let DeterminationsData = _exports.DeterminationsData = (_dec5 = Ember.computed('entries.@each.isValid'), _class2 = class DeterminationsData extends Ember.Object {
    init() {
      super.init(...arguments);
      this.set('entries', []);
      this.set('removedDeterminations', []);
    }
    get validity() {
      return this.entries.isEvery('isValid');
    }
    add() {
      const determination = this.store.createRecord('determination/determination', {
        content_type: this.targetRecordContentType,
        object_id: this.get('targetRecord.id')
      });
      this.addExisting(determination, []);
    }
    addExisting(determination, determiners, {
      toEnd = false
    } = {}) {
      const ownerInjection = Ember.getOwner(this.store).ownerInjection();
      const entry = DeterminationEntry.create(ownerInjection, {
        determination,
        determiners,
        fadeId: Math.random()
      });
      const entries = this.entries;
      if (toEnd) {
        entries.pushObject(entry);
      } else {
        entries.unshiftObject(entry);
      }
    }
    remove(entry) {
      entry.delete();
      this.entries.removeObject(entry);
      this.removedDeterminations.pushObject(entry);
    }
    save() {
      return Ember.RSVP.all(this.removedDeterminations.invoke('save')).then(() => {
        this.removedDeterminations.clear();
        const targetID = this.get('targetRecord.id');
        const entries = this.entries.filterBy('canEdit');

        // Not sure why, but I guess so that the active one would be the latest
        entries.reverse();

        // TODO: this is somewhat meh. Better would be to pass ctype/oid to entry.save, but
        // saveSequentially is too dumb. In any case, saving data for a new determinations
        // means that there is no point in passing ctype/oid to data/entry constructors
        entries.forEach(entry => entry.set('determination.object_id', targetID));
        return _promises.default.saveSequentially(entries);
      });
    }
    setRequireOne(requireOne, prefill = true) {
      if (this.requireOne === requireOne) {
        return;
      }
      this.set('requireOne', requireOne);
      if (requireOne) {
        if (prefill && this.entries.length === 0) {
          this.add();
        }
      } else {
        if (this.entries.length === 1 && !this.entries[0].isValid) {
          this.remove(this.entries[0]);
        }
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "validity", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "validity"), _class2.prototype), _class2); // XXX: Prefill is needed only when we're outside the usual flow, e.g. when cloning
  function createDeterminationData(store, targetRecord, {
    requireOne = true,
    prefill = true
  } = {}) {
    return (0, _content_types.get_object_ctype)(store, targetRecord).then(ctype => {
      const data = DeterminationsData.create({
        store,
        targetRecord,
        // XXX: Shouldn't be a propery, just calculate in data.save()
        targetRecordContentType: ctype
      });
      data.setRequireOne(requireOne, prefill);
      return data;
    });
  }
  async function loadDeterminationData(store, targetRecord, {
    requireOne = true
  } = {}) {
    const ctype = await (0, _content_types.get_object_ctype)(store, targetRecord);
    const data = DeterminationsData.create({
      store,
      targetRecord,
      targetRecordContentType: ctype
    });
    data.setRequireOne(requireOne, false);
    const determinations = await (0, _store.query)(store, 'determination/determination', {
      content_type: ctype.get('id'),
      object_id: targetRecord.get('id'),
      ordering: '-is_current,-id'
    });
    const withDeterminers = await Ember.RSVP.all(determinations.map(async determination => {
      const determiners = await (0, _store.query)(store, 'determination/determiner', {
        determination: determination.get('id')
      });
      return [determination, determiners];
    }));
    for (const [determination, determiners] of withDeterminers) {
      data.addExisting(determination, determiners, {
        toEnd: true
      });
    }
    return data;
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'data.validity': (0, _emberCpValidations.validator)('is-truthy'),
    'haveDeterminationIfRequired': (0, _emberCpValidations.validator)('is-truthy')
  });
  let DeterminationsComponent = (_dec6 = (0, _component.classNames)('edit-determinations'), _dec7 = (0, _model.choices)('determination.determination.assessment'), _dec8 = (0, _object.observes)('data.validity'), _dec9 = Ember.computed('data.requireOne', 'data.entries.[]'), _dec10 = Ember.computed('data.requireOne', 'data.entries.[]'), _dec11 = Ember.computed('data.targetRecord'), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec6(_class3 = (_class4 = class DeterminationsComponent extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "assessments", _descriptor5, this);
      _defineProperty(this, "data", null);
      _defineProperty(this, "validity", true);
      _defineProperty(this, "validationChildren", ['dateValidity']);
    }
    init() {
      super.init(...arguments);
      this.validityUpdater();
    }
    validityUpdater() {
      if (!Ember.isNone(this.get('attrs.validity'))) {
        this.get('attrs.validity').update(this.get('data.validity'));
      }
    }
    get disableRemove() {
      return this.get('data.requireOne') && this.get('data.entries.length') === 1;
    }
    get haveDeterminationIfRequired() {
      return !this.get('data.requireOne') || this.get('data.entries.length') > 0;
    }
    get enableBasis() {
      return (0, _determination.isBasisEnabled)(this.data.targetRecord);
    }
    addDetermination() {
      this.data.add();
    }
    removeDetermination(entry) {
      this.data.remove(entry);
    }
    openTaxonImport(determination) {
      this.set('taxonImportTarget', determination);
      this.set('taxonImportOpen', true);
    }
    closeTaxonImport() {
      this.set('taxonImportOpen', false);
    }
  }, _descriptor5 = _applyDecoratedDescriptor(_class4.prototype, "assessments", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class4.prototype, "validityUpdater", [_dec8], Object.getOwnPropertyDescriptor(_class4.prototype, "validityUpdater"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "disableRemove", [_dec9], Object.getOwnPropertyDescriptor(_class4.prototype, "disableRemove"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "haveDeterminationIfRequired", [_dec10], Object.getOwnPropertyDescriptor(_class4.prototype, "haveDeterminationIfRequired"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "enableBasis", [_dec11], Object.getOwnPropertyDescriptor(_class4.prototype, "enableBasis"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "addDetermination", [_dec12], Object.getOwnPropertyDescriptor(_class4.prototype, "addDetermination"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "removeDetermination", [_dec13], Object.getOwnPropertyDescriptor(_class4.prototype, "removeDetermination"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "openTaxonImport", [_dec14], Object.getOwnPropertyDescriptor(_class4.prototype, "openTaxonImport"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "closeTaxonImport", [_dec15], Object.getOwnPropertyDescriptor(_class4.prototype, "closeTaxonImport"), _class4.prototype), _class4)) || _class3);
  var _default = _exports.default = DeterminationsComponent;
});
define("plutof/components/common/data-quality/warning/timespan-after-created", ["exports", "plutof/utils/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToolTip
      @tooltip={{i18n-t
          'DataQuality.warnings.timespanAfterCreated'
          timespanBegin=this.formatteedTimespanBegin
          createdAt=this.formattedCreatedAt}}
      @class="plutof-tooltip-hover--inline"
  >
      <span class="{{icon 'event'}} data-quality-warning data-quality-warning--icon"></span>
  </ToolTip>
  
  */
  {
    "id": "Zu4IQF/X",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tool-tip\",[],[[\"@tooltip\",\"@class\"],[[30,[36,0],[\"DataQuality.warnings.timespanAfterCreated\"],[[\"timespanBegin\",\"createdAt\"],[[32,0,[\"formatteedTimespanBegin\"]],[32,0,[\"formattedCreatedAt\"]]]]],\"plutof-tooltip-hover--inline\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[\"event\"],null],\" data-quality-warning data-quality-warning--icon\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\"]}",
    "meta": {
      "moduleName": "plutof/components/common/data-quality/warning/timespan-after-created.hbs"
    }
  });
  let TimespanAfterCreated = _exports.default = (_dec = Ember.computed('timespanBegin'), _dec2 = Ember.computed('createdAt'), _class = class TimespanAfterCreated extends Ember.Component {
    get formatteedTimespanBegin() {
      return (0, _formatters.getFormattedAmbiguousDate)(this.timespanBegin);
    }
    get formattedCreatedAt() {
      return (0, _formatters.getFormattedAmbiguousDate)(this.createdAt);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "formatteedTimespanBegin", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "formatteedTimespanBegin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formattedCreatedAt", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "formattedCreatedAt"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TimespanAfterCreated);
});
define("plutof/components/history/diff", ["exports", "@glimmer/component", "moment"], function (_exports, _component, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isAddition}}
      {{i18n-t "history.diff.add"}}
  {{else if this.isRemoval}}
      {{i18n-t "history.diff.remove"}}
  {{else}}
      <Common::RecordListView @records={{this.entries}} as |entry|>
          {{entry.field}} <span class="fas fa-arrow-right"></span> {{entry.value}}
      </Common::RecordListView>
  {{/if}}
  
  */
  {
    "id": "XK4+wSJP",
    "block": "{\"symbols\":[\"entry\"],\"statements\":[[6,[37,1],[[32,0,[\"isAddition\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"history.diff.add\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"isRemoval\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"history.diff.remove\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"common/record-list-view\",[],[[\"@records\"],[[32,0,[\"entries\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"field\"]]],[2,\" \"],[10,\"span\"],[14,0,\"fas fa-arrow-right\"],[12],[13],[2,\" \"],[1,[32,1,[\"value\"]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/history/diff.hbs"
    }
  });
  const DATETIME = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/;
  let HistoryDiff = (_dec = Ember.computed.equal('args.change.type', 'add'), _dec2 = Ember.computed.equal('args.change.type', 'remove'), _class = class HistoryDiff extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "isAddition", _descriptor, this);
      _initializerDefineProperty(this, "isRemoval", _descriptor2, this);
    }
    get entries() {
      return Object.entries(this.args.change.diff).map(([field, value]) => {
        if (DATETIME.test(value)) {
          value = moment(value).format('YYYY-MM-DD HH:mm');
        }
        return {
          field,
          value
        };
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isAddition", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isRemoval", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HistoryDiff);
});
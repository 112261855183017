define("plutof/controllers/collection/view", ["exports", "plutof/models/agent/collection", "plutof/utils/pagination"], function (_exports, _collection, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CollectionViewController = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.computed.equal('model.collection.organization_type', _collection.CollectionType.DNACollection), _dec3 = Ember.computed.notEmpty('model.collection.managing_group.id'), _dec4 = Ember.computed.and('isDNACollection', 'hasManagingGroup'), _dec5 = Ember.computed('model.collection.id'), _dec6 = Ember.computed('model.collection.id'), _dec7 = Ember.computed('model.collection.id'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _class = class CollectionViewController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "isDNACollection", _descriptor2, this);
      _initializerDefineProperty(this, "hasManagingGroup", _descriptor3, this);
      _initializerDefineProperty(this, "showDNAs", _descriptor4, this);
    }
    get relatedOccurrencesQuery() {
      return {
        deposited_in: this.model.collection.id
      };
    }
    get relatedOccurrencesClipboardQuery() {
      return {
        dynamic_object_list: `deposited_in=${this.model.collection.id}`
      };
    }
    get canAddOccurrences() {
      return this.model.accessRights.canModify || this.model.userIsGroupMember;
    }
    get identifierBlocks() {
      return (0, _pagination.paginateModel)(this.store, 'agent/reserved-identifier-block', {
        collection: this.model.collection.id
      });
    }
    add(route) {
      return this.router.transitionTo(route, {
        queryParams: {
          collection_id: this.model.collection.id
        }
      });
    }
    addSpecimen() {
      return this.add('specimen.add');
    }
    addMaterialSample() {
      return this.add('materialsample.add');
    }
    addLivingSpecimen() {
      return this.add('livingspecimen.add');
    }
    addPhotobankObject() {
      return this.add('photobank.add');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDNACollection", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasManagingGroup", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showDNAs", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "relatedOccurrencesQuery", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "relatedOccurrencesQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "relatedOccurrencesClipboardQuery", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "relatedOccurrencesClipboardQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "identifierBlocks", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "identifierBlocks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addSpecimen", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "addSpecimen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addMaterialSample", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "addMaterialSample"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addLivingSpecimen", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "addLivingSpecimen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addPhotobankObject", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "addPhotobankObject"), _class.prototype), _class);
});
define("plutof/components/tables/table-header", ["exports", "@glimmer/component", "plutof/utils/search"], function (_exports, _component, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let TableHeader = (_dec = Ember._action, _class = class TableHeader extends _component.default {
    _sortBy() {
      Ember.run.debounce(this, this.args.sortBy, _search.SEARCH_DELAY, true);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_sortBy", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_sortBy"), _class.prototype), _class);
  var _default = _exports.default = TableHeader;
});
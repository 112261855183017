define("plutof/controllers/photobank/add", ["exports", "plutof/components/occurrences-table", "plutof/controllers/photobank/edit", "plutof/mixins/add-controller", "plutof/utils/access", "plutof/utils/notifications"], function (_exports, _occurrencesTable, _edit, _addController, _access, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PhotobankAddController = (_dec = Ember.inject.service, _dec2 = Ember._action, _class = class PhotobankAddController extends _edit.default.extend(_addController.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "listRoutes", _descriptor, this);
      _defineProperty(this, "queryParams", ['file_ids', 'area_id', 'event_id', 'project_id', 'template_id', 'clone_area', 'collection_id']);
      _defineProperty(this, "file_ids", null);
      _defineProperty(this, "area_id", null);
      _defineProperty(this, "event_id", null);
      _defineProperty(this, "project_id", null);
      _defineProperty(this, "template_id", null);
      _defineProperty(this, "clone_area", null);
      _defineProperty(this, "collection_id", null);
      _defineProperty(this, "allowAreaReset", true);
      _defineProperty(this, "showObservationForm", true);
      _defineProperty(this, "showAreaEditButton", false);
      _defineProperty(this, "cloningRules", {
        occurrence: {
          included: ['mainform'],
          excluded: ['individual_count', 'remarks']
        },
        entry: {
          included: ['mainform', 'taxon'],
          excluded: []
        },
        measurements: {
          included: [],
          excluded: []
        }
      });
    }
    async saveObject() {
      await super.saveObject(...arguments);
      const accessRights = await (0, _access.createDerivedRecordRights)(this.store, this.model.object, {
        collection: await this.model.object.deposited_in
      });
      this.model.observationData.occurrences.forEach(observation => {
        this.model.eventFormData.apply(observation);
      });
      const observationsProgress = this.model.observationData.save(accessRights);
      (0, _occurrencesTable.notifySaveProgress)(observationsProgress, 'Observations.saveProgress');
      this.listRoutes.reset('photobank/collectionobject');
      return observationsProgress.completionPromise;
    }
    clone(cloneArea) {
      this.set('closePanels', true);
      return this._save().then(() => {
        this.set('template_id', this.get('model.object.id'));
        this.set('clone_area', cloneArea);
        Ember.run.later(() => {
          this.send('doRefresh');
        });
      }).catch(_notifications.reportError);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "listRoutes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clone", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "clone"), _class.prototype), _class);
  var _default = _exports.default = PhotobankAddController;
});
define("plutof/components/load-page", ["exports", "@ember-decorators/object", "plutof/components/load-more", "plutof/misc/abstract"], function (_exports, _object, _loadMore, _abstract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let LoadPage = (_dec = (0, _object.observes)('query'), _dec2 = Ember.computed('records.[]', 'isLoading', 'count'), _dec3 = Ember.computed.or('isFirstPage', 'isLoading'), _dec4 = Ember.computed.or('isLastPage', 'isLoading'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _class = class LoadPage extends _loadMore.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "centered", false);
      _defineProperty(this, "loadPageComponent", true);
      // Load and display one page at the time.
      _defineProperty(this, "initialPage", 1);
      _initializerDefineProperty(this, "onlyForwards", _descriptor, this);
      _initializerDefineProperty(this, "onlyBackwards", _descriptor2, this);
    }
    init() {
      super.init(...arguments);
      this.queryChanged();
    }
    queryChanged() {
      const query = this.query;
      if (Ember.isEmpty(query)) {
        return _abstract.EMPTY_PROMISE;
      }
      return super.queryChanged().then(() => {
        if (!this.isDestroyed) {
          if (this.initialPage <= this.pageCount) {
            this.loadPage(this.initialPage);
          }
        }
      });
    }
    loadPage(pageNumber) {
      var queryObject = this.queryObject;
      queryObject.set('args.page', pageNumber);
      this.set('isLoading', true);
      return this.getPage(queryObject).then(function (response) {
        if (this.isDestroyed) {
          queryObject.set('args.page', 0);
          return;
        }
        this.set('isLoading', false);
        const records = response.toArray();
        this.set('records', records);
        this.set('loadedRecordsCount', records.length);
        if (this.pageSwitched) {
          this.pageSwitched(pageNumber, records);
        }
      }.bind(this));
    }
    get showControls() {
      const count = this.count;
      const recordCount = this.get('records.length');
      if (Ember.isNone(count)) {
        return false;
      }
      return this.isLoading || recordCount > 0 && count !== recordCount;
    }
    firstPage() {
      if (this.isLoading) {
        return;
      }
      this.loadPage(1);
    }
    previousPage() {
      if (this.isLoading) {
        return;
      }
      var pageNumber = this.pageNumber;
      if (pageNumber - 1 >= 1) {
        this.loadPage(pageNumber - 1);
      }
    }
    nextPage() {
      if (this.isLoading) {
        return;
      }
      var pageNumber = this.pageNumber;
      if (pageNumber < this.pageCount) {
        this.loadPage(pageNumber + 1);
      }
    }
    lastPage() {
      if (this.isLoading) {
        return;
      }
      this.loadPage(this.pageCount);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "queryChanged", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "queryChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showControls", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "showControls"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "onlyForwards", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onlyBackwards", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "firstPage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "firstPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previousPage", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "previousPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextPage", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "nextPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lastPage", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "lastPage"), _class.prototype), _class);
  var _default = _exports.default = LoadPage;
});
define("plutof/components/clipboard/bulk-update-multi-select-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let BulkUpdateMultiSelectList = (_dec = Ember._action, _class = class BulkUpdateMultiSelectList extends Ember.Component {
    remove(container, choice) {
      container.removeObject(choice);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "remove", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  var _default = _exports.default = BulkUpdateMultiSelectList;
});
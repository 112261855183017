define("plutof/components/import/edit-rows", ["exports", "@ember-decorators/object", "plutof/components/import/utils", "plutof/misc/config", "plutof/utils/clipboard", "plutof/utils/objects"], function (_exports, _object, _utils, _config, _clipboard, _objects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ImportTable = void 0;
  _exports.createImportTable = createImportTable;
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _dec7, _class2, _dec8, _dec9, _dec10, _class3, _descriptor, _descriptor2, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class4, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const INT_TYPE_REGEX = /^-?\d+$/;
  const FLOAT_TYPE_REGEX = /^-?\d+(\.\d+)?$/;
  let ImportCell = (_dec = Ember.computed('field.type', 'field.regex', 'value'), _dec2 = Ember.computed('field.isModelField', 'value', 'relation', 'relation.[]'), _dec3 = Ember.computed('isValid', 'field.isModelField', 'isEmpty'), _dec4 = Ember.computed('field.required', 'isEmpty', 'isTypeConformant'), _dec5 = (0, _object.observes)('multipleDropdownSelection.@each.representation'), _dec6 = (0, _object.observes)('multipleDropdownChoicesSelection.[]'), _class = class ImportCell extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "field", null);
      _defineProperty(this, "value", '');
    }
    init() {
      super.init(...arguments);
      this.set('multipleDropdownSelection', []);
      this.set('multipleDropdownChoicesSelection', []);
    }
    get isTypeConformant() {
      const value = this.value;
      if (!Ember.isPresent(value)) {
        return true;
      }
      const type = this.get('field.type');
      const formats = this.formats;
      const regex = this.get('field.regex');
      switch (type) {
        case 'integer':
          return INT_TYPE_REGEX.test(value);
        case 'float':
          return FLOAT_TYPE_REGEX.test(value);
        case 'boolean':
          // TODO: Booleans should be checkboxes
          return value === 'true' || value === 'false';
        case 'date':
          return formats.date.check(value);
        case 'datetime':
          return formats.datetime.check(value);
        case 'latitude':
          return formats.coordinates.latRegex.test(value);
        case 'longitude':
          return formats.coordinates.lonRegex.test(value);
        default:
          return Ember.isNone(regex) || regex.test(value);
      }
    }
    get isEmpty() {
      const value = this.get('field.isModelField') ? this.relation : this.value;
      return Ember.isEmpty(value);
    }
    get isFixed() {
      return this.isValid && !(this.get('field.isModelField') && this.isEmpty);
    }
    get isValid() {
      return this.isTypeConformant && !(this.get('field.required') && this.isEmpty);
    }
    multipleDropdownSelectionChanged() {
      const selection = this.multipleDropdownSelection;
      if (Ember.isNone(selection)) {
        return;
      }
      const values = selection.mapBy('representation');
      this.set('value', values.join(_config.default.Import.HASMANY_SEPARATOR));
    }

    // Need a different one becaus can no longer use @each.* for arrays of primitives
    multipleDropdownChoicesSelectionChanged() {
      const selection = this.multipleDropdownChoicesSelection.mapBy('value');
      this.set('value', selection.join(_config.default.Import.HASMANY_SEPARATOR));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isTypeConformant", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isTypeConformant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isEmpty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isFixed", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isFixed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "multipleDropdownSelectionChanged", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "multipleDropdownSelectionChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "multipleDropdownChoicesSelectionChanged", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "multipleDropdownChoicesSelectionChanged"), _class.prototype), _class);
  let ImportRow = (_dec7 = Ember.computed('cells.@each.isValid'), _class2 = class ImportRow extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "number", null);
      _defineProperty(this, "cells", []);
    }
    get isValid() {
      return this.cells.filterBy('previously_correct', false).isEvery('isValid');
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "isValid", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "isValid"), _class2.prototype), _class2);
  let ImportTable = _exports.ImportTable = (_dec8 = Ember.computed.equal('rows.length', 0), _dec9 = Ember.computed('rows.@each.isValid'), _dec10 = Ember.computed.not('isValid'), _class3 = class ImportTable extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "fields", []);
      _defineProperty(this, "rows", []);
      _initializerDefineProperty(this, "isEmpty", _descriptor, this);
      _initializerDefineProperty(this, "isInvalid", _descriptor2, this);
    }
    serializeRows() {
      return this.rows.map(function (row) {
        return {
          number: row.get('number'),
          values: row.get('cells').mapBy('value')
        };
      });
    }
    // TODO: In all fairness, field should not be needed here and is only used
    // to optimize the multi-row substitution on client
    addNameResolution(model, name, record, field) {
      let resolutions = this.nameResolutions;

      // Non-filtered relation fields share resolutions
      const filters = field.get('filters');
      const global = Ember.isNone(filters) || Object.keys(filters).length === 0;
      const segment = field.get('scope');
      (0, _objects.ensurePathExists)(resolutions, [segment, model]);
      resolutions[segment][model][name] = record.get('id');
      this.rows.forEach(row => {
        const affectedCells = row.get('cells').filter(cell => {
          if (global) {
            // Should be scope-based but we don't expose actual resolution scope to frontend, so this
            // will do
            const same_filters = (0, _objects.objects_are_equal)(cell.get('field.filters'), field.get('filters'));
            const fieldModel = cell.get('field.model');
            const shortModel = Ember.isNone(fieldModel) ? fieldModel : (0, _utils.importModel)(fieldModel);
            return shortModel === model && same_filters;
          } else {
            return cell.get('field') === field;
          }
        });
        affectedCells.forEach(cell => {
          if (cell.get('field.isRelation') || cell.get('field.isDropdown')) {
            if (cell.get('field.multiple')) {
              const separator = _config.default.Import.HASMANY_SEPARATOR;
              const pvalues = cell.get('previous_value').split(separator).map(pv => pv.trim());
              const selection = cell.get('relation');
              if (pvalues.length > selection.length) {
                if (pvalues[selection.length] === name) {
                  selection.pushObject(record);
                  cell.get('selectionNames').pushObject(name);
                  cell.set('value', cell.get('selectionNames').join(_config.default.Import.HASMANY_SEPARATOR));
                }
              }
            } else if (cell.get('previous_value') === name && cell.get('relation') !== record) {
              cell.set('relation', record);
            }
          }
        });
      });
      this.notifyPropertyChange('nameResolutions');
    }
    addTranslation(field, value, translated) {
      const attribute = field.get('full_id');
      let translations = this.translations;
      if (!(attribute in translations)) {
        translations[attribute] = {};
      }
      translations[attribute][value] = translated;

      // Propagate
      this.rows.forEach(row => {
        row.get('cells').filterBy('field', field).forEach(cell => {
          if (cell.get('previous_value') === value) {
            cell.set('value', translated);
          }
        });
      });
    }
    get isValid() {
      return this.rows.isEvery('isValid');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class3.prototype, "isEmpty", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "isValid", [_dec9], Object.getOwnPropertyDescriptor(_class3.prototype, "isValid"), _class3.prototype), _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "isInvalid", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class3); // @tree: subtree-selector/TreeNode
  // @values: array of arrays of string values
  //
  // XXX: Currently nameResolutions is shared with everyone. Maybe it should
  // be just initialized from the "global" one, then the controller would
  // merge new resolutions into global. TODO
  //
  // XXX: store is used only to generate dropdown content lists. Better was is to allow to
  // set model & filters on select component
  function createImportTable(store, fields, valueRows, nameResolutions, translations, formats) {
    if (Ember.isNone(nameResolutions)) {
      nameResolutions = {};
    }
    const rows = valueRows.map(function (valueRow) {
      const cells = valueRow.fields.map(function (rowField, column) {
        const field = fields[column];

        // Needed for required check to work. For dropdown, value can be non-empty, but unless
        // it's in the option array we need to consider it not filled

        const correct = Ember.isEmpty(rowField.errors);
        const type = field.get('type');
        let value = rowField.value;
        if (!correct) {
          if (type === 'dropdown') {
            value = '';
          } else if (type === 'choices') {
            // Field can be marked as incorrect, but have a correct value because of
            // model-level checks (e.g. sequnce parent: if specimen with given name
            // was not found, both name and parent.type fields will be marked invalid)
            const choices = field.get('choices').mapBy('value');
            if (!choices.includes(value)) {
              // Not all choices have ''-mapped choice (e.g. preparation type)
              value = choices[0];
            }
          }
        }
        return ImportCell.create({
          store: store,
          field: field,
          formats: formats,
          value: value,
          relation: field.get('multiple') ? [] : null,
          // This is kinda hackish: used for dealing with partial fixing of hasmany field. While
          // usual relations are fixed just by adding resolution and leaving the initial value
          // intact, has-many ones need to change values. For example, if the source file lists
          // foo and bar as determiners, and user just sets foo=baz during fixing, it must still
          // be a valid fix
          selectionNames: [],
          previous_value: rowField.value,
          previous_errors: rowField.errors,
          previously_correct: rowField.correct
        });
      });
      return ImportRow.create({
        number: valueRow.number,
        cells: cells
      });
    });
    return ImportTable.create({
      fields: fields,
      rows: rows,
      nameResolutions: nameResolutions,
      translations: translations
    });
  }
  let EditRows = (_dec11 = Ember.inject.service(), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _class4 = class EditRows extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _defineProperty(this, "table", null);
    }
    selectValueChanged(cell, selection) {
      if (Ember.isNone(selection)) {
        return;
      }

      // XXX XXX XXX
      // PROBABLY ALSO THE SAME BUG IN AUTOCOMPLETE HANDLING
      // If the invalid value is empty, we can't add an override!

      cell.set('value', selection.get('representation'));
      const model = (0, _utils.importModel)(selection.constructor.modelName);
      if (Ember.isPresent(cell.get('previous_value'))) {
        this.table.addNameResolution(model, cell.get('previous_value'), selection, cell.get('field'));
      }
    }
    selectChoice(cell, choice) {
      if (!choice) {
        cell.set('value', '');
        return;
      }
      cell.set('value', choice.value);
      this.table.addTranslation(cell.get('field'), cell.get('previous_value'), choice.value);
    }
    clearCell(cell) {
      cell.set('value', '');
      cell.set('previous_value', '');
      cell.set('previously_correct', true);
    }
    _removeRow(row) {
      this.get('table.rows').removeObject(row);
      this.removeRow(row.number);
    }
    openFieldAddRoute(field) {
      const route = field.get('addRoute');
      if (!Ember.isEmpty(route)) {
        const url = route === 'taxonomy.add' ? '/taxonomy/trees?tree_id=1' : this.router.urlFor(route);
        window.open(url);
      }
    }
    copyToClipboard(value) {
      (0, _clipboard.sendToClipboard)(value);
    }

    // XXX XXX XXX
    // Hack before the select component zoo problem is solved
    restoreChoice(field, value) {
      return field.get('choices').findBy('value', value);
    }
  }, _descriptor3 = _applyDecoratedDescriptor(_class4.prototype, "router", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class4.prototype, "selectValueChanged", [_dec12], Object.getOwnPropertyDescriptor(_class4.prototype, "selectValueChanged"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "selectChoice", [_dec13], Object.getOwnPropertyDescriptor(_class4.prototype, "selectChoice"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "clearCell", [_dec14], Object.getOwnPropertyDescriptor(_class4.prototype, "clearCell"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "_removeRow", [_dec15], Object.getOwnPropertyDescriptor(_class4.prototype, "_removeRow"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "openFieldAddRoute", [_dec16], Object.getOwnPropertyDescriptor(_class4.prototype, "openFieldAddRoute"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "copyToClipboard", [_dec17], Object.getOwnPropertyDescriptor(_class4.prototype, "copyToClipboard"), _class4.prototype), _applyDecoratedDescriptor(_class4.prototype, "restoreChoice", [_dec18], Object.getOwnPropertyDescriptor(_class4.prototype, "restoreChoice"), _class4.prototype), _class4);
  var _default = _exports.default = EditRows;
});
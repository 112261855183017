define("plutof/components/clipboard/darwin-core-export-form", ["exports", "@ember-decorators/component", "@ember-decorators/object", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _object, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.simpleValidator = simpleValidator;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // XXX End me.
  function simpleValidator(valuePath, requiredPath) {
    return Ember.computed(`${valuePath}.[]`, requiredPath, function () {
      return !this.get(requiredPath) || !Ember.isEmpty(this.get(valuePath));
    }); // Interesting because value isn't always an array.
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'titleValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'abstractValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'languageValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'pubDateValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'creatorsValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'contactsValidity': (0, _emberCpValidations.validator)('is-truthy'),
    'metadataProvidersValidity': (0, _emberCpValidations.validator)('is-truthy')
  });
  let DarwinCoreExportForm = (_dec = (0, _component.classNames)('darwin-core-export-form'), _dec2 = simpleValidator('data.title', 'options.title.required'), _dec3 = simpleValidator('data.abstract', 'options.abstract.required'), _dec4 = simpleValidator('data.language', 'options.language.required'), _dec5 = simpleValidator('data.creators', 'options.creators.required'), _dec6 = simpleValidator('data.contacts', 'options.contacts.required'), _dec7 = simpleValidator('data.metadata_providers', 'options.metadata_providers.required'), _dec8 = Ember.computed('data.pub_date', 'options.pub_date', 'pubDatePickerValidity'), _dec9 = (0, _object.observes)('validations.isValid'), _dec10 = Ember._action, _dec11 = Ember._action, _dec(_class = (_class2 = class DarwinCoreExportForm extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "options", null);
      _defineProperty(this, "data", null);
      _defineProperty(this, "pubDatePickerValidity", false);
      _initializerDefineProperty(this, "titleValidity", _descriptor, this);
      _initializerDefineProperty(this, "abstractValidity", _descriptor2, this);
      _initializerDefineProperty(this, "languageValidity", _descriptor3, this);
      _initializerDefineProperty(this, "creatorsValidity", _descriptor4, this);
      _initializerDefineProperty(this, "contactsValidity", _descriptor5, this);
      _initializerDefineProperty(this, "metadataProvidersValidity", _descriptor6, this);
    }
    get pubDateValidity() {
      const required = this.get('options.pub_date');
      const datePickerValidity = this.pubDatePickerValidity;
      return !required || !Ember.isEmpty(this.get('data.pub_date')) && datePickerValidity;
    }

    // ...
    updateValidity() {
      this.validated(this.get('validations.isValid'));
    }
    addPerson(key) {
      this.get(`data.${key}`).pushObject({
        person: null,
        email: null
      });
    }
    removePerson(person, key) {
      this.get(`data.${key}`).removeObject(person);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "titleValidity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "abstractValidity", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "languageValidity", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "creatorsValidity", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "contactsValidity", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "metadataProvidersValidity", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "pubDateValidity", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "pubDateValidity"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateValidity", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "updateValidity"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addPerson", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "addPerson"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removePerson", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "removePerson"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = DarwinCoreExportForm;
});
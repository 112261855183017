define("plutof/routes/datacite/alignment", ["exports", "plutof/mixins/clear-ui-route", "plutof/mixins/route", "plutof/utils/pagination", "plutof/utils/structures"], function (_exports, _clearUiRoute, _route, _pagination, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const thresholds = ['<0.5', '0.5', '1.0', '1.5', '2.0', '2.5', '3.0'];
  function loadClusterSequence(cseq) {
    const seq = cseq.sequence;
    if (cseq.reference_sequence) {
      seq.is_reference = true;
    }
    const blocks = cseq.colours.map(color => ({
      dsh_id: color.dsh_id,
      sh_code: color.cluster_name,
      distance: thresholds[color.ordering - 1],
      hex_colour: '#' + color.dshcolour,
      ordering: color.ordering,
      styles: Ember.String.htmlSafe(seq.is_reference ? `background: repeating-linear-gradient( 45deg, #${color.dshcolour}, 4px, white 5px); border-radius:25px;` : `background-color: #${color.dshcolour};`)
    }));
    return {
      id: seq.id,
      sequence: seq,
      area_name: Ember.isNone(seq.area) || seq.area.name === 'Unspecified' ? '' : seq.area.name,
      dna_source: seq.seq_source,
      insd_taxon_name: Ember.isNone(seq.insd_taxon_node) ? '' : seq.insd_taxon_node.name,
      unite_taxon_name: Ember.isNone(seq.taxon_node) ? '' : seq.taxon_node.name,
      country_name: Ember.isNone(seq.country) ? '' : seq.country.name,
      its_sequence: cseq.aligned_sequence,
      ordering: cseq.cheat_combined_order,
      accnos: seq.identifiers.map(i => ({
        accno: i.id_in_database,
        database: i.database
      })),
      sh_colours: (0, _structures.reversed)(blocks.sortBy('ordering'))
    };
  }
  const ClusterSequencesPagination = Ember.Mixin.create({
    preprocessPage(data) {
      return Ember.RSVP.Promise.resolve(data.map(loadClusterSequence));
    }
  });
  let DataciteSHAlignmentRoute = (_dec = Ember.inject.service, _class = class DataciteSHAlignmentRoute extends Ember.Route.extend(_clearUiRoute.default, _route.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "title", 'UniteSH.alignmentView');
    }
    loadSequences(clusterID) {
      const filters = {
        dshcluster: clusterID
      };
      const mixins = [_pagination.AccumulatingPaginationMixin, ClusterSequencesPagination];
      return (0, _pagination.paginateAjaxEndpoint)(this.ajax, 'globalkey/dshclustersequencestables/sequence_ordering', {
        filters,
        mixins,
        countEndpoint: 'globalkey/dshclustersequencestables/sequence_ordering_count/'
      });
    }
    model(params) {
      return Ember.RSVP.hash({
        sequencePagination: this.loadSequences(params.cluster_id)
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = DataciteSHAlignmentRoute;
});
define("plutof/components/person/person-view-general", ["exports", "@glimmer/component", "plutof/utils/formatters", "plutof/utils/model"], function (_exports, _component, _formatters, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PersonViewGeneralComponent = (_dec = Ember.computed('args.person.orcid_identifier'), _dec2 = Ember.computed('args.person.valid_agent.id', 'args.person.id'), _class = class PersonViewGeneralComponent extends _component.default {
    get orcidUrlValidity() {
      return _formatters.ORCID_URL_REGEX.test(this.args.person.orcid_identifier);
    }
    get isSynonym() {
      const person = this.args.person;
      const validAgentID = (0, _model.relationID)(person, 'valid_agent');
      return validAgentID && person.id !== validAgentID;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "orcidUrlValidity", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "orcidUrlValidity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSynonym", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isSynonym"), _class.prototype), _class);
  var _default = _exports.default = PersonViewGeneralComponent;
});
define("plutof/components/search/top-results", ["exports", "@glimmer/component", "plutof/utils/chart", "plutof/utils/search"], function (_exports, _component, _chart, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadTopResults = loadTopResults;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="spacer-above top-results-container">
      {{#resolve-promise @topResults as |topResults loaded isLoading|}}
          {{#if (or isLoading (not @canLoadTops))}}
              <Layout::RegularGrid @columns={{1}} @class="spacer-above top-results-container">
                  <Search::TopResults::Info
                      @canLoad={{@canLoadTops}}
                      @isLoading={{isLoading}} />
              </Layout::RegularGrid>
          {{else if loaded}}
              <Layout::RegularGrid @columns={{4}} @class="spacer-above top-results-container">
                  {{#each topResults as |top|}}
                      {{#unless top.isEmpty}}
                          <Search::TopResults::Top
                              @top={{top}}
                              @addRecordFilter={{this._addRecordFilter}} />
                      {{/unless}}
                  {{/each}}
              </Layout::RegularGrid>
          {{/if}}
      {{/resolve-promise}}
  </div>
  
  */
  {
    "id": "SeU1Nw7J",
    "block": "{\"symbols\":[\"topResults\",\"loaded\",\"isLoading\",\"top\",\"@canLoadTops\",\"@topResults\"],\"statements\":[[10,\"div\"],[14,0,\"spacer-above top-results-container\"],[12],[2,\"\\n\"],[6,[37,6],[[32,6]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,5],[[32,3],[30,[36,4],[[32,5]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"layout/regular-grid\",[],[[\"@columns\",\"@class\"],[1,\"spacer-above top-results-container\"]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"search/top-results/info\",[],[[\"@canLoad\",\"@isLoading\"],[[32,5],[32,3]]],null],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"layout/regular-grid\",[],[[\"@columns\",\"@class\"],[4,\"spacer-above top-results-container\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,4,[\"isEmpty\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                        \"],[8,\"search/top-results/top\",[],[[\"@top\",\"@addRecordFilter\"],[[32,4],[32,0,[\"_addRecordFilter\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[4]}]]],[2,\"            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"-track-array\",\"each\",\"if\",\"not\",\"or\",\"resolve-promise\"]}",
    "meta": {
      "moduleName": "plutof/components/search/top-results.hbs"
    }
  });
  async function loadTopResults(ajax, searchModule, query) {
    return (await Ember.RSVP.Promise.all(searchModule.topResults.map(async field => {
      const chart = await (0, _chart.getChartData)(ajax, searchModule.value + _search.TOP_RESULTS_ENDPOINT, [field.id], query);
      if (!chart[field.id]) {
        return null;
      }
      const data = chart[field.id].data;
      return {
        field: field,
        less: data.slice(0, _search.TOP_RESULTS_READ_MORE_LIMITER),
        more: data.slice(_search.TOP_RESULTS_READ_MORE_LIMITER),
        isEmpty: Ember.isEmpty(data)
      };
    }))).compact();
  }
  let TopResults = (_dec = Ember._action, _class = class TopResults extends _component.default {
    async _addRecordFilter(field, result) {
      // Perhaps validate something here? Hmm?
      const value = await field.filterValue(this.store, result);
      this.args.addRecordFilterWithValue(field.id, value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_addRecordFilter", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_addRecordFilter"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TopResults);
});
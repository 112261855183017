define("plutof/controllers/step-one-register", ["exports", "@ember-decorators/object", "ember-cp-validations", "plutof/utils/formatters", "plutof/utils/notifications", "plutof/utils/promises"], function (_exports, _object, _emberCpValidations, _formatters, _notifications, _promises) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "EMAIL_REGEX", {
    enumerable: true,
    get: function () {
      return _formatters.EMAIL_REGEX;
    }
  });
  _exports.default = _exports.PASSWORD_REGEX = _exports.NAME_REGEX = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const USERNAME_REGEX = /^[A-Za-z0-9@_.-]{8,30}$/;
  const PASSWORD_REGEX = _exports.PASSWORD_REGEX = /^[A-Za-z0-9!?@%$#&:*-_]{12,}$/;
  const NAME_REGEX = _exports.NAME_REGEX = /^[^+_#¤%&/£$€?@0-9]{2,30}$/;
  const Validations = (0, _emberCpValidations.buildValidations)({
    username: [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('format', {
      regex: USERNAME_REGEX
    })],
    usernameIsUnique: (0, _emberCpValidations.validator)('is-truthy'),
    email: [(0, _emberCpValidations.validator)('format', {
      regex: _formatters.EMAIL_REGEX
    })],
    emailIsUnique: (0, _emberCpValidations.validator)('is-truthy'),
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: PASSWORD_REGEX
    })],
    passwordConfirmationValidity: (0, _emberCpValidations.validator)('is-truthy'),
    first_name: [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('format', {
      regex: NAME_REGEX
    })],
    last_name: [(0, _emberCpValidations.validator)('present-not-blank'), (0, _emberCpValidations.validator)('format', {
      regex: NAME_REGEX
    })]
  });
  let StepOneRegisterController = (_dec = Ember.inject.service, _dec2 = Ember.computed.or('checkTasks.isWorking', 'validations.isInvalid'), _dec3 = Ember.computed('password', 'passwordConfirmation'), _dec4 = Ember.computed.and('validations.attrs.email.isValid', 'emailIsUnique'), _dec5 = (0, _object.observes)('username', 'validations.attrs.username.isValid'), _dec6 = (0, _object.observes)('email', 'validations.attrs.email.isValid'), _dec7 = Ember._action, _class = class StepOneRegisterController extends Ember.Controller.extend(Validations) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "registryEndpoint", '/users/users/');
      _defineProperty(this, "emailIsUnique", true);
      _defineProperty(this, "usernameIsUnique", true);
      _defineProperty(this, "inProgress", false);
      _initializerDefineProperty(this, "registrationBlocked", _descriptor2, this);
      _initializerDefineProperty(this, "emailIsValid", _descriptor3, this);
    }
    init() {
      super.init(...arguments);
      this.set('checkTasks', _promises.TaskBlock.create({}));
    }
    get passwordConfirmationValidity() {
      const passwordIsValid = this.get('validations.attrs.password.isValid');
      const password = this.get('password');
      const passwordConfirmation = this.get('passwordConfirmation');
      return !Ember.isEmpty(password) && passwordIsValid && password === passwordConfirmation;
    }
    checkEmailUniqueness() {
      var email = this.email;
      if (_formatters.EMAIL_REGEX.test(email)) {
        var url = `/users/email_uniqueness/?email=${email}`;
        var req = this.ajax.request(url).then(function (response) {
          this.set('emailIsUnique', response.unique);
        }.bind(this));
        this.checkTasks.addTask(req);
      }
    }
    checkUsernameUniqueness() {
      var username = this.username;
      if (USERNAME_REGEX.test(username)) {
        var url = `/users/username_uniqueness/?username=${username}`;
        var req = this.ajax.request(url).then(function (response) {
          this.set('usernameIsUnique', response.unique);
        }.bind(this));
        this.checkTasks.addTask(req);
      }
    }
    triggerUsernameCheck() {
      Ember.run.debounce(this, this.checkUsernameUniqueness, 500);
    }
    triggerEmailCheck() {
      Ember.run.debounce(this, this.checkEmailUniqueness, 500);
    }
    register(event) {
      event.preventDefault();
      const data = this.getProperties(['last_name', 'first_name', 'username', 'email', 'password']);
      this.set('inProgress', true);
      const req = this.ajax.post(this.registryEndpoint, {
        data
      }).then(response => {
        // Some backend env configs don't require key activation
        if (response.is_active) {
          this.transitionToRoute('step-two-register', response.id);
          (0, _notifications.displayNotification)('status', this.i18n.t('Registration.successRegister'));
        } else {
          this.transitionToRoute('index');
          (0, _notifications.displayNotification)('status', this.i18n.t('Registration.firstStepSuccess'));
        }
      }).catch(_notifications.reportError).finally(() => {
        this.set('inProgress', false);
      });
      this.checkTasks.addTask(req);
      return req;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "registrationBlocked", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "passwordConfirmationValidity", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "passwordConfirmationValidity"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "emailIsValid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "triggerUsernameCheck", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "triggerUsernameCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "triggerEmailCheck", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "triggerEmailCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "register", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _class);
  var _default = _exports.default = StepOneRegisterController;
});
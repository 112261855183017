define("plutof/components/add-multiple-form", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="add-multiple-form">
      {{yield (hash
          remove=this.remove
          addButton=(component "add-multiple-form/-add-button")
          column=(component "add-multiple-form/-column")
      )}}
  </div>
  
  */
  {
    "id": "nPOdYmpA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"add-multiple-form\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"remove\",\"addButton\",\"column\"],[[32,0,[\"remove\"]],[30,[36,0],[\"add-multiple-form/-add-button\"],null],[30,[36,0],[\"add-multiple-form/-column\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "plutof/components/add-multiple-form.hbs"
    }
  });
  let AddMultipleForm = (_dec = Ember._action, _class = class AddMultipleForm extends _component.default {
    remove(object) {
      this.args.objects.removeObject(object);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "remove", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AddMultipleForm);
});
define("plutof/components/clipboard/bulk-edit-measurement-form", ["exports", "ember-data", "plutof/config/environment", "plutof/misc/abstract", "plutof/utils/notifications"], function (_exports, _emberData, _environment, _abstract, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MeasurementData = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const FLOAT_REGEXP = new RegExp('^$|^((-[1-9])|\\d)\\d*([.]\\d+)?$');
  const INT_REGEXP = new RegExp('^$|^((-[1-9])|\\d)\\d*');
  let Field = (_dec = Ember.computed.not('isEditMode'), _dec2 = Ember.computed.or('isEditMode', 'isDeleteMode'), _dec3 = Ember.computed('value', 'config.is_required', 'config.type'), _class = class Field extends Ember.ObjectProxy {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", null);
      _defineProperty(this, "content", null);
      // object
      _defineProperty(this, "methodFieldOpen", false);
      _defineProperty(this, "methodDescription", null);
      // string
      _defineProperty(this, "idEditMode", false);
      _defineProperty(this, "isDeleteMode", false);
      _initializerDefineProperty(this, "disabled", _descriptor, this);
      _initializerDefineProperty(this, "isActive", _descriptor2, this);
    }
    get isValid() {
      const value = Ember.isNone(this.get('value')) ? '' : this.get('value');
      const intIsGood = this.get('config.type') !== 'int' || INT_REGEXP.test(value);
      const floatIsGood = this.get('config.type') !== 'float' || FLOAT_REGEXP.test(value);
      const presenceIsGood = !this.get('config.is_required') || !Ember.isEmpty(value);
      return intIsGood && floatIsGood && presenceIsGood;
    }
    resetField() {
      this.setProperties({
        isEditMode: false,
        isDeleteMode: false,
        methodFieldOpen: false,
        methodDescription: null,
        value: null
      });
    }
    switchFieldMode(modeProperty) {
      // We need to keep the state for a moment to prevent it from getting lost when all states are reset.
      const state = this.get(modeProperty);
      this.resetField();
      this.set(modeProperty, !state);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _class);
  class MeasurementData extends Ember.Object {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "measurements", null);
      // array
      _defineProperty(this, "fiels", null);
    }
    // array

    init() {
      super.init(...arguments);
      let measurements = this.measurements ?? [];
      this.set('fields', measurements.map(m => {
        return Field.create({
          config: m,
          content: {},
          methodFieldOpen: false
        });
      }));
    }
    getData() {
      const fieldsInEditMode = this.fields.filterBy('isEditMode');
      const fieldsInDeleteMode = this.fields.filterBy('isDeleteMode');

      // Edited fields with values.
      const objectMeasurements = fieldsInEditMode.map(field => {
        const value = field.get('config.type') === 'set' ? field.get('value.id') : field.get('value');
        if (Ember.isEmpty(value)) {
          return null;
        } else {
          return {
            measurement: field.get('config.url'),
            method_desc: field.get('methodDescription'),
            value: value
          };
        }
      }).compact();

      // Fields marked for deletion.
      const removeObjectMeasurements = fieldsInDeleteMode.map(field => {
        return {
          measurement: field.get('config.url')
        };
      });
      return {
        object_measurement: objectMeasurements,
        remove_object_measurement: removeObjectMeasurements
      };
    }
  }
  _exports.MeasurementData = MeasurementData;
  function createMeasurementData(ajax, mainformId, formPath) {
    const query = `${_environment.default.API_HOST}/measurement/mainforms/${mainformId}/measurements/`;
    return _emberData.default.PromiseObject.create({
      promise: Ember.isNone(mainformId) ? _abstract.EMPTY_PROMISE : ajax.request(query).then(measurements => {
        return MeasurementData.create({
          measurements: Ember.get(measurements, formPath) ?? []
        });
      }).catch(_notifications.reportError)
    });
  }
  let BulkEditMeasurementForm = (_dec4 = Ember.inject.service, _dec5 = Ember.computed('data.mainformType'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _class2 = class BulkEditMeasurementForm extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor3, this);
    }
    get mainformFilters() {
      return {
        type: this.get('data.mainformType')
      };
    }
    toggleMethodEdit(field) {
      this.get('data.measurementData.fields').forEach(df => {
        if (df === field) {
          field.toggleProperty('methodFieldOpen');
        } else {
          df.set('methodFieldOpen', false);
        }
      });
    }
    switchToEditMode(field) {
      field.switchFieldMode('isEditMode');
    }
    switchToDeleteMode(field) {
      field.switchFieldMode('isDeleteMode');
    }
    mainformChanged(mainform) {
      if (Ember.isNone(mainform)) {
        this.set('data.measurementData', null);
      } else {
        const measurementData = createMeasurementData(this.ajax, mainform.id, this.formPath);
        this.set('data.measurementData', measurementData);
      }
    }
    updateMainform(inputWrapper, mainform) {
      inputWrapper.set('value', mainform);
      if (Ember.isNone(mainform)) {
        this.set('data.measurementData', null);
      } else {
        const measurementData = createMeasurementData(this.ajax, mainform.id, this.formPath);
        this.set('data.measurementData', measurementData);
      }
    }
  }, _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "mainformFilters", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "mainformFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleMethodEdit", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleMethodEdit"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "switchToEditMode", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "switchToEditMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "switchToDeleteMode", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "switchToDeleteMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "mainformChanged", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "mainformChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateMainform", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "updateMainform"), _class2.prototype), _class2);
  var _default = _exports.default = BulkEditMeasurementForm;
});
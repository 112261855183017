define("plutof/components/clipboard/bulk-update-sequence-general", ["exports", "@ember-decorators/component", "@ember-decorators/object", "plutof/components/clipboard/bulk-update", "plutof/utils/model"], function (_exports, _component, _object, _bulkUpdate, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BulkUpdateSequenceGeneral = (_dec = (0, _component.classNames)('bulk-update-sequence-general'), _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('data.inputs'), _dec4 = (0, _model.choices)('taxonoccurrence.sequence.sequencetype.type'), _dec5 = Ember.computed(), _dec6 = (0, _object.observes)('selectedSourceOption'), _dec(_class = (_class2 = class BulkUpdateSequenceGeneral extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "inputs", _descriptor2, this);
      _initializerDefineProperty(this, "sequenceRegions", _descriptor3, this);
    }
    get sequenceSources() {
      return this.store.findAll('taxonoccurrence/sequence/source');
    }
    init() {
      super.init(...arguments);
      const inputs = {
        project: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'General.Project',
          labelSource: 'local'
        }),
        sequence_regions: (0, _bulkUpdate.createInput)('hasMany', {
          labelPath: 'Sequences.sequencedRegions',
          labelSource: 'local'
        }),
        sequence: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'sequence.sequence',
          canDelete: false
        }),
        forw_primer_name: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'sequence.forw_primer_name'
        }),
        forw_primer_sequence: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'sequence.forw_primer_sequence'
        }),
        rev_primer_name: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'sequence.rev_primer_name'
        }),
        rev_primer_sequence: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'sequence.rev_primer_sequence'
        }),
        seq_source: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'sequence.seq_source'
        }),
        parent_sequence: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'sequence.parent_sequence'
        }),
        remarks: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'sequence.remarks'
        }),
        is_available_for_unite: (0, _bulkUpdate.createInput)('boolean', {
          labelPath: 'sequence.is_available_for_unite'
        }),
        unitestatus: (0, _bulkUpdate.createInput)('boolean', {
          labelPath: 'Clipboard.bulkUpdate.fields.sequence.unitestatus',
          labelSource: 'local'
        }),
        chimeric_status: (0, _bulkUpdate.createInput)('boolean', {
          labelPath: 'sequence.chimeric_status'
        }),
        quality_status: (0, _bulkUpdate.createInput)('boolean', {
          labelPath: 'sequence.quality_status'
        }),
        content_object: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'Sequences.source',
          labelSource: 'local'
        }),
        mainform: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'sequence.mainform',
          canDelete: false
        })
      };
      const sourceSelectionOptions = [{
        key: 'specimen',
        label: 'Model.specimen',
        data: 'taxonoccurrence/specimen/specimen'
      }, {
        key: 'livingspecimen',
        label: 'Model.livingspecimen',
        data: 'taxonoccurrence/livingculture/strain'
      }, {
        key: 'materialsample',
        label: 'Model.materialsample',
        data: 'taxonoccurrence/materialsample/materialsample'
      }];

      // XXX, See bulk-update-specimen-general
      Ember.run.next(() => {
        this.setProperties({
          'data.inputs': (0, _bulkUpdate.processInputs)(inputs, this.data),
          'sourceSelectionOptions': sourceSelectionOptions,
          'selectedSourceOption': Ember.get(sourceSelectionOptions, 'firstObject')
        });
      });
    }
    clearSequenceSourceValue() {
      this.set('inputs.content_object.doClearValue', true); // XXX See clipboard/input-wrapper.
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "inputs", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "sequenceRegions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "sequenceSources", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "sequenceSources"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearSequenceSourceValue", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "clearSequenceSourceValue"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = BulkUpdateSequenceGeneral;
});
define("plutof/components/tables/-bulk-update/selector", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _TableBulkUpdateSelector;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let TableBulkUpdateSelector = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed('selection', 'record'), _dec(_class = (_class2 = (_TableBulkUpdateSelector = class TableBulkUpdateSelector extends Ember.Component {
    // Can't observe Set contents
    get selected() {
      return this.selection.has(this.record);
    }
    set selected(value) {
      // Update is done through the action, because ember can't listed to set updates and we
      // need those to show the bulk-update table
      this.toggle(this.record, value);
      return value;
    }

    // Because the only way currently to add to a bulk-update selection is via selectors, I guess
    // this is reasonable
    willDestroy() {
      this.toggle(this.record, false);
    }
  }, _defineProperty(_TableBulkUpdateSelector, "positionalParams", ['record']), _TableBulkUpdateSelector), _applyDecoratedDescriptor(_class2.prototype, "selected", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "selected"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = TableBulkUpdateSelector;
});
define("plutof/components/experiment/pcr/view/experiment-material-view", ["exports", "plutof/components/experiment/utils", "plutof/utils/file-serialization"], function (_exports, _utils, _fileSerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ExperimentMaterialView = (_dec = Ember.computed('products.[]'), _dec2 = Ember._action, _dec3 = Ember.computed.filterBy('products', 'hasSource'), _class = class ExperimentMaterialView extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "products", []);
      _initializerDefineProperty(this, "filledMaterials", _descriptor, this);
    }
    get labware() {
      const products = this.products.sortBy('tube_nr');
      return products.map((product, index) => {
        return {
          material: product,
          representation: (0, _utils.labwareTubeLabel)(index)
        };
      });
    }
    downloadCSV() {
      let csv = 'tube,sec. ID,sample,primer F,primer F tag,primer R,primer R tag\n';
      csv += this.products.sortBy('tube_nr').map((product, index) => {
        const sourceName = product.get('dna.name') || product.get('pcr_product.name');
        return [(0, _utils.labwareTubeLabel)(index), product.secondary_identifier,
        // XXX
        Ember.isPresent(sourceName) ? sourceName.split(' | ').shift() : sourceName, product.get('forward_primer.representation'), product.get('forward_primer_tag'), product.get('reverse_primer.representation'), product.get('reverse_primer_tag')].join(',');
      }).join('\n');
      (0, _fileSerialization.saveFile)([csv], 'pcr_products.csv');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "labware", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "labware"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadCSV", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "downloadCSV"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "filledMaterials", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = ExperimentMaterialView;
});
define("plutof/components/import/process-list/row", ["exports", "@ember-decorators/component", "plutof/misc/abstract", "plutof/misc/config", "plutof/utils/modules"], function (_exports, _component, _abstract, _config, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ImportProcessListRow = (_dec = (0, _component.tagName)('tr'), _dec2 = Ember.computed('process.moduleAttributes.contentType'), _dec3 = Ember.computed('process.status', 'process.is_async'), _dec4 = Ember.computed('process.status'), _dec5 = Ember.computed('process.status'), _dec6 = Ember.computed('process.{total,total_imported,ignored_rows}'), _dec(_class = (_class2 = class ImportProcessListRow extends Ember.Component {
    get moduleIcon() {
      return _modules.default[this.process.moduleAttributes.contentType].icon;
    }
    get statusMessage() {
      let status = this.process.status;
      if (status === 'waiting') {
        status = this.process.is_async ? 'waiting.async' : 'waiting.sync';
      }
      return this.i18n.t(`Import.status.${status}`);
    }
    get canBeStopped() {
      return ['waiting', 'processing', 'errors'].includes(this.process.status);
    }
    get isInactive() {
      return ['finished', 'stopped'].includes(this.process.status);
    }
    get progress() {
      if (this.process.total === 0) {
        return '100%';
      }
      const progress = (this.process.total_imported + this.process.ignored_rows.length) / this.process.total;
      const percentage = (0, _abstract.decimal_round)(progress * 100, _config.default.Import.PROGRESS_ROUND_PRECISION);
      return `${percentage}%`;
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "moduleIcon", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "moduleIcon"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "statusMessage", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "statusMessage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canBeStopped", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "canBeStopped"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isInactive", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isInactive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "progress", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "progress"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = ImportProcessListRow;
});
define("plutof/components/search/filter-panels/red-list-assessment/time", ["exports", "@glimmer/component", "plutof/misc/options-getter"], function (_exports, _component, _optionsGetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTime"}}
      @collapse={{true}}
  >
      {{#resolve-promise this.groups as |groups|}}
          <Search::FilterPanels::-TimeGroups
              @groups={{groups}}
              @filters={{@filterInputs}} />
      {{/resolve-promise}}
  </PlutofPanel::Simple>
  
  */
  {
    "id": "lPfhkVAl",
    "block": "{\"symbols\":[\"groups\",\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelTime\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"groups\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"search/filter-panels/-time-groups\",[],[[\"@groups\",\"@filters\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"resolve-promise\"]}",
    "meta": {
      "moduleName": "plutof/components/search/filter-panels/red-list-assessment/time.hbs"
    }
  });
  let SearchRedListAssessmentTimeFiltersPanel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed, _class = class SearchRedListAssessmentTimeFiltersPanel extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "i18n", _descriptor2, this);
    }
    get groups() {
      return (0, _optionsGetter.getModelTexts)(this.ajax, 'redbook/red-list-assessment').then(texts => [{
        id: 'created',
        label: this.i18n.translate('Search.createdAt'),
        filters: ['created']
      }, {
        id: 'modified',
        label: this.i18n.translate('Search.lastModified'),
        filters: ['modified']
      }, {
        id: 'assessment_date',
        label: texts.assessment_date.label,
        filters: ['assessment_date']
      }, {
        id: 'due_date',
        label: texts.due_date.label,
        filters: ['due_date']
      }]);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "groups", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "groups"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchRedListAssessmentTimeFiltersPanel);
});
define("plutof/components/publishing/gbif/records/existing-record", ["exports", "@ember-decorators/component", "plutof/controllers/publishing/utils"], function (_exports, _component, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class="{{if validations.attrs.record.plutof_id.isInvalid 'has-error'}}">
      {{#link-to this.type.viewRoute @record.id}}
          {{@record.representation}}
      {{/link-to}}
  </td>
  
  <td>
      {{#if @record.taxon_node}}
          {{#link-to "taxonomy.view" @record.taxon_node.id}}
              {{@record.taxon_node.name}}
          {{/link-to}}
      {{/if}}
  </td>
  
  <td>
      {{#if @record.project.id}}
          {{#link-to "study.view" @record.project.id}}
              {{@record.project.name}}
          {{/link-to}}
      {{/if}}
  </td>
  
  <td>{{@record.district}}</td>
  <td>{{@record.commune}}</td>
  <td>{{@record.locality_text}}</td>
  
  <td class="align-right">
      <button
          {{on "click" @remove}}
          class="btn btn-link data-table-btn"
      >
          <span class="icon-remove--red"></span>
      </button>
  </td>
  
  */
  {
    "id": "DwJjMKFH",
    "block": "{\"symbols\":[\"@record\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[31,[[30,[36,2],[[35,1,[\"attrs\",\"record\",\"plutof_id\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\",\"model\"],[[32,0,[\"type\",\"viewRoute\"]],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"taxon_node\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[\"taxonomy.view\",[32,1,[\"taxon_node\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,1,[\"taxon_node\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"project\",\"id\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[\"study.view\",[32,1,[\"project\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,1,[\"project\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[1,[32,1,[\"district\"]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[1,[32,1,[\"commune\"]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[1,[32,1,[\"locality_text\"]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[32,2]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"validations\",\"if\",\"on\"]}",
    "meta": {
      "moduleName": "plutof/components/publishing/gbif/records/existing-record.hbs"
    }
  });
  let ExistingRecord = (_dec = (0, _component.tagName)('tr'), _dec2 = Ember.computed('record.type'), _dec(_class = (_class2 = class ExistingRecord extends Ember.Component {
    get type() {
      return (0, _utils.getRecordType)(this.record.type);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "type", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "type"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ExistingRecord);
});
define("plutof/components/unite/alignment-columns", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Tables::ColumnSelector as |columnSelector|>
      <columnSelector.column
          @toggle={{this.toggleAll}}
          @visible={{this.allSelected}}
      >
          {{i18n-t "General.all"}}
      </columnSelector.column>
  
      {{#each @all as |column|}}
          <div class="column-control">
              <columnSelector.column
                  @toggle={{fn this.toggle column}}
                  @visible={{in-list @selected column.id}}
              >
                  {{column.name}}
              </columnSelector.column>
          </div>
      {{/each}}
  </Tables::ColumnSelector>
  
  */
  {
    "id": "TAjJbF9/",
    "block": "{\"symbols\":[\"columnSelector\",\"column\",\"@selected\",\"@all\"],\"statements\":[[8,\"tables/column-selector\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"column\"]],[],[[\"@toggle\",\"@visible\"],[[32,0,[\"toggleAll\"]],[32,0,[\"allSelected\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[\"General.all\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"column-control\"],[12],[2,\"\\n            \"],[8,[32,1,[\"column\"]],[],[[\"@toggle\",\"@visible\"],[[30,[36,0],[[32,0,[\"toggle\"]],[32,2]],null],[30,[36,1],[[32,3],[32,2,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[32,2,[\"name\"]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"in-list\",\"i18n-t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/unite/alignment-columns.hbs"
    }
  });
  let AlignmentColumns = (_dec = Ember.computed('args.selected.[]', 'args.all.[]'), _dec2 = Ember._action, _dec3 = Ember._action, _class = class AlignmentColumns extends _component.default {
    get allSelected() {
      return this.args.selected.length === this.args.all.length;
    }
    toggle(column) {
      if (this.args.selected.includes(column.id)) {
        this.args.selected.removeObject(column.id);
      } else {
        this.args.selected.pushObject(column.id);
      }
    }
    toggleAll() {
      if (this.allSelected) {
        this.args.selected.clear();
      } else {
        this.args.selected.replace(0, this.args.selected.length, this.args.all.map(col => col.id));
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "allSelected", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "allSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAll", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAll"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AlignmentColumns);
});
define("plutof/components/experiment/common/labware-tube", ["exports", "@ember-decorators/component", "plutof/components/experiment/utils"], function (_exports, _component, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let LabwareTube = (_dec = (0, _component.tagName)(''), _dec2 = Ember._action, _dec3 = Ember.computed('material.{specimen,living_specimen,material_sample,dna,pcr_product}'), _dec4 = Ember.computed('sourceKey'), _dec5 = Ember.computed('sourceRecord.representation'), _dec(_class = (_class2 = class LabwareTube extends Ember.Component {
    selectMaterial(material) {
      if (this.select) {
        this.select(material);
      }
    }
    get sourceKey() {
      return _utils.MATERIAL_SOURCE_KEYS.find(key => {
        return Ember.isPresent(this.material.get(key + '.id'));
      });
    }
    get sourceRecord() {
      return Ember.isPresent(this.sourceKey) ? this.material.get(this.sourceKey) : null;
    }
    get tubeLabel() {
      let label = this.get('sourceRecord.representation');
      if (Ember.isEmpty(label)) {
        return label;
      } else {
        // Replace pipes with newlines.
        label = label.replace(/\s\|\s/g, '\n');
        // Trim '\n-'.
        label = label.replace(/\n-$/, '');
        return label;
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "selectMaterial", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "selectMaterial"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sourceKey", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "sourceKey"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sourceRecord", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "sourceRecord"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "tubeLabel", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "tubeLabel"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = LabwareTube;
});
define("plutof/components/observation/measurements-miniform", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations"], function (_exports, _component, _emberCpValidations, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'fieldsAreValid': (0, _emberCpValidations.validator)('is-truthy')
  });
  let MeasurementsMiniform = (_dec = (0, _component.classNames)('measurements-miniform'), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.inject.service, _dec4 = Ember.computed('locale.locale'), _dec5 = Ember.computed('fields.@each.isValid'), _dec6 = Ember.computed('collapsed', 'data.objectMeasurements.@each.is_extended'), _dec7 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class MeasurementsMiniform extends Ember.Component.extend(Validations, _componentValidations.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "locale", _descriptor, this);
    }
    didReceiveAttrs() {
      this.data.setProperties({
        object: this.object,
        mainform: this.mainform
      });
    }
    get elementLabel() {
      return this.locale.locale === 'est' ? 'element_translated' : 'element';
    }
    get fieldsAreValid() {
      return this.fields.every(field => field.isValid);
    }
    get fields() {
      let fields = this.data.objectMeasurements.rejectBy('measurementData.is_extended', true);
      if (!this.collapsed) {
        fields = fields.concat(this.data.objectMeasurements.filterBy('measurementData.is_extended', true));
      }
      return fields;
    }
    changeMultipleSetValue(om, element) {
      const value = Ember.isNone(element) ? [] : [element];
      om.set('value', value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "locale", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "elementLabel", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "elementLabel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fieldsAreValid", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "fieldsAreValid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "fields", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "fields"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeMultipleSetValue", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "changeMultipleSetValue"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = MeasurementsMiniform;
});
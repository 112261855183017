define("plutof/components/forms/edit", ["exports", "@glimmer/component", "plutof/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <Forms::Form
          @mainform={{@data.mainform}}
          @taxonMainforms={{@data.taxonMainforms}}
          @traitForms={{@data.measurementForms}}
          @areaTraits={{@data.areaForm}}
          @eventTraits={{@data.eventForm}}
          @habitatTraits={{@data.habitatForm}}
          @itemsToDelete={{@data.itemsToDelete}}
          @validationContext={{@validationContext.generalData}} />
  </PlutofPanel::Simple>
  
  {{#if @managingGroup}}
      {{! TODO: This is the only place this component is used }}
      <Access::ManagingGroup::Edit
          @model={{@managingGroup}}
          @includeModerationRights={{@data.mainform.requires_moderation}}
          @validationContext={{@validationContext.managingGroup}} />
  {{/if}}
  
  {{#if this.enableTaxonSheet}}
      <Forms::TaxonSheet
          @data={{@data.taxonSheetData}}
          @validationContext={{@validationContext.taxonSheet}} />
  {{/if}}
  
  */
  {
    "id": "AstDzDoJ",
    "block": "{\"symbols\":[\"@data\",\"@validationContext\",\"@managingGroup\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"forms/form\",[],[[\"@mainform\",\"@taxonMainforms\",\"@traitForms\",\"@areaTraits\",\"@eventTraits\",\"@habitatTraits\",\"@itemsToDelete\",\"@validationContext\"],[[32,1,[\"mainform\"]],[32,1,[\"taxonMainforms\"]],[32,1,[\"measurementForms\"]],[32,1,[\"areaForm\"]],[32,1,[\"eventForm\"]],[32,1,[\"habitatForm\"]],[32,1,[\"itemsToDelete\"]],[32,2,[\"generalData\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"access/managing-group/edit\",[],[[\"@model\",\"@includeModerationRights\",\"@validationContext\"],[[32,3],[32,1,[\"mainform\",\"requires_moderation\"]],[32,2,[\"managingGroup\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"enableTaxonSheet\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"forms/taxon-sheet\",[],[[\"@data\",\"@validationContext\"],[[32,1,[\"taxonSheetData\"]],[32,2,[\"taxonSheet\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\"]}",
    "meta": {
      "moduleName": "plutof/components/forms/edit.hbs"
    }
  });
  let FormsEdit = (_dec = Ember.computed('args.data.mainform.id'), _class = class FormsEdit extends _component.default {
    get enableTaxonSheet() {
      return _environment.default.TAXON_SHEET_MAINFORMS.includes(this.args.data.mainform.id);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "enableTaxonSheet", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "enableTaxonSheet"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FormsEdit);
});
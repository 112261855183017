define("plutof/components/chart/base-chart", ["exports", "@ember-decorators/component", "chart.js"], function (_exports, _component, _chart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let BaseChart = (_dec = (0, _component.tagName)('canvas'), _dec2 = (0, _component.attributeBindings)('width', 'height'), _dec(_class = _dec2(_class = class BaseChart extends Ember.Component {
    didInsertElement(...args) {
      super.didInsertElement(...args);
      const context = this.element;
      const data = this.data;
      const type = this.type;
      const options = this.options;
      const chart = new _chart.default(context, {
        type: type,
        data: Ember.getProperties(data, Object.keys(data)),
        options: options
      });
      this.set('chart', chart);

      // TODO: Debounce or will it be more fun if we don't?
      this.addObserver('data', this, this.updateChart);
      this.addObserver('data.[]', this, this.updateChart);
      this.addObserver('options', this, this.updateChart);
    }
    willDestroyElement(...args) {
      super.willDestroyElement(...args);
      this.chart.destroy();
      this.removeObserver('data', this, this.updateChart);
      this.removeObserver('data.[]', this, this.updateChart);
      this.removeObserver('options', this, this.updateChart);
    }
    updateChart() {
      const chart = this.chart;
      const data = this.data;
      chart.config.data = data;
      chart.update();
    }
  }) || _class) || _class);
  var _default = _exports.default = BaseChart;
});
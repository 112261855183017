define("plutof/controllers/area/view", ["exports", "plutof/utils/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AreaViewController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember.computed('model.samplingarea.geom', 'model.samplingarea.restricted'), _dec6 = Ember.computed('model.samplingarea.id'), _dec7 = Ember.computed('model.samplingarea.id'), _dec8 = Ember.computed.alias('model.samplingarea.study.id'), _dec9 = Ember.computed('model.samplingarea.id'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _class = class AreaViewController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "geomToolOpen", _descriptor2, this);
      _defineProperty(this, "enabledControls", ['navigate', 'layerset']);
      _initializerDefineProperty(this, "projectId", _descriptor3, this);
    }
    init() {
      super.init(...arguments);
      const i18n = this.i18n;
      this.set('mapModes', [{
        name: i18n.t('Projects.areaOnMap'),
        parent: true,
        children: false
      }, {
        name: i18n.t('Projects.includeSubAreasOnMap'),
        parent: true,
        children: true
      }, {
        name: i18n.t('Projects.paginatedAreasOnMap'),
        parent: true,
        children: true,
        value: 'listed'
      }]);
      this.set('currentMapMode', this.mapModes[0]);
    }
    openGeomTool() {
      this.geomToolOpen = true;
    }
    closeGeomTool() {
      this.geomToolOpen = false;
    }
    get hasGeometry() {
      return this.model.samplingarea.geom && !this.model.samplingarea.restricted;
    }
    get relatedOccurrencesQuery() {
      return {
        samplingarea: this.model.samplingarea.id
      };
    }
    get relatedOccurrencesListQuery() {
      return {
        samplingarea: this.model.samplingarea.id,
        ordering: '-updated_at'
      };
    }
    get sampleEventsListArguments() {
      const area = this.model.samplingarea;
      return {
        title: 'General.samplingEvents',
        addAction: 'newEvent',
        model: 'sample/samplingevent',
        queryParameters: {
          samplingarea: this.get('model.samplingarea.id')
        },
        uniqueProperties: {
          event_name: {
            name: 'name',
            viewRoute: 'event.view',
            get(event) {
              const areaName = area.representation;
              const timespan = (0, _formatters.formatTimespan)(event.timespan_begin, event.timespan_end);
              return timespan ? `${areaName} ${timespan}` : areaName;
            }
          },
          timespan_begin: {
            name: 'collectionDate',
            isDate: true
          },
          collected_by: {
            name: 'collectedBy'
          }
        }
      };
    }
    makeTransition(routeToTransition) {
      var queryParams = {
        area_id: this.get('model.samplingarea.id')
      };
      if (this.projectId) {
        queryParams.project_id = this.projectId;
      }
      return this.transitionToRoute(routeToTransition, {
        queryParams
      });
    }
    newSpecimen() {
      return this.makeTransition('specimen.add');
    }
    newObservation() {
      return this.makeTransition('observation.add');
    }
    newCulture() {
      return this.makeTransition('livingspecimen.add');
    }
    newMaterialSample() {
      return this.makeTransition('materialsample.add');
    }
    newReferenceOccurrence() {
      return this.makeTransition('referencebased.add');
    }
    newSequence() {
      return this.makeTransition('sequence.add');
    }
    newEvent() {
      return this.transitionToRoute('event.add', this.get('model.samplingarea.id'));
    }
    newSample() {
      var queryParams = {
        parent_area_id: this.get('model.samplingarea.id')
      };
      if (this.projectId) {
        queryParams.project_id = this.projectId;
      }
      return this.transitionToRoute('area.add', {
        queryParams
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "geomToolOpen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openGeomTool", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "openGeomTool"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeGeomTool", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "closeGeomTool"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasGeometry", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "hasGeometry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "relatedOccurrencesQuery", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "relatedOccurrencesQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "relatedOccurrencesListQuery", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "relatedOccurrencesListQuery"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "projectId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sampleEventsListArguments", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "sampleEventsListArguments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newSpecimen", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "newSpecimen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newObservation", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "newObservation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newCulture", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "newCulture"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newMaterialSample", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "newMaterialSample"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newReferenceOccurrence", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "newReferenceOccurrence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newSequence", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "newSequence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newEvent", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "newEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newSample", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "newSample"), _class.prototype), _class);
  var _default = _exports.default = AreaViewController;
});
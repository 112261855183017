define("plutof/components/linked-items/view", ["exports", "@ember-decorators/object", "ember-concurrency", "plutof/components/linked-items/edit", "plutof/misc/content_types", "plutof/models/permit/item", "plutof/utils/annotation/format", "plutof/utils/pagination", "plutof/utils/push-to-store", "plutof/utils/reflection", "plutof/utils/modules"], function (_exports, _object, _emberConcurrency, _edit, _content_types, _item, _format, _pagination, _pushToStore, _reflection, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function loadItems(store, ctype, objectID, itemModel) {
    const filters = {
      content_type: ctype.get('id'),
      object_id: objectID
    };
    return (0, _pagination.paginateModel)(store, itemModel, filters, [_pagination.AccumulatingPaginationMixin]);
  }
  let LinkedItemsView = (_dec = Ember.inject.service, _dec2 = Ember.computed('files.[]'), _dec3 = Ember.computed('eventFiles.objects.[]', 'recordFiles.objects.[]'), _dec4 = Ember._tracked, _dec5 = (0, _object.observes)('object'), _dec6 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec7 = Ember._tracked, _dec8 = Ember._action, _class = class LinkedItemsView extends Ember.Component.extend(_edit.ToggleTabsMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "object", null);
      _initializerDefineProperty(this, "hasItems", _descriptor2, this);
      _initializerDefineProperty(this, "_loadItems", _descriptor3, this);
      _initializerDefineProperty(this, "selectedFile", _descriptor4, this);
    }
    get fileCount() {
      return this.files.length;
    }
    get files() {
      return (this.eventFiles || []).concat(this.recordFiles || []);
    }
    init() {
      super.init(...arguments);
      this.loadItems();
    }
    async loadFileItems(record) {
      const url = (0, _reflection.get_record_url)(record) + 'files/';
      let items = await this.ajax.request(url);

      // XXX: Sequencing being a JSON API endpoint just
      // changes the format of this without asking
      if (items.data) {
        items = items.data;
      }
      for (const item of items) {
        item.file = (0, _pushToStore.default)(this.store, 'filerepository/file', item.file);
      }
      if (this.store.inAnnotation) {
        // Annotate removals
        for (const item of items) {
          const change = this.store.annotatedStore.getRecordChange('filerepository/item', item.id);
          if (change && change.operation === _format.Operation.Remove) {
            item.removedInAnnotation = true;
          }
        }

        // Annotate additions
        const ctype = (0, _modules.getRecordContentType)(record);
        const addedInAnnotation = this.store.annotatedStore.rawQueryAdditions('filerepository/item', {
          content_type: ctype,
          object_id: record.id
        });
        const resolvedAdditions = await Ember.RSVP.Promise.all(addedInAnnotation.map(async rawItem => {
          const file = await this.store.findRecord('filerepository/file', rawItem.file);
          return {
            id: rawItem.id,
            file,
            addedInAnnotation: true
          };
        }));
        items = items.concat(resolvedAdditions);
      }
      return items;
    }
    loadItems() {
      this._loadItems.perform();
    }
    showFile(item) {
      this.selectedFile = item;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fileCount", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fileCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "files", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "files"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasItems", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadItems", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loadItems"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_loadItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const object = this.object;
        if (Ember.isNone(object)) {
          return;
        }
        const ctype = yield (0, _content_types.get_object_ctype)(this.store, object);
        const parts = {
          eventFiles: [],
          recordFiles: [],
          referenceItems: [],
          glossaryItems: [],
          externalLinks: [],
          permits: []
        };
        if (this.enabledTabs.files) {
          const event = yield object.samplingevent;
          parts.eventFiles = event ? this.loadFileItems(event) : [], parts.recordFiles = this.loadFileItems(object);
        }
        if (this.enabledTabs.references) {
          parts.referenceItems = loadItems(this.store, ctype, object.id, 'reference/item');
        }
        if (this.enabledTabs.glossaries) {
          parts.glossaryItems = loadItems(this.store, ctype, object.id, 'glossary/item');
        }
        if (this.enabledTabs.links) {
          parts.externalLinks = loadItems(this.store, ctype, object.id, 'reference/externallink');
        }
        if (this.enabledTabs.permits) {
          parts.permits = (0, _item.loadPermitItems)(object);
        }
        const properties = yield Ember.RSVP.hash(parts);
        this.setProperties(properties);

        // Counting like this instead of a computed prop, because of some
        // panel collapse bug with those
        const counts = [properties.eventFiles.length, properties.recordFiles.length, properties.referenceItems.objectCount, properties.glossaryItems.objectCount, properties.externalLinks.objectCount, properties.permits.length];
        Ember.run.later(() => this.hasItems = counts.some(count => count > 0));
      };
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedFile", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showFile", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showFile"), _class.prototype), _class);
  var _default = _exports.default = LinkedItemsView;
});
define("plutof/components/clipboard/-export/citation", ["exports", "@glimmer/component", "ember-concurrency", "plutof/utils/clipboard"], function (_exports, _component, _emberConcurrency, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Common::Ui::ControlLabel>
      <:label>
          {{i18n-t "Clipboard.citation.citation"}}
      </:label>
  
      <:controls as |controls|>
          {{#if this.copied}}
              <div>
                  <span class={{icon "ok"}}></span>
                  {{i18n-t "Clipboard.citation.copied"}}
              </div>
          {{else}}
              <controls.toggle @toggle={{perform this.copyToClipboard}}>
                  {{i18n-t "Clipboard.citation.copy"}}
              </controls.toggle>
          {{/if}}
      </:controls>
  </Common::Ui::ControlLabel>
  
  <p>
      {{html-unsafe this.citationHTML}}
  </p>
  
  */
  {
    "id": "5T4xhqvr",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"controls\"],\"statements\":[[8,\"common/ui/control-label\",[],[[\"@namedBlocksInfo\"],[[30,[36,6],null,[[\"label\",\"controls\"],[0,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,5],[[32,1],\"label\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[\"Clipboard.citation.citation\"],null]],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,5],[[32,1],\"controls\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"copied\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[12],[2,\"\\n                \"],[10,\"span\"],[15,0,[30,[36,2],[\"ok\"],null]],[12],[13],[2,\"\\n                \"],[1,[30,[36,1],[\"Clipboard.citation.copied\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[8,[32,3,[\"toggle\"]],[],[[\"@toggle\"],[[30,[36,0],[[32,0,[\"copyToClipboard\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,1],[\"Clipboard.citation.copy\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,7],[[32,0,[\"citationHTML\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"i18n-t\",\"icon\",\"if\",\"let\",\"-is-named-block-invocation\",\"hash\",\"html-unsafe\"]}",
    "meta": {
      "moduleName": "plutof/components/clipboard/-export/citation.hbs"
    }
  });
  let ExportCitation = _exports.default = (_dec = Ember._tracked, _class = class ExportCitation extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "copied", _descriptor, this);
      _defineProperty(this, "CITATION", 'Abarenkov, Kessy; Tedersoo, Leho; Nilsson, R. Henrik; Vellak, Kai; Saar, Irja; Veldre, Vilmar; Parmasto, Erast; Prous, Marko; Aan, Anne; Ots, Margus; Kurina, Olavi; Ostonen, Ivika; Jõgeva, Janno; Halapuu, Siim; Põldmaa, Kadri; Toots, Märt; Truu, Jaak; Larsson, Karl-Henrik; Kõljalg, Urmas (2010). PlutoF - a Web Based Workbench for Ecological and Taxonomic Research, with an Online Implementation for Fungal ITS Sequences. Evolutionary Bioinformatics, 6, 189 - 196. doi: https://doi.org/10.4137/EBO.S6271');
      _initializerDefineProperty(this, "copyToClipboard", _descriptor2, this);
    }
    // Because this get raw-inlined, make sure that CITATION doesn't have anything user-provided
    get citationHTML() {
      return this.CITATION.replace(/(https:\/\/[^w]+)/, url => `<a href="${url}" target="_blank" rel="noreferrer">${url}</a>`);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "copied", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "copyToClipboard", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        yield (0, _clipboard.sendToClipboard)(this.CITATION);
        this.copied = true;
        yield (0, _emberConcurrency.timeout)(2000);
        this.copied = false;
      };
    }
  }), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ExportCitation);
});
define("plutof/components/search/filter-panels/photobank/checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::RegularGrid @columns={{6}}>
      <Search::FilterInput @filter={{@filterInputs.sub_studies}} />
      <Search::FilterInput @filter={{@filterInputs.is_public}} />
      <Search::FilterInput @filter={{@filterInputs.has_sound_files}} />
      <Search::FilterInput @filter={{@filterInputs.has_video_files}} />
      <Search::FilterInput @filter={{@filterInputs.has_images}} />
      <Search::FilterInput @filter={{@filterInputs.has_files}} />
  </Layout::RegularGrid>
  
  */
  {
    "id": "X4IJD0N2",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"sub_studies\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_public\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_sound_files\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_video_files\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_images\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_files\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "plutof/components/search/filter-panels/photobank/checkboxes.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});
define("plutof/components/observation/moderation/gallery", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="gallery">
      {{#each @entries as |entry|}}
          <Observation::Moderation::Gallery::Thumbnail
              @record={{entry.observation}}
              @selected={{entry.selected}}
              @accept={{fn this._accept entry.observation.id}}
              @reject={{fn @reject entry.observation}}
              @forward={{fn @prepareForwarding (list entry.observation)}}
              @toggleModal={{fn @toggleModal entry.observation}} />
      {{/each}}
  </div>
  
  */
  {
    "id": "5Uzw1PBW",
    "block": "{\"symbols\":[\"entry\",\"@reject\",\"@prepareForwarding\",\"@toggleModal\",\"@entries\"],\"statements\":[[10,\"div\"],[14,0,\"gallery\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"observation/moderation/gallery/thumbnail\",[],[[\"@record\",\"@selected\",\"@accept\",\"@reject\",\"@forward\",\"@toggleModal\"],[[32,1,[\"observation\"]],[32,1,[\"selected\"]],[30,[36,0],[[32,0,[\"_accept\"]],[32,1,[\"observation\",\"id\"]]],null],[30,[36,0],[[32,2],[32,1,[\"observation\"]]],null],[30,[36,0],[[32,3],[30,[36,1],[[32,1,[\"observation\"]]],null]],null],[30,[36,0],[[32,4],[32,1,[\"observation\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"list\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "plutof/components/observation/moderation/gallery.hbs"
    }
  });
  let ObservationModerationGallery = (_dec = Ember._action, _class = class ObservationModerationGallery extends _component.default {
    _accept(id) {
      return this.args.accept(id);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_accept", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_accept"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ObservationModerationGallery);
});
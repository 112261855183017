define("plutof/components/agent/view/organization-additional-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <GenericViews::Record
      @record={{@organization}}
      data-test="Agent::View::OrganizationAdditionalData"
      as |view|
  >
      <Layout::RegularGrid @columns={{2}}>
          {{view.attr "original_name"}}
  
          {{#view.attr "original_language"}}
              {{@organization.original_language.representation}}
          {{/view.attr}}
  
          {{view.attr "herbarium_name"}}
          {{view.attr "original_herbarium_name"}}
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "h4mBBSGv",
    "block": "{\"symbols\":[\"view\",\"@organization\"],\"statements\":[[8,\"generic-views/record\",[[24,\"data-test\",\"Agent::View::OrganizationAdditionalData\"]],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"attr\"]],\"expected `view.attr` to be a contextual component but found a string. Did you mean `(component view.attr)`? ('plutof/components/agent/view/organization-additional-data.hbs' @ L7:C10) \"],null],\"original_name\"],null]],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"attr\"]],\"expected `view.attr` to be a contextual component but found a string. Did you mean `(component view.attr)`? ('plutof/components/agent/view/organization-additional-data.hbs' @ L9:C11) \"],null],\"original_language\"],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"original_language\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"attr\"]],\"expected `view.attr` to be a contextual component but found a string. Did you mean `(component view.attr)`? ('plutof/components/agent/view/organization-additional-data.hbs' @ L13:C10) \"],null],\"herbarium_name\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"attr\"]],\"expected `view.attr` to be a contextual component but found a string. Did you mean `(component view.attr)`? ('plutof/components/agent/view/organization-additional-data.hbs' @ L14:C10) \"],null],\"original_herbarium_name\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "meta": {
      "moduleName": "plutof/components/agent/view/organization-additional-data.hbs"
    }
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});